import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/core/models/appState.model';
import { notificationSelector } from 'src/app/state/notification/notification.selector';

@Component({
  selector: 'app-shared-notification',
  templateUrl: './shared-notification.component.html',
  styleUrls: ['./shared-notification.component.scss'],
})
export class SharedNotificationComponent implements OnInit {
  message: string = '';
  isSuccess: boolean = false;
  isFailure: boolean = false;
  showNotification: boolean = false;

  constructor(private store: Store<AppState>, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.store.select(notificationSelector).subscribe((res) => {
      this.message = res.message;
      this.isFailure = res.isFailure;
      this.isSuccess = res.isSuccess;

      if (res.showNotification && this.isSuccess) {
        this.toastr.success(this.message, undefined, {
          timeOut: 5000,
          tapToDismiss: true,
        });
      } else if (res.showNotification && (!this.isSuccess || this.isFailure)) {
        this.toastr.error(this.message, undefined, {
          disableTimeOut: true,
          tapToDismiss: true,
        });
      }
    });
  }

  show(message: string, isSuccess: boolean) {
    this.message = message;
    this.isSuccess = isSuccess;
    this.isFailure = !isSuccess;
    this.showNotification = true;

    setTimeout(() => {
      this.showNotification = false;
    }, 5000);
  }
}
