import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FuelGasEditComponent } from '../fuel-gas-edit/fuel-gas-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedSaveConfirmationComponent } from 'src/app/shared/components/shared-save-confirmation/shared-save-confirmation.component';

@Injectable({
  providedIn: 'root',
})

export class ProductionGasBalanceService {
  private data: any = {
    Day: {},
    Month: {},
  };

  constructor(
    private dialog: MatDialog
  ) {}

  getPeriods() {
    return Object.keys(this.data);
  }

  private selectedOptionSource = new BehaviorSubject<string>('Day');

  selectedOption$ = this.selectedOptionSource.asObservable();

  setSelectedOption(option: string) {
    this.selectedOptionSource.next(option);
  }

  private selectedMonthSource = new BehaviorSubject<Date | undefined>(new Date());

  selectedMonth$ = this.selectedMonthSource.asObservable();

  setSelectedMonth(month: Date) {
    this.selectedMonthSource.next(month);
  }

  public selectedDateSource = new BehaviorSubject<Date | undefined>(new Date());

  selectedDate$ = this.selectedDateSource.asObservable();

  setSelectedDate(date: Date) {
    this.selectedDateSource.next(date);
  }

  openEditDialog(data: any) {
    const dialogRef = this.dialog.open(FuelGasEditComponent, {
      width: '500px',
      data: data,
    });
    return dialogRef.afterClosed();
  }

  openSaveDialog() {
    const dialogRef = this.dialog.open(SharedSaveConfirmationComponent, {
      width: '500px',
      data: { action: 'Save' }
    });
    return dialogRef.afterClosed();
  }

  openRefreshDialog() {
    const dialogRef = this.dialog.open(SharedSaveConfirmationComponent, {
      width: '500px',
      data: { action: 'Refresh' }
    });
    return dialogRef.afterClosed();
  }
}