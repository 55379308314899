<h1 mat-dialog-title class="title">СЖИГАНИЕ ГАЗА/FLARED GAS</h1>
<div mat-dialog-content>
  <form [formGroup]="operatorEditForm">
    <div class="container">
      <div class="row" style="margin-bottom: 20px">
        <div class="col">
          <label for="StartTime">Начало сжигания / Start Time</label>
          <div class="input-group mb-2 mr-sm-2">
            <input
              type="datetime-local"
              id="StartTimeInput"
              class="form-control"
              formControlName="startTime"
            />
          </div>
          <div
            *ngIf="
              operatorEditForm.get('startTime')?.hasError('required') &&
              operatorEditForm.get('startTime')?.touched
            "
            class="text-danger"
          >
            Start Time is required.
          </div>
        </div>
        <div class="col">
          <label>Окончание сжигание / End Time</label>
          <div class="input-group mb-2 mr-sm-2">
            <input
              type="datetime-local"
              id="EndTimeInput"
              class="form-control"
              formControlName="endTime"
            />
          </div>
          <div
            *ngIf="
              operatorEditForm.get('endTime')?.hasError('required') &&
              operatorEditForm.get('endTime')?.touched
            "
            class="text-danger"
          >
            End Time is required.
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div class="row" style="margin-bottom: 20px">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="ParkSelectInput">ТП СУГ / LPG Park:</label>
              <select
                id="ParkSelectInput"
                (change)="onParkChange()"
                class="form-select"
                formControlName="lpgPark"
              >
                <option value="" disabled>ТП СУГ / LPG Park:</option>
                <option *ngFor="let park of parks" [value]="park">
                  {{ park }}
                </option>
              </select>
            </div>
            <div
              *ngIf="
                operatorEditForm.get('lpgPark')?.hasError('required') &&
                operatorEditForm.get('lpgPark')?.touched
              "
              class="text-danger"
            >
              LPG Park is required.
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="gasSelect">Тип газа / Gas Type</label>
              <select
                id="gasSelect"
                (change)="onGasChange()"
                class="form-select"
                [disabled]="!selectedPark"
                formControlName="gasType"
              >
                <option value="" disabled>Тип газа / Gas Type</option>
                <option *ngFor="let gas of gases" [value]="gas">
                  {{ gas }}
                </option>
              </select>
            </div>
            <div
              *ngIf="
                operatorEditForm.get('gasType')?.hasError('required') &&
                operatorEditForm.get('gasType')?.touched
              "
              class="text-danger"
            >
              Gas Type is required.
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="optionSelect">Буллит / Bullit:</label>
              <select
                id="optionSelect"
                class="form-select"
                [disabled]="!selectedGas"
                (change)="onOptionChange()"
                formControlName="bullet"
              >
                <option value="" disabled>Буллит / Bullit:</option>
                <option *ngFor="let option of options" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div
              *ngIf="
                operatorEditForm.get('bullet')?.hasError('required') &&
                operatorEditForm.get('bullet')?.touched
              "
              class="text-danger"
            >
              Bullit is required.
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group" *ngIf="isOtherSelected">
              <label for="otherInput">Другое / Other:</label>
              <input
                type="text"
                id="otherInput"
                class="form-control"
                formControlName="other"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Причина/Reason</label>
          <textarea
            class="form-control"
            rows="5"
            id="ReasonInput"
            formControlName="reason"
            placeholder="Причина/Reason"
          ></textarea>
          <div
            *ngIf="
              operatorEditForm.get('reason')?.hasError('required') &&
              operatorEditForm.get('reason')?.touched
            "
            class="text-danger"
          >
            Reason is required.
          </div>
        </div>
        <div class="col">
          <label> Комментарии для технолога/Comments for Technologiest </label>
          <textarea
            class="form-control"
            rows="5"
            formControlName="techComments"
            name="comment"
            placeholder="Комментарии для технолога(опционально)/Comments for Technologiest"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="center">
  <button
    mat-raised-button
    mat-dialog-save
    [ngStyle]="{
      'background-color': operatorEditForm.invalid ? 'gray' : '#0066b2',
      color: 'white'
    }"
    [disabled]="operatorEditForm.invalid"
    (click)="handleSave()"
    onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
    onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
  >
    SAVE
  </button>
  <button mat-raised-button mat-dialog-close>CLOSE</button>
</div>
