<div class="row" [formGroup]="peDropdownFormGroup">
  <div class="col-md-3">
    <div class="form-group">
      <label for="ParkSelectInput">LPG Park</label>
      <select
        id="ParkSelectInput"
        (change)="onParkChange()"
        class="form-select"
        formControlName="lpgPark"
      >
        <option value="null" disabled>LPG Park</option>
        <option *ngFor="let park of parks" [value]="park">{{ park }}</option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('lpgPark')?.hasError('required') &&
        peDropdownFormGroup.get('lpgPark')?.touched
      "
      class="text-danger"
    >
      LPG Park is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="gasSelect">Gas Type</label>
      <select
        id="gasSelect"
        (change)="onGasChange()"
        class="form-select"
        formControlName="gasType"
      >
        <option value="null" disabled>Gas Type</option>
        <option *ngFor="let gas of gases" [value]="gas">{{ gas }}</option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('gasType')?.hasError('required') &&
        peDropdownFormGroup.get('gasType')?.touched
      "
      class="text-danger"
    >
      Gas Type is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="PurposeSelectInput">Purpose</label>
      <select
        id="PurposeSelectInput"
        class="form-select"
        formControlName="purpose"
      >
        <option value="null" disabled>Purpose</option>
        <option *ngFor="let purpose of purposes" [value]="purpose">
          {{ purpose }}
        </option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('purpose')?.hasError('required') &&
        peDropdownFormGroup.get('purpose')?.touched
      "
      class="text-danger"
    >
      Purpose is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="EntryTypeSelectInput">Entry Type</label>
      <select
        id="EntryTypeSelectInput"
        class="form-select"
        formControlName="entryType"
      >
        <option value="null" disabled>Entry Type</option>
        <option *ngFor="let entrytype of entrytypes" [value]="entrytype">
          {{ entrytype }}
        </option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('entryType')?.hasError('required') &&
        peDropdownFormGroup.get('entryType')?.touched
      "
      class="text-danger"
    >
      Entry Type is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="optionSelect">Bullet</label>
      <select
        id="optionSelect"
        class="form-select"
        (change)="onOptionChange()"
        formControlName="bullet"
      >
        <option value="null" disabled>Bullet</option>
        <option *ngFor="let bullet of bullets" [value]="bullet">
          {{ bullet }}
        </option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('bullet')?.hasError('required') &&
        peDropdownFormGroup.get('bullet')?.touched
      "
      class="text-danger"
    >
      Bullet is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="mwSelect">MW Selection Point</label>
      <select
        id="mwSelect"
        class="form-select"
        formControlName="mwSelectionPoint"
      >
        <option value="null" disabled>MW Selection Point</option>
        <option *ngFor="let point of MWSelectionPoints" [value]="point">
          {{ point }}
        </option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('mwSelectionPoint')?.hasError('required') &&
        peDropdownFormGroup.get('mwSelectionPoint')?.touched
      "
      class="text-danger"
    >
      MW Selection Point is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="categorySelect">Category</label>
      <select
        id="categorySelect"
        class="form-select"
        formControlName="category"
      >
        <option value="null" disabled>Category</option>
        <option *ngFor="let category of categories" [value]="category">
          {{ category }}
        </option>
      </select>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('category')?.hasError('required') &&
        peDropdownFormGroup.get('category')?.touched
      "
      class="text-danger"
    >
      Category is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label>Volume, Nm3</label>
      <div class="input-group mb-2 mr-sm-2">
        <input
          id="volume"
          name="volume"
          class="form-control"
          formControlName="volume"
          type="text"
        />
      </div>
    </div>
    <div
      *ngIf="
        peDropdownFormGroup.get('volume')?.hasError('required') &&
        peDropdownFormGroup.get('volume')?.touched
      "
      class="text-danger"
    >
      Volume is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group" *ngIf="isOtherSelected">
      <label for="otherInput">Other</label>
      <input
        type="text"
        id="otherInput"
        formControlName="other"
        class="form-control"
      />
    </div>
  </div>
</div>
