<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-content>
        <div class="row" style="margin: 0" *ngFor="let group of arr">
          <div>
            <div *ngFor="let item of group.items">
              <div *ngFor="let child of item.childs">
                <div *ngFor="let schild of child.schilds">

                  <div *ngIf="isSubFDKTLActive">
                    <div *ngIf="isSubFDKTLInputActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'KTL' &&
                          child.name === 'Input'
                        "
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <p class="subHeader">
                          {{ schild.name }}
                        </p>
                        <ul>
                          <li
                            class="row"
                            style="margin: 0; margin-bottom: 10px"
                            *ngFor="let tchild of schild.tchilds"
                          >
                            <a
                              mat-button
                              class="align-left"
                              [ngClass]="{
                                'active-button': isSubChildActive(tchild.name)
                              }"
                              [routerLink]="['/TCO/ProcessEng/' + tchild.route]"
                            >
                              {{ tchild.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div *ngIf="isSubFDKTLDailyReportActive || isSubFDKTL2F205Active || isSubFDKTL23F205Active">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'KTL' &&
                          child.name === 'Daily Report'
                        "
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <p class="subHeader">
                          {{ schild.name }}
                        </p>
                        <ul>
                          <li
                            class="row"
                            style="margin: 0; margin-bottom: 10px"
                            *ngFor="let tchild of schild.tchilds"
                          >
                            <a
                              mat-button
                              class="align-left"
                              [ngClass]="{
                                'active-button': isSubChildActive(tchild.name)
                              }"
                              [routerLink]="['/TCO/ProcessEng/' + tchild.route]"
                            >
                              {{ tchild.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isSubFDFIELDActive">
                    <div *ngIf="isSubFDFIELDInputActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'FIELD' &&
                          child.name === 'Input'
                        "
                      >
                        <a
                          mat-button
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>

                    <div *ngIf="isSubFDFIELDFieldCalcActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'FIELD' &&
                          child.name === 'Field Calc'
                        "
                      >
                        <a
                          mat-button
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>

                    <div *ngIf="isSubFDFIELDCCActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'FIELD' &&
                          child.name === 'Chemical Composition'
                        "
                      >
                        <a
                          mat-button
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>

                    <div *ngIf="isSubFDFIELDFinalReportActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'FIELD' &&
                          child.name === 'Final Report'
                        "
                      >
                        <a
                          mat-button
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>

                    
                  </div>

                  <div *ngIf="isSubFDSGPActive">
                    <div *ngIf="isSubFDSGPInputActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'SGP' &&
                          child.name === 'Input'
                        "
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <p class="subHeader">
                          {{ schild.name }}
                        </p>
                        <ul>
                          <li
                            class="row"
                            style="margin: 0; margin-bottom: 10px"
                            *ngFor="let tchild of schild.tchilds"
                          >
                            <a
                              mat-button
                              class="align-left"
                              [ngClass]="{
                                'active-button': isSubChildActive(tchild.name)
                              }"
                              [routerLink]="['/TCO/ProcessEng/' + tchild.route]"
                            >
                              {{ tchild.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isSubFDLPGActive">
                    <div *ngIf="isSubFDLPGInputActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'LPG' &&
                          child.name === 'Input'
                        "
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <p class="subHeader">
                          {{ schild.name }}
                        </p>
                        <ul>
                          <li
                            class="row"
                            style="margin: 0; margin-bottom: 10px"
                            *ngFor="let tchild of schild.tchilds"
                          >
                            <a
                              mat-button
                              class="align-left"
                              [ngClass]="{
                                'active-button': isSubChildActive(tchild.name)
                              }"
                              [routerLink]="['/TCO/ProcessEng/' + tchild.route]"
                            >
                              {{ tchild.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngIf="!isSubFDLPGInputActive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'LPG' &&
                          child.name === 'Final Report'
                        "
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <a
                          mat-button
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isSubFDSO2EMISSIONSActive">
                    <div *ngIf="isSubFDSO2EMISSIONSInputctive">
                      <div
                        *ngIf="
                          group.name === 'FD' &&
                          item.name === 'SO2 EMISSIONS' &&
                          child.name === 'Input'
                        "
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <p class="subHeader">
                          {{ schild.name }}
                        </p>
                        <ul>
                          <li
                            class="row"
                            style="margin: 0; margin-bottom: 10px"
                            *ngFor="let tchild of schild.tchilds"
                          >
                            <a
                              mat-button
                              class="align-left"
                              [ngClass]="{
                                'active-button': isSubChildActive(tchild.name)
                              }"
                              [routerLink]="['/TCO/ProcessEng/' + tchild.route]"
                            >
                              {{ tchild.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="isSubFGDInternalUsageActive"
                    style="padding: 0; margin: 0"
                  >
                    <div
                      *ngIf="isSubFGDInternalUsageInputActive"
                      style="padding: 0; margin: 0"
                    >
                      <div
                        *ngIf="
                          group.name === 'FGD' &&
                          item.name === 'Internal Usage' &&
                          child.name === 'Input'
                        "
                        style="padding: 0; margin: 0"
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <p class="subHeader">
                          {{ schild.name }}
                        </p>
                        <ul style="padding: 0; margin: 0">
                          <li
                            class="row"
                            style="padding: 0; margin: 0; margin-bottom: 10px"
                            *ngFor="let tchild of schild.tchilds"
                          >
                            <a
                              mat-button
                              style="padding-left: 5px; font-size: 1.1em"
                              class="align-left"
                              [ngClass]="{
                                'active-button': isSubChildActive(tchild.name)
                              }"
                              [routerLink]="['/TCO/ProcessEng/' + tchild.route]"
                            >
                              {{ tchild.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="isSubFGDInternalUsageActive"
                    style="padding: 0; margin: 0"
                  >
                    <div
                      *ngIf="isSubFGDInternalUsageCalculationsActive"
                      style="padding: 0; margin: 0"
                    >
                      <div
                        *ngIf="
                          group.name === 'FGD' &&
                          item.name === 'Internal Usage' &&
                          child.name === 'Calculations'
                        "
                        style="padding: 0; margin: 0"
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <a
                          mat-button
                          style="padding-left: 5px; font-size: 1.1em"
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="isSubFGDInternalUsageActive"
                    style="padding: 0; margin: 0"
                  >
                    <div
                      *ngIf="isSubFGDInternalUsageMonthlyReportActive"
                      style="padding: 0; margin: 0"
                    >
                      <div
                        *ngIf="
                          group.name === 'FGD' &&
                          item.name === 'Internal Usage' &&
                          child.name === 'Monthly Report'
                        "
                        style="padding: 0; margin: 0"
                      >
                        <!-- !isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && -->
                        <!--!isSubFDKTLDailyReportActive && !isSubFDKTLMonthlyReportActive && !isSubFDKTLCCActive && !isSubFDKTLSchemaActive &&-->
                        <a
                          mat-button
                          class="align-left"
                          [ngClass]="{
                            'active-button': isSubChildActive(schild.name)
                          }"
                          [routerLink]="['/TCO/ProcessEng/' + schild.route]"
                        >
                          {{ schild.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
