import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as chartJsActions from './so2-charts.action';
import { LPGClient, SO2Client } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class SO2ChartsEffects {
  constructor(private actions: Actions, private so2Client: SO2Client) {}

  so2EmissionFlowmeterGraph = createEffect(() =>
    this.actions.pipe(
      ofType(chartJsActions.getSO2EmissionsFlowmeterGraph),
      exhaustMap((action) => {
        return this.so2Client
          .getFlowmeterGraph(action.date, action.plant, action.areaType)
          .pipe(
            map((res) =>
              chartJsActions.getSO2EmissionsFlowmeterGraphSuccess({ data: res })
            ),
            catchError((err) =>
              of(chartJsActions.getSO2EmissionsFlowmeterGraphFailure(err))
            )
          );
      })
    )
  );
}
