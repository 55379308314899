import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { PEService } from 'src/app/core/services/lpg/pe.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common/common.service';
import {
  LPGFlowmeterCalculationInputAdd,
  getCalculation,
} from 'src/app/state/lpg/flowmeter-calculation/flowmeter-calculation.action';
import { FlowmeterCalculationSelector } from 'src/app/state/lpg/flowmeter-calculation/flowmeter-calculation.selector';
import {
  LPGFlowmeterCalculateType,
  LPGGasType,
  LPGPEInputDTO,
  LPGPark,
  LPGPurpose,
  PermitTargetInputCategory,
} from 'src/app/web-api-client';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

import {
  chartDataSelector,
  chartTitleSelector,
} from 'src/app/state/chartjs/chartjs.selector';

import {
  getChartData,
  getFlowMeterChartData,
} from 'src/app/state/chartjs/chartjs.action';

Chart.register(...registerables, zoomPlugin);

@Component({
  selector: 'app-flowmeter-calculation',
  templateUrl: './flowmeter-calculation.component.html',
  styleUrls: ['./flowmeter-calculation.component.scss'],
})
export class FlowmeterCalculationComponent {
  @Input() FlowmeterCalculationForm!: FormGroup;

  parks: string[] = [];
  gases: string[] = [];
  bullets: string[] = [];
  isOtherSelected: boolean = false;
  otherInputValue: string = '';
  purposes: string[] = [];
  entrytypes: string[] = [];
  MWSelectionPoints: string[] = [];
  categories: string[] = [];
  startTime: string = '';
  endTime: string = '';
  isDisabled: boolean = false;
  FlaringAverageFrom: string = '';
  FlaringAverageTo: string = '';
  AverageBeforeFlaring: any;
  FlaringStartTime: string = '';
  FlaringEndTime: string = '';

  chart: any;
  xLabels?: number[];

  yLabels?: number[];
  title?: string = 'Flared Gas Flowmeter';
  //data?: LPGFlowmeterValuesDTO[];

  initialTimeUnit = 'hour';
  zoomedTimeUnit = 'minute';
  teckStepInitial = 4;
  tickStepZoomed = 2;
  izZoomed = false;

  data: any;

  constructor(
    private store: Store<AppState>,
    private dataService: PEService,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.parks = this.dataService.getParks();
    this.purposes = this.dataService.getPurpose();
    this.entrytypes = this.dataService.getFlowMeterEntryType();
    this.categories = this.dataService.getCategory();
    this.gases = [];
    this.bullets = [];

    this.FlowmeterCalculationForm = this.fb.group({
      linkToSEEQ: [''],
      entryType: ['Flowmeter Calc'],
      FlaredVolume: [{ value: null, disabled: true }, [Validators.required, Validators.min(0)]],
      startTime: [
        this.commonService.convertToDateTimeZ(this.startTime),
        Validators.required,
      ],
      endTime: [
        this.commonService.convertToDateTimeZ(this.endTime),
        Validators.required,
      ],
      FlaringAverageFrom: [
        this.commonService.convertToDateTimeZ(this.FlaringAverageFrom),
        Validators.required,
      ],
      FlaringAverageTo: [
        this.commonService.convertToDateTimeZ(this.FlaringAverageTo),
        Validators.required,
      ],
      AverageBeforeFlaring: [{ value: null, disabled: true }],
      FlaringStartTime: [
        this.commonService.convertToDateTimeZ(this.FlaringStartTime),
        Validators.required,
      ],
      FlaringEndTime: [
        this.commonService.convertToDateTimeZ(this.FlaringEndTime),
        Validators.required,
      ],
      FlaredMass: [{ value: null, disabled: true }],
      lpgPark: ['', Validators.required],
      gasType: ['', Validators.required],
      purpose: ['', Validators.required],
      bullet: ['', Validators.required],
      mwSelectionPoint: ['', Validators.required],
      category: ['', Validators.required],
      other: [''],
      reason: ['', Validators.required],
    });

    const FlaringEndTimeControl =
      this.FlowmeterCalculationForm.get('FlaringEndTime');

    const entryTypeControl = this.FlowmeterCalculationForm.get('entryType');
    if (entryTypeControl) {
      entryTypeControl.valueChanges.subscribe((selectedEntryType) => {
        const flaredVolumeControl =
          this.FlowmeterCalculationForm.get('FlaredVolume');
        if (flaredVolumeControl) {
          if (selectedEntryType === 'Manual Entry') {
            flaredVolumeControl.enable();
          } else {
            flaredVolumeControl.disable();
            if (FlaringEndTimeControl) {
              // this.onVolumeChange();
            } else {
              flaredVolumeControl.disable();
            }
          }
        }
      });
    }

    this.setDefaultValues();

    this.store.select(chartDataSelector).subscribe((data) => {
      console.log('data', data);
      
      this.data = data;
      this.xLabels = data?.map((x) => x.timeStamp.getTime());
      this.xLabels?.push(this.xLabels[this.xLabels.length - 1] + 60000);
      if (this.data) this.createChart();
    });

    this.onGraphChange();
    this.setupFormListeners();
    this.onAverageChange();
    //this.onVolumeChangByDefault();
    this.onFlaredVolumeChange();

    window.addEventListener('beforeunload', () => {
      this.handleBeforeUnload();
    });
  }

  handleBeforeUnload() {
    window.opener.postMessage({ closedManually: true }, window.location.origin);
  }

  ngOnInit(): void {
    this.FlowmeterCalculationForm.get(
      'FlaringAverageFrom'
    )!.valueChanges.subscribe((newValue) => {
      this.updateFlaringStartTime(newValue);
    });
    this.FlowmeterCalculationForm.get(
      'FlaringAverageTo'
    )!.valueChanges.subscribe((newValue) => {
      this.updateFlaringEndTime(newValue);
    }); //FlaringStartTime
  }

  private updateFlaringStartTime(newDate: string | Date) {
    const originalDate =
      newDate instanceof Date ? newDate : new Date(newDate as string);

    const flaringStartTime = new Date(originalDate);
    flaringStartTime.setDate(flaringStartTime.getDate());

    const flaringAverageTo = new Date(originalDate);
    flaringAverageTo.setDate(flaringAverageTo.getDate());
    flaringAverageTo.setHours(flaringAverageTo.getHours() + 1);

    console.log('flaringStartTime:', flaringStartTime);
    console.log('flaringAverageTo:', flaringAverageTo);

    this.FlowmeterCalculationForm.patchValue({
      FlaringStartTime: this.commonService.convertToDateTimeZ(
        flaringStartTime.toString()
      ),
      FlaringAverageTo: this.commonService.convertToDateTimeZ(
        flaringAverageTo.toString()
      ),
    });
  }

  private updateFlaringEndTime(newDate: string | Date) {
    const originalDate =
      newDate instanceof Date ? newDate : new Date(newDate as string);

    const FlaringAverageFrom = new Date(
      this.FlowmeterCalculationForm.get('FlaringAverageFrom')?.value
    );
    const FlaringAverageTo = new Date(
      this.FlowmeterCalculationForm.get('FlaringAverageTo')?.value
    );

    const flaringStartTime = new Date(originalDate);
    flaringStartTime.setDate(FlaringAverageFrom.getDate());
    flaringStartTime.setMinutes(flaringStartTime.getMinutes() + 1);

    const flaringEndTime = new Date(flaringStartTime);
    flaringEndTime.setDate(FlaringAverageTo.getDate());
    flaringEndTime.setHours(flaringEndTime.getHours() + 1);

    this.FlowmeterCalculationForm.patchValue({
      FlaringEndTime: this.commonService.convertToDateTimeZ(
        flaringEndTime.toString()
      ),
      FlaringStartTime: this.commonService.convertToDateTimeZ(
        flaringStartTime.toString()
      ),
    });
  }

  private setupFormListeners(): void {
    this.FlowmeterCalculationForm.get('gasType')!.valueChanges.subscribe(
      (newValue) => {
        const resetBullet =
          newValue === 'Fuel Gas' ||
          newValue === 'Propane' ||
          newValue === 'Butane';
        const bulletControl = this.FlowmeterCalculationForm.get('bullet')!;

        if (resetBullet) {
          bulletControl.setValue('');
          bulletControl.clearValidators();
        } else {
          bulletControl.clearValidators();
          bulletControl.setValidators([Validators.required]);
        }

        bulletControl.updateValueAndValidity();
      }
    );
  }

  onAverageChange() {
    const startControl =
      this.FlowmeterCalculationForm.get('FlaringAverageFrom');
    const endControl = this.FlowmeterCalculationForm.get('FlaringAverageTo');

    const averageControl = this.FlowmeterCalculationForm.get(
      'AverageBeforeFlaring'
    );

    if (startControl && endControl) {
      const startTime = new Date(startControl.value);
      const endTime = new Date(endControl.value);

      this.store.dispatch(
        getCalculation({
          startTime,
          endTime,
          calculationType: LPGFlowmeterCalculateType.Average,
          avg: 0,
          volume: 0,
          mw: 0,
        })
      );

      if (averageControl) {
        this.store.select(FlowmeterCalculationSelector).subscribe((data) => {
          this.FlowmeterCalculationForm.patchValue({
            AverageBeforeFlaring: data.average,
          });
        });
      }
    }

    this.onFlaredVolumeChange();
  }

  /*
  onVolumeChange(){
    const startControl = this.FlowmeterCalculationForm.get('FlaringStartTime');
    const endControl = this.FlowmeterCalculationForm.get('FlaringEndTime');
  
    const averageControl = this.FlowmeterCalculationForm.get('AverageBeforeFlaring');
    //const avgValue = averageControl?.value;
    //console.log(avgValue)
    const volumeControl = this.FlowmeterCalculationForm.get('FlaredVolume');
    
    if (startControl && endControl) {
      const startTime = new Date(startControl.value)
      const endTime = new Date(endControl.value)
      const avgValue = averageControl?.value;
  
      this.store.dispatch(getCalculation({
        startTime,
        endTime,
        calculationType: LPGFlowmeterCalculateType.Volume,
        avg: avgValue,
        volume: 0,
        mw: 0
      }));
      if (volumeControl && this.FlowmeterCalculationForm) {
        this.store.select(FlowmeterCalculationSelector).subscribe(data => {
          this.FlowmeterCalculationForm.get('FlaredVolume')?.patchValue( data.volume);
        });
      }
    }

  }
*/
  onFlaredVolumeChange() {
    const entryTypeControl = this.FlowmeterCalculationForm.get('entryType');
    const flaredVolumeControl =
      this.FlowmeterCalculationForm.get('FlaredVolume');

    if (entryTypeControl?.value === 'Manual Entry' && flaredVolumeControl) {
      this.onMassChange();
    } else if (
      entryTypeControl?.value === 'Flowmeter Calc' &&
      flaredVolumeControl
    ) {
      this.onVolumeChangByDefault();
    }
  }

  onVolumeChangByDefault() {
    const startControl = this.FlowmeterCalculationForm.get('FlaringStartTime');
    const endControl = this.FlowmeterCalculationForm.get('FlaringEndTime');
    const volumeControl = this.FlowmeterCalculationForm.get('FlaredVolume');

    if (startControl && endControl && volumeControl) {
      const startTime = new Date(startControl.value);
      const endTime = new Date(endControl.value);

      setTimeout(() => {
        const averageControl = this.FlowmeterCalculationForm.get(
          'AverageBeforeFlaring'
        );
        const avgValue = averageControl?.value;

        this.store.dispatch(
          getCalculation({
            startTime,
            endTime,
            calculationType: LPGFlowmeterCalculateType.Volume,
            avg: avgValue,
            volume: 0,
            mw: 0,
          })
        );

        if (this.FlowmeterCalculationForm) {
          this.store.select(FlowmeterCalculationSelector).subscribe((data) => {
            this.FlowmeterCalculationForm.get('FlaredVolume')?.patchValue(
              data.volume
            );
          });
        }
      }, 4000); // 4 seconds delay
    }
  }

  onMassChange() {
    const startControl = this.FlowmeterCalculationForm.get('FlaringStartTime');
    const endControl = this.FlowmeterCalculationForm.get('FlaringEndTime');

    const averageControl = this.FlowmeterCalculationForm.get(
      'AverageBeforeFlaring'
    );
    const volumeControl = this.FlowmeterCalculationForm.get('FlaredVolume');
    const mwControl = this.dataService.formatMWSelectionPoint(
      this.FlowmeterCalculationForm.value.mwSelectionPoint,
      this.FlowmeterCalculationForm.value.gasType
    );

    const massControl = this.FlowmeterCalculationForm.get('FlaredMass');

    if (startControl && endControl) {
      const startTime = new Date(startControl.value);
      const endTime = new Date(endControl.value);
      const avgValue = averageControl?.value;
      const volValue = volumeControl?.value;
      const mwValue = mwControl;

      this.store.dispatch(
        getCalculation({
          startTime,
          endTime,
          calculationType: LPGFlowmeterCalculateType.Mass,
          avg: avgValue,
          volume: volValue,
          mw: mwValue,
        })
      );
      if (massControl && this.FlowmeterCalculationForm) {
        this.store.select(FlowmeterCalculationSelector).subscribe((data) => {
          this.FlowmeterCalculationForm.patchValue({
            FlaredMass: data.mass,
            FlaredVolume: volValue,
          });
        });
      }
    }
  }

  onGraphChange() {
    const startControl = this.FlowmeterCalculationForm.get('startTime');
    const endControl = this.FlowmeterCalculationForm.get('endTime');

    if (startControl && endControl) {
      const startTime = new Date(startControl.value);
      const endTime = new Date(endControl.value);

      this.store.dispatch(
        getFlowMeterChartData({
          startTime: new Date(startTime),
          endTime: new Date(endTime),
          graphType: 2,
        })
      );
    }
  }

  updateLink() {
    const formControl = this.FlowmeterCalculationForm.get('linkToSEEQ');

    if (formControl) {
      const linkValue = formControl.value;
      this.FlowmeterCalculationForm.patchValue({ linkToSEEQ: linkValue });
    }
  }

  onParkChange() {
    this.gases = this.dataService.getGasesForPark(
      this.FlowmeterCalculationForm.value.lpgPark
    );
    this.FlowmeterCalculationForm.get('gasType')?.enable();
    this.FlowmeterCalculationForm.patchValue({
      gasType: '',
      bullet: '',
    });
    this.bullets = [];
    this.isOtherSelected = false;
  }

  onGasChange() {
    this.bullets = this.dataService.getOptionsForGas(
      this.FlowmeterCalculationForm.value.lpgPark,
      this.FlowmeterCalculationForm.value.gasType
    );
    this.FlowmeterCalculationForm.get('bullet')?.enable();
    this.FlowmeterCalculationForm.get('mwSelectionPoint')?.enable();
    this.MWSelectionPoints = this.dataService.getMWSelectionPoints(
      this.FlowmeterCalculationForm.value.gasType
    );
    this.FlowmeterCalculationForm.patchValue({
      bullet: '',
    });
    this.isOtherSelected = false;
  }

  onOptionChange() {
    this.isOtherSelected =
      this.FlowmeterCalculationForm.value.bullet === 'Other';
    if (!this.isOtherSelected) {
      this.otherInputValue = '';
    }
  }

  setDefaultValues() {
    const today = new Date();
    const todayFlaring = new Date();
    const previousDay = new Date();
    const sixDaysAgo = new Date();

    const flaringAverageFromDate = new Date();
    const flaringAverageToDate = new Date();
    const flaringStartTimeDate = new Date();
    const flaringEndTimeDate = new Date();

    today.setHours(1, 59, 0, 0);
    todayFlaring.setHours(1, 59, 0, 0);
    previousDay.setHours(0, 59, 0, 0);
    sixDaysAgo.setHours(2, 0, 0, 0);

    flaringAverageFromDate.setHours(0, 59, 0, 0);
    flaringAverageToDate.setHours(1, 59, 0, 0);
    flaringStartTimeDate.setHours(2, 0, 0, 0);
    flaringEndTimeDate.setHours(3, 0, 0, 0);

    previousDay.setDate(today.getDate());
    sixDaysAgo.setDate(today.getDate() - 6);

    flaringAverageFromDate.setDate(today.getDate());
    flaringAverageToDate.setDate(today.getDate());
    flaringStartTimeDate.setDate(today.getDate());
    flaringEndTimeDate.setDate(today.getDate());

    const formattedSixDaysAgo = this.commonService.convertToDateTimeZ(
      sixDaysAgo.toString()
    );
    const formattedPreviosDay = this.commonService.convertToDateTimeZ(
      previousDay.toString()
    );
    const formattedToday = this.commonService.convertToDateTimeZ(
      today.toString()
    );
    const formattedFlaringToday = this.commonService.convertToDateTimeZ(
      todayFlaring.toString()
    );

    const flaringAverageFrom = this.commonService.convertToDateTimeZ(
      flaringAverageFromDate.toString()
    );
    const flaringAverageTo = this.commonService.convertToDateTimeZ(
      flaringAverageToDate.toString()
    );
    const flaringStartTime = this.commonService.convertToDateTimeZ(
      flaringStartTimeDate.toString()
    );
    const flaringEndTime = this.commonService.convertToDateTimeZ(
      flaringEndTimeDate.toString()
    );

    console.log(formattedSixDaysAgo);
    console.log(formattedPreviosDay);
    console.log(formattedToday);

    this.FlowmeterCalculationForm.patchValue({
      startTime: formattedSixDaysAgo,
      endTime: formattedToday,
      FlaringAverageFrom: flaringAverageFrom,
      FlaringAverageTo: flaringAverageTo,
      FlaringStartTime: flaringStartTime,
      FlaringEndTime: flaringEndTime,
    });
  }

  nearestTimeRange(val: number): number {
    const stepSizes = [240, 120, 60, 30, 15, 10, 5, 1];
    let nearest = 0;
    let diff = 300;
    stepSizes.forEach((x, index) => {
      if (diff > Math.abs(x - val)) {
        nearest = index;
        diff = Math.abs(x - val);
      }
    });
    return stepSizes[nearest];
  }

  createChart() {
    var oldChart = Chart.getChart('MyChart');
    if (oldChart != undefined) oldChart.destroy();
    this.chart = new Chart('MyChart', {
      type: 'line',
      data: {
        labels: this.xLabels, //timestamp
        datasets: [
          //values
          {
            label: '80FI351043_2S.PV',
            data: this.data?.map((y: { value: any }) => y.value),
            // data: this.data?.map((x) => x.value),
            backgroundColor: 'green',
            borderColor: 'green',
            pointBackgroundColor: 'green',
          },
        ],
      },
      options: {
        // responsive: true,
        // aspectRatio: 2.5,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              onZoom: ({ chart }) => {
                if (chart.options.scales) {
                  const max = chart.options.scales['x']?.max as number;
                  const min = chart.options.scales['x']?.min as number;
                  const newStepSize = this.nearestTimeRange(
                    Math.floor((max - min) / 1000000)
                  );
                  if (chart.options.scales['x'])
                    chart.options.scales['x'].ticks = {
                      stepSize: newStepSize,
                      source: 'auto',
                      maxRotation: 90,
                      minRotation: 90,
                      autoSkip: true,
                    };
                }
              },
              mode: 'xy',
            },
          },
          title: {
            display: true,
            text: this.title,
          },
        },

        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              displayFormats: {
                minute: 'D-MMM,HH:mm',
              },
              tooltipFormat: 'D-MMM,HH:mm',
            },
            title: {
              display: true,
              text: 'Time',
            },
            ticks: {
              source: 'auto',
              stepSize: 240,
              maxRotation: 90,
              minRotation: 90,
              autoSkip: true,
            },
          },
          y: {
            title: {
              display: true,
              text: 'Value',
            },
          },
        },
      },
    });
  }

  handleSave() {
    const newFlowmeterCalc = new LPGPEInputDTO();

    newFlowmeterCalc.init(this.FlowmeterCalculationForm.value);

    newFlowmeterCalc.startTime =
      this.FlowmeterCalculationForm.value.FlaringStartTime; //

    newFlowmeterCalc.endTime =
      this.FlowmeterCalculationForm.value.FlaringEndTime; //

    newFlowmeterCalc.lpgPark =
      this.FlowmeterCalculationForm.value.lpgPark === 'New Park - SGP'
        ? LPGPark.NewParkSGP
        : LPGPark.OldParkKTL; //

    switch (this.FlowmeterCalculationForm.value.gasType) {
      case 'Butane':
        newFlowmeterCalc.gasType = LPGGasType.Butane;
        break;
      case 'Propane':
        newFlowmeterCalc.gasType = LPGGasType.Propane;
        break;
      default:
        newFlowmeterCalc.gasType = LPGGasType.Dry;
    } //

    newFlowmeterCalc.purpose =
      this.FlowmeterCalculationForm.value.purpose === 'Flared'
        ? LPGPurpose.Flared
        : LPGPurpose.Leakage; //

    newFlowmeterCalc.isAuto =
      this.FlowmeterCalculationForm.value.entryType === 'FlowmeterCalc'
        ? true
        : false; //

    const bulletControl = this.FlowmeterCalculationForm.get('bullet'); //
    if (bulletControl) {
      newFlowmeterCalc.bullet = bulletControl.value;
    }

    // newFlowmeterCalc.bullet = this.FlowmeterCalculationForm.value.bullet;

    /*
    const otherControl = this.FlowmeterCalculationForm.get('other'); //
    if (otherControl) {
      newFlowmeterCalc.other = otherControl.value;
    } 
*/
    newFlowmeterCalc.other = this.FlowmeterCalculationForm.value.other;

    // thereisnotechcomments

    newFlowmeterCalc.mwSelectionPoint = this.dataService.formatMWSelectionPoint(
      //
      this.FlowmeterCalculationForm.value.mwSelectionPoint,
      this.FlowmeterCalculationForm.value.gasType
    );

    switch (
      this.FlowmeterCalculationForm.value.category //
    ) {
      case 'V6':
        newFlowmeterCalc.category = PermitTargetInputCategory.V6;
        break;
      case 'V7':
        newFlowmeterCalc.category = PermitTargetInputCategory.V7;
        break;
      case 'V8':
        newFlowmeterCalc.category = PermitTargetInputCategory.V8;
        break;
      default:
        newFlowmeterCalc.category = PermitTargetInputCategory.V9;
    }

    const reasonControl = this.FlowmeterCalculationForm.get('reason'); //
    if (reasonControl) {
      newFlowmeterCalc.reason = reasonControl.value;
    }

    //newFlowmeterCalc.reason = this.FlowmeterCalculationForm.value.reason;

    //newFlowmeterCalc.isManual = this.FlowmeterCalculationForm.value.entryType === 'Manual Entry' ? true : false;  //

    newFlowmeterCalc.flaringAvgFrom =
      this.FlowmeterCalculationForm.value.FlaringAverageFrom; //

    newFlowmeterCalc.flaringAvgTo =
      this.FlowmeterCalculationForm.value.FlaringAverageTo; //

    const FlaringAVGvolumeControl = this.FlowmeterCalculationForm.get(
      'AverageBeforeFlaring'
    ); //
    if (FlaringAVGvolumeControl) {
      newFlowmeterCalc.flaringAvgVolume = FlaringAVGvolumeControl.value;
    }

    // newFlowmeterCalc.flaringAvgVolume = this.FlowmeterCalculationForm.value.AverageBeforeFlaring;

    const FlaredvolumeControl =
      this.FlowmeterCalculationForm.get('FlaredVolume');
    if (FlaredvolumeControl) {
      newFlowmeterCalc.flaredVolume = FlaredvolumeControl.value;
      newFlowmeterCalc.volume = FlaredvolumeControl.value;
    }

    // newFlowmeterCalc.flaredVolume = this.FlowmeterCalculationForm.value.FlaredVolume;
    // newFlowmeterCalc.volume = this.FlowmeterCalculationForm.value.FlaredVolume;

    newFlowmeterCalc.flaringStartTime =
      this.FlowmeterCalculationForm.value.FlaringStartTime; //

    newFlowmeterCalc.flaringEndTime =
      this.FlowmeterCalculationForm.value.FlaringEndTime; //

    const FlaredmassControl = this.FlowmeterCalculationForm.get('FlaredMass'); //

    if (FlaredmassControl) {
      newFlowmeterCalc.flaredMass = FlaredmassControl.value;
      newFlowmeterCalc.mass = FlaredmassControl.value;
    }

    // newFlowmeterCalc.flaredMass = this.FlowmeterCalculationForm.value.FlaredMass;
    // newFlowmeterCalc.mass = this.FlowmeterCalculationForm.value.FlaredMass;

    const seeqControl = this.FlowmeterCalculationForm.get('linkToSEEQ'); //
    if (seeqControl) {
      newFlowmeterCalc.seeq = seeqControl.value;
    }

    //newFlowmeterCalc.seeq = this.FlowmeterCalculationForm.value.seeqControl;

    newFlowmeterCalc.isFlowmeter = true;

    this.store.dispatch(LPGFlowmeterCalculationInputAdd(newFlowmeterCalc));
    this.FlowmeterCalculationForm.reset();

    console.log(newFlowmeterCalc);

    this.commonService.closePage();

    // this.dialogRef.close();
  }
}
