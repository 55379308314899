import { createAction, props } from '@ngrx/store';
import {
  KTLSGPSO2RecalculationRecordDTO,
  SO2StreamGroupChildrenTypes,
} from 'src/app/web-api-client';

export const ListSO2EmissionsRecalculation = createAction(
  '[SO2EmissionsRecalc] List SO2 Emissions',
  props<{ startTime: Date; train: SO2StreamGroupChildrenTypes }>()
);
export const ListSO2EmissionsRecalculationSuccess = createAction(
  '[SO2EmissionsRecalc] List SO2 Emissions Success',
  props<{ data: KTLSGPSO2RecalculationRecordDTO[] }>()
);
export const ListSO2EmissionsRecalculationFailure = createAction(
  '[SO2Recalc] List SO2 Emissions Failure',
  props<{ message: string }>()
);

export const AddSO2EmissionsRecalculation = createAction(
  '[SO2Recalc] Add SO2 Recalc',
  props<{ props: KTLSGPSO2RecalculationRecordDTO }>()
);
export const AddSO2EmissionsRecalculationSuccess = createAction(
  '[SO2Recalc] Add SO2 Recalc Success',
  props<any>()
);
export const AddSO2EmissionsRecalculationFailure = createAction(
  '[SO2Recalc] Add SO2 Recalc Failure',
  props<{ message: string }>()
);

export const DeleteSO2EmissionsRecalculation = createAction(
  '[SO2Recalc] Delete SO2 Recalc',
  props<{ props: KTLSGPSO2RecalculationRecordDTO }>()
);
export const DeleteSO2EmissionsRecalculationSuccess = createAction(
  '[SO2Recalc] Delete SO2 Recalc Success',
  props<any>()
);
export const DeleteSO2EmissionsRecalculationFailure = createAction(
  '[SO2Recalc] Delete SO2 Recalc Failure',
  props<{ message: string }>()
);
