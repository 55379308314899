import { Action, createReducer, on } from '@ngrx/store';
import * as FuelGasFlaringAction from './lpg-data.actions';
import { LPGDataDTO } from 'src/app/web-api-client';

export interface LPGDataState {
  data: LPGDataDTO | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: LPGDataState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const LPGDataReducer = createReducer(
  initialState,
  on(FuelGasFlaringAction.LPGData, (state, result) => ({
    ...state,
    data: null,
    isLoading: true,
  })),
  on(FuelGasFlaringAction.LPGDataSuccess, (state, result) => ({
    ...state,
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(FuelGasFlaringAction.LPGDataFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
