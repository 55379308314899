import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  concatMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as DailyReportActions from './daily-report.actions';
import * as notificationActions from '../../../../../../../state/notification/notification.action';
import {
  CalculatedStreamInputRecordDTO,
  DailyReportDTO,
  KTLSGPClient,
} from '../../../../../../../web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class DailyReportEffects {
  constructor(
    private actions: Actions,
    private ktlfdClient: KTLSGPClient,
    private store: Store<AppState>
  ) {}

  addGenerateReports = createEffect(() =>
    this.actions.pipe(
      ofType(DailyReportActions.AddGenerateReports),
      exhaustMap((action) => {
        return this.ktlfdClient.generateReports(action.startTime).pipe(
          concatMap((res) => [
            DailyReportActions.AddGenerateReportsSuccess(res),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(DailyReportActions.AddGenerateReportsFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  DailyReport = createEffect(() =>
    this.actions.pipe(
      ofType(DailyReportActions.getDailyReport),
      exhaustMap((action) => {
        this.store.dispatch(notificationActions.startLoading());

        return this.ktlfdClient.getReportDaily(action.dt, action.flare).pipe(
          map((res) => {
            this.store.dispatch(notificationActions.stopLoading());

            const dataArray: DailyReportDTO[] = [res];

            return DailyReportActions.getDailyReportSuccess({
              data: dataArray,
            });
          }),
          catchError((err) => {
            this.store.dispatch(notificationActions.stopLoading());
            this.store.dispatch(notificationActions.showNotification({ isSuccess: false }));
            
            return of(DailyReportActions.getDailyReportFailure(err))
          })
        );
      })
    )
  );

  DailyReportStreamDetails = createEffect(() =>
    this.actions.pipe(
      ofType(DailyReportActions.getDailyReportStreamDetails),
      exhaustMap((action) => {
        return this.ktlfdClient
          .getDailyReportStreamDetails(action.dt, action.streamId)
          .pipe(
            map((res) => {
              const dataArray: CalculatedStreamInputRecordDTO[] = [res];
              return DailyReportActions.getDailyReportStreamDetailsSuccess({
                data: dataArray,
              });
            }),
            catchError((err) =>
              of(DailyReportActions.getDailyReportStreamDetails(err))
            )
          );
      })
    )
  );
}
