import { createAction, props } from '@ngrx/store';
import { GURInputRecordDTO, GetGURDTO } from 'src/app/web-api-client';

export const getGUR = createAction(
  '[SGP] Get GUR',
  props<{ startTime: Date }>()
);
export const getGURSuccess = createAction(
  '[SGP] Get GUR Success',
  props<{ data: GetGURDTO}>()
);
export const getGURFailure = createAction(
  '[SGP] Get GUR Failure',
  props<{ message: string }>()
);

export const FGDSGPGURManualRecord = createAction(
  '[SGP] GUR Manual Record',
  props<{props: GURInputRecordDTO}>()
);
export const FGDSGPGURManualRecordSuccess = createAction(
  '[SGP] GUR Manual Record Success',
  props<any>()
);
export const FGDSGPGURManualRecordFailure = createAction(
  '[SGP] GUR Manual Record Failure',
  props<{ message: string }>()
);