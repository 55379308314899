import { createSelector } from '@ngrx/store';
import { AppState } from '../../../core/models/appState.model';
import {
  LPGDataDTO,
  LPGDataForAnalystDTO,
  LPGGasType,
} from 'src/app/web-api-client';
import {
  AnalystReportType,
  BulletsReportType,
  LPGDataReportType,
} from 'src/app/core/models/lpgDataReport.model';

export const LPGDataSelector = createSelector(
  (state: AppState) => state.lpgData.data,
  (list: LPGDataDTO | null) => {
    let data1: LPGDataReportType = {
      table1: {
        dry: list?.lpgData
          .filter(
            (x) =>
              x.elementName ===
                'Total flared gas from LPG Park for Monthly Flaring report' &&
              x.gasType === LPGGasType.Dry
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName ===
                'Total flared gas from LPG Park for Monthly Flaring report' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName ===
                'Total flared gas from LPG Park for Monthly Flaring report' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table2: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Old (KTL) LPG park' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Old (KTL) LPG park' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table3: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'New (SGP) LPG park' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'New (SGP) LPG park' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table4: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName ===
                'E-909 and flaring from bullets during PSVs repair work' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName ===
                'E-909 and flaring from bullets during PSVs repair work' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table5: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'SGP LPG Equalizing Line flaring' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'SGP LPG Equalizing Line flaring' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table6: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Data for PDV Daily Monitoring' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Data for PDV Daily Monitoring' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table7: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Data for Accounting Analyst: Old Park' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Data for Accounting Analyst: Old Park' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table8: {
        propane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Data for Accounting Analyst: New' &&
              x.gasType === LPGGasType.Propane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
        butane: list?.lpgData
          .filter(
            (x) =>
              x.elementName === 'Data for Accounting Analyst: New' &&
              x.gasType === LPGGasType.Butane
          )
          .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      },
      table9: list?.lpgData
        .filter((x) => x.elementName === 'Fuel Gas Flaring')
        .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
    };
    let data2: AnalystReportType = {
      table1: {
        propane: list?.forAnalysts.filter(
          (x) => x.gasType === LPGGasType.Propane
        ),
        butane: list?.forAnalysts.filter(
          (x) => x.gasType === LPGGasType.Butane
        ),
      },
      table2: new Array<LPGDataForAnalystDTO>(
        list?.forAnalysts[4]!,
        list?.forAnalysts[5]!
      ),
      table3: new Array<LPGDataForAnalystDTO>(
        list?.forAnalysts[6]!,
        list?.forAnalysts[7]!,
        list?.forAnalysts[8]!
      ),
    };
    let data3: BulletsReportType = {
      table1: list?.bulletsEntry
        .filter((x) => x.elementName === 'Old LPG Park - RTC Loaded')
        .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
      table2: list?.bulletsEntry
        .filter((x) => x.elementName === 'New LPG Park - RTC Loaded')
        .sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()),
    };
    return { data1, data2, data3 };
  }
);
