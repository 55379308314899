import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EditFuelGasFlaringData } from 'src/app/state/lpg/fuel-gas-flaring/fuel-gas-flaring.actions';
import {
  LPGFuelGasFlaringDefaultDTO,
  LPGMonthlyInputRecordDTO,
  LPGMonthlyInputRecordType,
} from 'src/app/web-api-client';
import { getFuelGasFlaringDefault } from '../store/fuel-gas-flaring-default.action';
import { fuelGasFlaringDefault } from '../store/fuel-gas-flaring-default.selector';

@Component({
  selector: 'app-fuel-gas-flaring-edit',
  templateUrl: './fuel-gas-flaring-edit.component.html',
  styleUrls: ['./fuel-gas-flaring-edit.component.scss'],
})
export class FuelGasFlaringEditComponent implements OnInit {
  fuelGasFlaringEditForm: FormGroup = this.fb.group({});
  title: string = '';

  inputs = {
    volume: false,
    pilotGasRate: false,
    purgeRate: false,
    purgeRate2: false,
    numberOfFuelGasLine: false,
  };

  defaultValues: LPGFuelGasFlaringDefaultDTO = new LPGFuelGasFlaringDefaultDTO({
    purgeRate: 16.5,
    purgeRate2: 48,
    numberOfFuelGasLine: 4,
    pilotGasRate: 6,
  });

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FuelGasFlaringEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.title = this.data.elementName;
    this.enableInputs();
    this.store.dispatch(
      getFuelGasFlaringDefault({ date: new Date(this.data.startTime) })
    );
    this.fuelGasFlaringEditForm = this.fb.group({
      volume: [
        this.data.totalVolume,
        this.inputs.volume ? Validators.required : null,
      ],
      calculation: ['Manual'],
      comment: [this.data.comment],
      pilotGasRate: [
        this.defaultValues.pilotGasRate,
        this.inputs.pilotGasRate ? Validators.required : null,
      ],
      purgeRate: [
        this.defaultValues.purgeRate,
        this.inputs.purgeRate ? Validators.required : null,
      ],
      purgeRate2: [
        this.defaultValues.purgeRate2,
        this.inputs.purgeRate2 ? Validators.required : null,
      ],
      numberOfFuelGasLine: [
        this.defaultValues.numberOfFuelGasLine,
        this.inputs.numberOfFuelGasLine ? Validators.required : null,
      ],
    });
    this.store.select(fuelGasFlaringDefault).subscribe((defaults) => {
      if (defaults) this.defaultValues = defaults;
      this.setDefaultValues();
    });
  }

  enableInputs() {
    const initialInputs = {
      volume: false,
      pilotGasRate: false,
      purgeRate: false,
      purgeRate2: false,
      numberOfFuelGasLine: false,
    };
    switch (this.title) {
      case 'Smokeless flaring':
        this.inputs = {
          ...initialInputs,
          volume: true,
        };
        break;
      case 'Smoke spreading':
        this.inputs = {
          ...initialInputs,
          volume: true,
        };
        break;
      case 'Pilot gas':
        this.inputs = {
          ...initialInputs,
          pilotGasRate: true,
        };
        break;
      case 'Flare header purge':
        this.inputs = {
          ...initialInputs,
          purgeRate: true,
          purgeRate2: true,
          numberOfFuelGasLine: true,
        };
        break;
      default:
        this.inputs = initialInputs;
    }
  }

  getType(type: string): LPGMonthlyInputRecordType {
    switch (type) {
      case 'Smokeless flaring':
        return LPGMonthlyInputRecordType.Smokeless;
      case 'Smoke spreading':
        return LPGMonthlyInputRecordType.Smoke;
      case 'Pilot gas':
        return LPGMonthlyInputRecordType.Pilot;
      case 'Flare header purge':
        return LPGMonthlyInputRecordType.FlareHeader;
      default:
        return LPGMonthlyInputRecordType.Smoke;
    }
  }

  setDefaultValues() {
    console.log(this.defaultValues);
    this.fuelGasFlaringEditForm.patchValue({
      pilotGasRate: this.defaultValues.pilotGasRate,
      purgeRate: this.defaultValues.purgeRate,
      purgeRate2: this.defaultValues.purgeRate2,
      numberOfFuelGasLine: this.defaultValues.numberOfFuelGasLine,
    });
  }

  handleSave() {
    if (this.fuelGasFlaringEditForm.valid) {
      let newData = new LPGMonthlyInputRecordDTO();
      newData = { ...newData, ...this.fuelGasFlaringEditForm.value };
      newData.type = this.getType(this.data.elementName);
      newData.startTime = new Date(this.data.startTime);
      newData.isAuto = this.fuelGasFlaringEditForm.value.calculation === 'Auto';
      // console.log(newData);
      this.store.dispatch(EditFuelGasFlaringData({ data: newData }));
    }
  }
}
