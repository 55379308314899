import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  concatMap,
  withLatestFrom,
  toArray,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as FuelGasActions from './fuel-gas.actions';
import * as notificationActions from '../../../../../../../state/notification/notification.action';
import { SGPFGDClient } from '../../../../../../../web-api-client';
import { SGPFGDCalculatedDTO } from 'src/app/web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';


@Injectable({
  providedIn: 'root',
})
export class FuelGasEffects {
  constructor(
    private actions: Actions,
    private sgpfgdClient: SGPFGDClient,
    private store: Store<AppState>
  ) {}

  dailyFuelGas = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.getDailyFuelGas),
      exhaustMap((action) => {
        return this.sgpfgdClient
          .getDailyRecords(new Date(action.startTime))
          .pipe(
            map((res) =>
            FuelGasActions.getDailyFuelGasSuccess({ data: res })
            ),
            catchError((err) =>
              of(FuelGasActions.getDailyFuelGasFailure(err))
            )
          );
      })
    )
  );

  isDailyRequestExist = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.getIsDailyRecordExist),
      exhaustMap((action) => {
        return this.sgpfgdClient
          .isDailyRecordsExist(new Date(action.startTime))
          .pipe(
            map((res) =>
            FuelGasActions.getIsDailyRecordExistSuccess({ isDailyRecordExistdata: res })
            ),
            catchError((err) =>
              of(FuelGasActions.getIsDailyRecordExistFailure(err))
            )
          );
      })
    )
  );

  monthlyFuelGas = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.getMonthlyFuelGas),
      exhaustMap((action) => {
        return this.sgpfgdClient
          .getMonthlyRecords(new Date(action.startTime))
          .pipe(
            map((res) =>
            FuelGasActions.getMonthlyFuelGasSuccess({ data: res })
            ),
            catchError((err) =>
              of(FuelGasActions.getMonthlyFuelGasFailure(err))
            )
          );
      })
    )
  );

  ManualRecord = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.FGDSGPManualRecord),
      exhaustMap((action) => {
        return this.sgpfgdClient.addManualRecord(action).pipe(
          concatMap((res) => [
            FuelGasActions.FGDSGPManualRecordSuccess(res),
            FuelGasActions.getDailyFuelGas({
              startTime: action.startTime
            }),
            FuelGasActions.getIsDailyRecordExist({
              startTime: action.startTime
            }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(FuelGasActions.FGDSGPManualRecordFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  addOverrideManualRecord = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.AddOverrideManualRecord),
      exhaustMap((action) => {
        return this.sgpfgdClient.overrideManualRecord(action.props).pipe(
          concatMap((res) => [
            FuelGasActions.AddOverrideManualRecordSuccess(res),
            FuelGasActions.getDailyFuelGas({ startTime: action.props.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(FuelGasActions.AddOverrideManualRecordFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  addOverrideManualRecords = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.AddOverrideManualRecords),
      exhaustMap((action) => {
        const startTime = action.date;
        const req$ = from(action.props).pipe(
          concatMap(
            prop => this.sgpfgdClient.overrideManualRecord(prop),
            prop => this.sgpfgdClient.getDailyRecords( startTime )
          )
        );

        this.store.dispatch(notificationActions.startLoading())

        return req$.pipe(
          toArray(),
          concatMap((res) => [
            FuelGasActions.AddOverrideManualRecordsSuccess(res),
            FuelGasActions.getDailyFuelGas({ startTime }),
            FuelGasActions.getIsDailyRecordExist({ startTime }),
            notificationActions.showNotification({ isSuccess: true }),
            notificationActions.stopLoading(),
          ]),
          catchError((err) => [
            of(FuelGasActions.AddOverrideManualRecordFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
            notificationActions.stopLoading(),
          ])
        );
      }),
    )
  );
  

  deleteOverrideRecord = createEffect(() =>
  this.actions.pipe(
    ofType(FuelGasActions.DeleteOverrideRecord),
    exhaustMap((action) => {
      return this.sgpfgdClient.deleteOverrideRecord(action.streamId, action.startTime).pipe(
        concatMap((res) => [
          FuelGasActions.DeleteOverrideRecordSuccess(res),
          FuelGasActions.getDailyFuelGas({ startTime: action.startTime }),
          notificationActions.showNotification({ isSuccess: true }),
        ]),
        catchError((err) => [
          of(FuelGasActions.DeleteOverrideRecordFailure(err)),
          notificationActions.showNotification({ isSuccess: false }),
        ])
      );
    })
  )
);

}
