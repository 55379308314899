import { Action, createReducer, on } from '@ngrx/store';
import * as SO2EmissionsActions from './so2-emissions.actions';
import { KTLSGPSO2RecordDTO } from 'src/app/web-api-client';
import { KTLSGPSO2CalculatedReportDTO } from 'src/app/web-api-client';

export interface SO2EmissionsState{
  data: KTLSGPSO2CalculatedReportDTO[] | null ;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: SO2EmissionsState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const SO2EmissionsReducer = createReducer(
  initialState,
  on(SO2EmissionsActions.ListSO2Emissions, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(SO2EmissionsActions.ListSO2EmissionsSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(SO2EmissionsActions.ListSO2EmissionsFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(SO2EmissionsActions.SO2EmissionsEdit, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(SO2EmissionsActions.SO2EmissionsEditSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(SO2EmissionsActions.SO2EmissionsEditFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);