import { Action, createReducer, on } from '@ngrx/store';
import * as DailyReportActions from './daily-report.actions';
import { CalculatedStreamInputRecordDTO, DailyReportDTO } from 'src/app/web-api-client';

export interface DailyReportState{
  data: DailyReportDTO[] | null;
  //data2: CalculatedStreamInputRecordDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: DailyReportState = {
  data: null,
  //data2: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const DailyReportReducer = createReducer(
  initialState,
  on(DailyReportActions.getDailyReport, state => ({
    ...state,
    isLoading: true,
  })),
  on(DailyReportActions.getDailyReportSuccess, (state, result) => ({
    ...state,
     data: result.data,
     //data2: null,
     result,
     isLoading: false,
     isLoadingSuccess: true,
     isLoadingFailure: false,
  })),
  on(DailyReportActions.getDailyReportFailure, (state, result) => ({
    ...state,
    result: result,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  
  on(DailyReportActions.getDailyReportStreamDetails, state => ({
    ...state,
    isLoading: true,
  })),
  on(DailyReportActions.getDailyReportStreamDetailsSuccess, (state, result) => ({
    ...state,
     data: null,
     //data2: result.data,
     result,
     isLoading: false,
     isLoadingSuccess: true,
     isLoadingFailure: false,
  })),
  on(DailyReportActions.getDailyReportStreamDetailsFailure, (state, result) => ({
    ...state,
    result: result,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),

  on(DailyReportActions.AddGenerateReports, (state, payload) => ({
    ...state,
    isLoading: true,
  })),
  on(DailyReportActions.AddGenerateReportsSuccess, (state, payload) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
  })),
  on(DailyReportActions.AddGenerateReportsFailure, (state, payload) => ({
    ...state,
    result: payload.message,
    isLoading: false,
    isLoadingFailure: true,
  })),
);