import { createAction, props } from '@ngrx/store';


export const LPGApproveByChef = createAction(
  '[LPG Approve] Approve By Chef',
  props<{ startTime: Date | undefined }>()
);

export const LPGApproveByChefSuccess = createAction(
  '[LPG Approve] Approve By Chef Success',
  props<any>()
);

export const LPGApproveByChefFailure = createAction(
  '[LPG Approve] Approve By Chef Failure',
  props<{ message: string }>()
);

export const LPGApproveStatus = createAction(
  '[LPG Approve] Approve By Chef Status',
  props<{ startTime: Date | undefined }>()
);

export const LPGApproveStatusSuccess = createAction(
  '[LPG Approve] Approve By Chef Status Success',
  props<{ message: string }>()
);

export const LPGApproveStatusFailure = createAction(
  '[LPG Approve] Approve By Chef Status Failure',
  props<{ message: string }>()
);
