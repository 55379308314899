import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { CCNames, CCType } from 'src/app/core/models/cc.model';
import { CommonService } from 'src/app/core/services/common/common.service';
import { ExportService } from 'src/app/core/services/export/export.service';
import { GetCCData } from 'src/app/state/lpg/cc/cc.actions';
import { CCDataSelector } from 'src/app/state/lpg/cc/cc.selector';
import { ChemicalCompounds } from 'src/app/web-api-client';

@Component({
  selector: 'app-cc',
  templateUrl: './cc.component.html',
  styleUrls: ['./cc.component.scss'],
})
export class CcComponent implements OnInit {
  month: string = '';

  ccEnum: CCType = CCNames;
  ccKeys: string[] = Object.keys(this.ccEnum);
  data?: {
    fuelGasFromSalesGasLine?: ChemicalCompounds;
    fuelGasFromKTL?: {
      'KTL-1'?: ChemicalCompounds;
      'KTL-2'?: ChemicalCompounds;
      'KTL-2.3'?: ChemicalCompounds;
      Average?: ChemicalCompounds;
    };
    dryGasWeightedAverage?: ChemicalCompounds;
    propane?: {
      'E-902'?: ChemicalCompounds;
      'F-3501'?: ChemicalCompounds;
      'F-3502'?: ChemicalCompounds;
      'F-3503'?: ChemicalCompounds;
      'Old park average'?: ChemicalCompounds;
      'New park average'?: ChemicalCompounds;
    };
    propaneWeightedAverage?: ChemicalCompounds;
    butane?: {
      'E-901'?: ChemicalCompounds;
      'F-3511'?: ChemicalCompounds;
      'F-3512'?: ChemicalCompounds;
      'F-3513'?: ChemicalCompounds;
      'Old park average'?: ChemicalCompounds;
      'New park average'?: ChemicalCompounds;
    };
    butaneWeightedAverage?: ChemicalCompounds;
  };

  constructor(
    private store: Store<AppState>,
    private commonService: CommonService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.month = this.commonService.formatDate(new Date(), 'YYYY-MM');
    // this.titleService.setTitle(this.pageTitle);
    this.store.dispatch(GetCCData({ startTime: new Date() }));
    this.store.select(CCDataSelector).subscribe((cc) => {
      if (cc)
        this.data = {
          fuelGasFromSalesGasLine: cc[3].cc,
          fuelGasFromKTL: {
            'KTL-1': cc[0].cc,
            'KTL-2': cc[1].cc,
            'KTL-2.3': cc[2].cc,
            Average: cc[19].cc,
          },
          dryGasWeightedAverage: cc[20].cc,
          propane: {
            'E-902': cc[4].cc,
            'F-3501': cc[5].cc,
            'F-3502': cc[6].cc,
            'F-3503': cc[7].cc,
            'New park average': cc[18].cc,
            'Old park average': cc[15].cc,
          },
          propaneWeightedAverage: cc[13].cc,
          butane: {
            'E-901': cc[8].cc,
            'F-3511': cc[9].cc,
            'F-3512': cc[10].cc,
            'F-3513': cc[11].cc,
            'New park average': cc[17].cc,
            'Old park average': cc[16].cc,
          },
          butaneWeightedAverage: cc[14].cc,
        };
    });
  }

  getObjectName(key: string) {
    return CCNames[key];
  }

  getObjectValue(obj: any, key: string) {
    return obj[key].toFixed(4);
  }

  monthChange() {
    console.log(this.month);
    this.store.dispatch(GetCCData({ startTime: new Date(this.month) }));
  }
  exportToExcel() {
    this.exportService.exportChemicalCompositionReports(new Date(this.month));
  }

  getLoading(): boolean {
    return this.exportService.getIsLoading();
  }
}
