import { Action, createReducer, on } from '@ngrx/store';
import * as SO2EmissionsActions from './so2-emissions-recalculation.actions';
import {
  KTLSGPSO2RecalculationRecordDTO,
  KTLSGPSO2RecordDTO,
  SO2FlowmeterGraphDTO,
} from 'src/app/web-api-client';
import { KTLSGPSO2CalculatedReportDTO } from 'src/app/web-api-client';

export interface SO2EmissionsRecalculationState {
  data: KTLSGPSO2RecalculationRecordDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: SO2EmissionsRecalculationState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const SO2EmissionsRecalculationReducer = createReducer(
  initialState,
  on(SO2EmissionsActions.ListSO2EmissionsRecalculation, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(
    SO2EmissionsActions.ListSO2EmissionsRecalculationSuccess,
    (state, result) => ({
      data: result.data,
      result,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
    })
  ),
  on(
    SO2EmissionsActions.ListSO2EmissionsRecalculationFailure,
    (state, result) => ({
      ...state,
      result,
      isLoading: false,
      isLoadingFailure: true,
    })
  ),
  on(SO2EmissionsActions.AddSO2EmissionsRecalculation, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(
    SO2EmissionsActions.AddSO2EmissionsRecalculationSuccess,
    (state, result) => ({
      data: result.data,
      result,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
    })
  ),
  on(
    SO2EmissionsActions.AddSO2EmissionsRecalculationFailure,
    (state, result) => ({
      ...state,
      result,
      isLoading: false,
      isLoadingFailure: true,
    })
  ),
  on(SO2EmissionsActions.DeleteSO2EmissionsRecalculation, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(
    SO2EmissionsActions.DeleteSO2EmissionsRecalculationSuccess,
    (state, result) => ({
      data: result.data,
      result,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
    })
  ),
  on(
    SO2EmissionsActions.DeleteSO2EmissionsRecalculationFailure,
    (state, result) => ({
      ...state,
      result,
      isLoading: false,
      isLoadingFailure: true,
    })
  )
);
