import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FuelGasFlaringDefaultState,
  fuelGasFlaringDefaultFeatureKey,
} from './fuel-gas-flaring-default.state';

export const fuelGasFlaringDefaultState =
  createFeatureSelector<FuelGasFlaringDefaultState>(
    fuelGasFlaringDefaultFeatureKey
  );

export const fuelGasFlaringDefault = createSelector(
  fuelGasFlaringDefaultState,
  (state) => state.data
);
