import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, mergeMap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as FlowMeterCalculationAction from './flowmeter-calculation.action';
import * as PEAction from './../pe/pe.action';
import * as notificationActions from '../../notification/notification.action';
import { LPGClient, LPGOperatorInputDTO } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class FlowmeterCalculationEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}
  
  getCalculation = createEffect(() =>
  this.actions.pipe(
    ofType(FlowMeterCalculationAction.getCalculation),
    exhaustMap((action) => {
      return this.lpgClient
        .getCalculation(new Date(action.startTime), new Date(action.endTime), action.calculationType, action.avg, action.volume, action.mw)
        .pipe(
          map((res) => FlowMeterCalculationAction.getCalculationSuccess({ result: res, calculationType: action.calculationType })),
          catchError((err) => of(FlowMeterCalculationAction.getCalculationFailure(err)))
        );
    })
  )
);

/*
addFlowmeterCalculationInput = createEffect(() =>
this.actions.pipe(
  ofType(FlowMeterCalculationAction.LPGFlowmeterCalculationInputAdd),
  exhaustMap((action) => {
    return this.lpgClient.addLPGFlowmeterCalculationInput(action).pipe(
      concatMap((res) => [
        FlowMeterCalculationAction.LPGFlowmeterCalculationInputAddSuccess(res),
        FlowMeterCalculationAction.PEList({ startTime: action.startTime }),
        notificationActions.showNotification({ isSuccess: true }),
      ]),
      catchError((err) => [
        of(FlowMeterCalculationAction.LPGFlowmeterCalculationInputAddSuccess(err)),
        notificationActions.showNotification({ isSuccess: false }),
      ])
    );
  })
)
);*/

addFlowmeterCalculationInput = createEffect(() =>
this.actions.pipe(
  ofType(FlowMeterCalculationAction.LPGFlowmeterCalculationInputAdd),
  exhaustMap((action) => {
    return this.lpgClient.addLPGPEInput(action).pipe(
      concatMap((res) => [
        FlowMeterCalculationAction.LPGFlowmeterCalculationInputAddSuccess(res),
        PEAction.PEList({ startTime: action.startTime }),
        notificationActions.showNotification({ isSuccess: true }),
      ]),
      catchError((err) => [
        of(FlowMeterCalculationAction.LPGFlowmeterCalculationInputAddFailure(err)),
        notificationActions.showNotification({ isSuccess: false }),
      ])
    );
  })
), 
);

}
