import { createSelector } from '@ngrx/store';
import { AppState } from '../../../core/models/appState.model';
import { LPGFlaringReportDTO } from 'src/app/web-api-client';

export const FlaringReportSelector = createSelector(
  (state: AppState) => state.flaringReport.data,
  (list: LPGFlaringReportDTO[] | null) => {
    let sorted = list?.map((type) => {
      let newItems = [...type.items];
      newItems.sort((a, b) => a.order - b.order);
      return { ...type, items: newItems };
    });
    return sorted;
  }
);
