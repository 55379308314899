import { createAction, props } from '@ngrx/store';
import {
  BoilerGasUsage,
  BoilerGasUsageInputSavePayload,
  BoilerGasUsageSavePayload,
  GasUsageInputSavePayload,
  HeaterGasUsage,
  HeaterGasUsageInputSavePayload,
  HeaterGasUsageSavePayload,
  IBMOA,
  IBMOASavePayload,
  Meters,
  MetersSavePayload,
  MetersUpdatePayload,
  MonthlyReport,
  MonthlyReportCommentPayload,
  TotalTableRecords,
} from '../models';

// Boilers
export const getBoilerRecords = createAction(
  '[Boiler Gas Usage] Get Boiler Records',
  props<{ date: string }>()
);
export const getBoilerRecordsSuccess = createAction(
  '[Boiler Gas Usage] Get Boiler Records Success',
  props<{ payload: BoilerGasUsage[] }>()
);
export const getBoilerRecordsFailure = createAction(
  '[Boiler Gas Usage] Get Boiler Records Failure',
  props<{ error: string }>()
);

export const saveBoilersRecord = createAction(
  '[Boiler Gas Usage] Save Boilers Record',
  props<{ date: string; payload: BoilerGasUsageInputSavePayload }>()
);
export const saveBoilersRecordSuccess = createAction(
  '[Boiler Gas Usage] Save Boilers Record Success'
);
export const saveBoilersRecordFailure = createAction(
  '[Boiler Gas Usage] Save Boilers Record Failure',
  props<{ error: string }>()
);

export const updateBoilersRecord = createAction(
  '[Boiler Gas Usage] Update Boilers Record',
  props<{ date: string; payload: BoilerGasUsageSavePayload[] }>()
);
export const updateBoilersRecordSuccess = createAction(
  '[Boiler Gas Usage] Update Boilers Record Success'
);
export const updateBoilersRecordFailure = createAction(
  '[Boiler Gas Usage] Update Boilers Record Failure',
  props<{ error: string }>()
);

export const updateBoilerMotohours = createAction(
  '[Boiler Gas Usage] Update Boiler Motohours',
  props<{ date: string; payload: any }>()
);
export const updateBoilerMotohoursSuccess = createAction(
  '[Boiler Gas Usage] Update Boiler Motohours Success'
);
export const updateBoilerMotohoursFailure = createAction(
  '[Boiler Gas Usage] Update Boiler Motohours Failure',
  props<{ error: string }>()
);

export const bulkResetBoilerRecords = createAction(
  '[Boiler Gas Usage] Bulk Reset Boiler Records',
  props<{ date: string; payload: GasUsageInputSavePayload[] }>()
);
export const bulkResetBoilerRecordsSuccess = createAction(
  '[Boiler Gas Usage] Bulk Reset Boiler Records Success'
);
export const bulkResetBoilerRecordsFailure = createAction(
  '[Boiler Gas Usage] Bulk Reset Boiler Records Failure',
  props<{ error: string }>()
);

// Heaters
export const getHeaterRecords = createAction(
  '[Heaters Data] Get Heater Records',
  props<{ date: string }>()
);
export const getHeaterRecordsSuccess = createAction(
  '[Heaters Data] Get Heater Records Success',
  props<{ payload: HeaterGasUsage[] }>()
);
export const getHeaterRecordsFailure = createAction(
  '[Heaters Data] Get Heater Records Failure',
  props<{ error: string }>()
);

export const saveHeatersRecord = createAction(
  '[Heaters Data] Save Heaters Record',
  props<{ date: string; payload: GasUsageInputSavePayload }>()
);
export const saveHeatersRecordSuccess = createAction(
  '[Heaters Data] Save Heaters Record Success'
);
export const saveHeatersRecordFailure = createAction(
  '[Heaters Data] Save Heaters Record Failure',
  props<{ error: string }>()
);

export const updateHeatersRecords = createAction(
  '[Heaters] Update Heaters Record',
  props<{ date: string; payload: HeaterGasUsageSavePayload[] }>()
);
export const updateHeatersRecordsSuccess = createAction(
  '[Heaters] Update Heaters Record Success'
);
export const updateHeatersRecordsFailure = createAction(
  '[Heaters] Update Heaters Record Failure',
  props<{ error: string }>()
);

export const updateHeaterMotohours = createAction(
  '[Heater Gas Usage] Reset Heater Motohours',
  props<{ date: string; payload: HeaterGasUsageInputSavePayload }>()
);
export const updateHeaterMotohoursSuccess = createAction(
  '[Heater Gas Usage] Reset Heater Motohours Success'
);
export const updateHeaterMotohoursFailure = createAction(
  '[Heater Gas Usage] Reset Heater Motohours Failure',
  props<{ error: string }>()
);

export const bulkResetHeaterRecords = createAction(
  '[Boiler Gas Usage] Bulk Reset Heater Records',
  props<{ date: string; payload: GasUsageInputSavePayload[] }>()
);
export const bulkResetHeaterRecordsSuccess = createAction(
  '[Boiler Gas Usage] Bulk Reset Heater Records Success'
);
export const bulkResetHeaterRecordsFailure = createAction(
  '[Boiler Gas Usage] Bulk Reset Heater Records Failure',
  props<{ error: string }>()
);

// Meters
export const getMetersRecords = createAction(
  '[Meters Data] Get Meters Records',
  props<{ date: string }>()
);
export const getMetersRecordsSuccess = createAction(
  '[Meters Data] Get Meters Records Success',
  props<{ payload: Meters[] }>()
);
export const getMetersRecordsFailure = createAction(
  '[Meters Data] Get Meters Records Failure',
  props<{ error: string }>()
);

export const saveMetersRecords = createAction(
  '[Meters Data] Save Meters Records',
  props<{ date: string; payload: MetersSavePayload[] }>()
);
export const saveMetersRecordsSuccess = createAction(
  '[Meters Data] Save Meters Records Success'
);
export const saveMetersRecordsFailure = createAction(
  '[Meters Data] Save Meters Records Failure',
  props<{ error: string }>()
);

export const updateMetersRecords = createAction(
  '[Meters Data] Update Meters Records',
  props<{ date: string; payload: MetersUpdatePayload }>()
);
export const updateMetersRecordsSuccess = createAction(
  '[Meters Data] Update Meters Records Success'
);
export const updateMetersRecordsFailure = createAction(
  '[Meters Data] Update Meters Records Failure',
  props<{ error: string }>()
);

export const resetMetersData = createAction(
  '[Meters Data] Reset Meters Table Data',
  props<{ date: string; payload: MetersUpdatePayload }>()
);
export const resetMetersDataSuccess = createAction(
  '[Meters Data] Reset Meters Table Data Success'
);
export const resetMetersDataFailure = createAction(
  '[Meters Data] Reset Meters Table Data Failure',
  props<{ error: string }>()
);

// Total table records
export const getTotalTableRecords = createAction(
  '[Total Table Records] Get Total Table Records',
  props<{ date: string }>()
);
export const getTotalTableRecordsSuccess = createAction(
  '[Total Table Records] Get Total Table Records Success',
  props<{ payload: TotalTableRecords }>()
);
export const getTotalTableRecordsFailure = createAction(
  '[Total Table Records] Get Total Table Records Failure',
  props<{ error: string }>()
);

// IBMOA
export const getIBMOARecords = createAction(
  '[IBMOA] Get IBMOA Records',
  props<{ date: string }>()
);
export const getIBMOARecordsSuccess = createAction(
  '[IBMOA] Get IBMOA Records Success',
  props<{ payload: IBMOA | null }>()
);
export const getIBMOARecordsFailure = createAction(
  '[IBMOA] Get IBMOA Records Failure',
  props<{ error: string }>()
);

export const saveIBMOARecord = createAction(
  '[IBMOA] Save IBMOA Record',
  props<{ date: string; payload: IBMOASavePayload }>()
);
export const saveIBMOARecordSuccess = createAction(
  '[IBMOA] Save IBMOA Record Success'
);
export const saveIBMOARecordFailure = createAction(
  '[IBMOA] Save IBMOA Record Failure',
  props<{ error: string }>()
);

// Monthly report
export const getMonthlyReports = createAction(
  '[Monthly Report] Get Monthly Reports',
  props<{ date: string }>()
);
export const getMonthlyReportsSuccess = createAction(
  '[Monthly Report] Get Monthly Reports Success',
  props<{ payload: MonthlyReport | null }>()
);
export const getMonthlyReportsFailure = createAction(
  '[Monthly Report] Get Monthly Reports Failure',
  props<{ error: string }>()
);

export const saveMonthlyReportComments = createAction(
  '[Monthly Report] Save Monthly Report Comments',
  props<{ date: string; payload: MonthlyReportCommentPayload }>()
);
export const saveMonthlyReportCommentsSuccess = createAction(
  '[Monthly Report] Save Monthly Report Comments Success'
);
export const saveMonthlyReportCommentsFailure = createAction(
  '[Monthly Report] Save Monthly Report Comments Failure',
  props<{ error: string }>()
);

// Approve
export const getMonthlyApproveTimestamp = createAction(
  '[Monthly Report] Get Approve Monthly Report',
  props<{ date: string }>()
);
export const getMonthlyApproveTimestampSuccess = createAction(
  '[Monthly Report] Get Approve Monthly Report Success',
  props<{ payload: string }>()
);
export const getMonthlyApproveTimestampFailure = createAction(
  '[Monthly Report] Get Approve Monthly Report Failure',
  props<{ error: string }>()
);

export const approveMonthlyReport = createAction(
  '[Monthly Report] Approve Monthly Report',
  props<{ date: string }>()
);
export const approveMonthlyReportSuccess = createAction(
  '[Monthly Report] Approve Monthly Report Success'
);
export const approveMonthlyReportFailure = createAction(
  '[Monthly Report] Approve Monthly Report Failure',
  props<{ error: string }>()
);
