import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { CommonService } from 'src/app/core/services/common/common.service';
import { BreadcrumbService } from 'src/app/core/services/dynamictools/breadcrump.service';
import { TitleService } from 'src/app/core/services/dynamictools/title.service';
import { ExportService } from 'src/app/core/services/export/export.service';
import { FuelGasFlaringService } from 'src/app/core/services/lpg/fuel-gas-flaring.service';
import { FuelGasFlaringData } from 'src/app/state/lpg/fuel-gas-flaring/fuel-gas-flaring.actions';
import { FuelGasFlaringSelector } from 'src/app/state/lpg/fuel-gas-flaring/fuel-gas-flaring.selector';
import { getFuelGasFlaringDefault } from './store/fuel-gas-flaring-default.action';

@Component({
  selector: 'app-fuel-gas-flaring',
  templateUrl: './fuel-gas-flaring.component.html',
  styleUrls: ['./fuel-gas-flaring.component.scss'],
})
export class FuelGasFlaringComponent implements OnInit {
  // pageTitle = 'FUEL GAS FLARING';
  month: string = '';

  displayedColumns: string[] = [
    'elementName',
    'totalVolume',
    'totalVolumekSm3',
    'totalMass',
  ];
  displayedColumns2: string[] = [
    'elementName',
    'totalVolume',
    'totalMass',
    'comment',
    'updatedBy',
    'edit',
  ];

  // finalReportButtons2: { label: string; link: string }[] = [
  //   { label: 'INPUT', link: '/TCO/ProcessEng/FD/LPG/Input/Operator' },
  //   {
  //     label: 'FUEL GAS FLARING',
  //     link: '/TCO/ProcessEng/FD/LPG/FuelGasFlaring',
  //   },
  //   { label: 'CHEMICAL COMPOSITION', link: '/TCO/ProcessEng/FD/LPG/CC' },
  //   {
  //     label: 'FINAL REPORT',
  //     link: '/TCO/ProcessEng/FD/LPG/FinalReport/LPGData',
  //   },
  // ];

  monthlyGasFlarinReport?: any;
  fuelGasFlaring?: any;

  constructor(
    private store: Store<AppState>,
    private breadcrumbService: BreadcrumbService,
    private titleService: TitleService,
    private fuelGasFlaringService: FuelGasFlaringService,
    private commonService: CommonService,
    private exportService: ExportService
  ) {
    // const breadcrumbPath = ['PROCESS ENG', 'FD', 'LPG', 'FUEL GAS FLARING'];
    // this.breadcrumbService.updateBreadcrumb('FuelGasFlaring');
    // this.breadcrumbService.resetBreadcrumbTo(breadcrumbPath);
    // this.titleService.setTitle('FUEL GAS FLARING Page');
  }

  ngOnInit() {
    this.month = this.commonService.formatDate(new Date(), 'YYYY-MM');
    // this.titleService.setTitle(this.pageTitle);
    this.store.dispatch(FuelGasFlaringData({ startTime: new Date() }));
    // this.store.dispatch(getFuelGasFlaringDefault());
    this.store.select(FuelGasFlaringSelector).subscribe((res) => {
      this.monthlyGasFlarinReport = res?.monthlyGasFlaringReport;
      this.fuelGasFlaring = res?.fuelGasFlaring;
    });
  }

  monthChange() {
    this.store.dispatch(
      FuelGasFlaringData({ startTime: new Date(this.month) })
    );
  }

  exportToExcel() {
    this.exportService.exportFuelGasFlaringReports(new Date(this.month));
  }

  getLoading(): boolean {
    return this.exportService.getIsLoading();
  }

  onEditClick(data: any) {
    this.fuelGasFlaringService
      .openEditDialog({ ...data, startTime: this.month })
      .subscribe(() => {
        // Your code here (without using 'result')
      });
  }

  formatNumberCC(value: any): string {
    return this.commonService.formatNumberCC(value);
  }

  formatNumberMV(value: any): string {
    return this.commonService.formatNumberMV(value);
  }
}
