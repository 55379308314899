import { Action, createReducer, on } from '@ngrx/store';
import * as AnalystAction from './analyst.actions';
import {
  LPGAnalystInputDTO,
  LPGDataForAnalystDTO,
} from 'src/app/web-api-client';

export interface AnalystState {
  record: LPGAnalystInputDTO;
  records: LPGAnalystInputDTO[] | null;
  report: LPGDataForAnalystDTO[];
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: AnalystState = {
  record: new LPGAnalystInputDTO(),
  records: new Array<LPGAnalystInputDTO>(),
  report: new Array<LPGDataForAnalystDTO>(),
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const AnalystReducer = createReducer(
  initialState,
  on(AnalystAction.AddAnalyst, (state, payload) => ({
    ...state,
    isLoading: true,
  })),
  on(AnalystAction.AddAnalystSuccess, (state, payload) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
  })),
  on(AnalystAction.AddAnalystFailure, (state, payload) => ({
    ...state,
    result: payload.message,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(AnalystAction.ListAnalyst, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(AnalystAction.ListAnalystSuccess, (state, result) => ({
    ...state,
    records: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(AnalystAction.ListAnalystFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(AnalystAction.ForAnalystData, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(AnalystAction.ForAnalystDataSuccess, (state, result) => ({
    ...state,
    report: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(AnalystAction.ForAnalystDataFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(AnalystAction.EditAnalyst, (state, payload) => ({
    ...state,
    isLoading: true,
  })),
  on(AnalystAction.EditAnalystSuccess, (state, payload) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
  })),
  on(AnalystAction.EditAnalystFailure, (state, payload) => ({
    ...state,
    result: payload.message,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(AnalystAction.DeleteAnalyst, (state, payload) => ({
    ...state,
    isLoading: true,
  })),
  on(AnalystAction.DeleteAnalystSuccess, (state, payload) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
  })),
  on(AnalystAction.DeleteAnalystFailure, (state, payload) => ({
    ...state,
    result: payload.message,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
