<label>{{ label }}</label>

<div class="radio-toggle">
  <div
    *ngFor="let option of options; index as i; first as first; last as last"
    class="radio-toggle__item"
  >
    <input
      type="radio"
      id="radio-{{ i }}"
      name="switch-one"
      [value]="option"
      [formControl]="radioControl"
    />
    <label for="radio-{{ i }}" [class.first]="first" [class.last]="last">
      {{ option }}
    </label>
  </div>
</div>
