import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'FD',
    loadChildren: () => import('./fd/fd.module').then((m) => m.FDModule),
  },
  {
    path: 'FGD',
    loadChildren: () => import('./fgd/fgd.module').then((m) => m.FgdModule),
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProcessEngRoutingModule {}
