<!-- <app-tab [pageTitle]="pageTitle" [buttons]="finalReportButtons" [buttons2]="finalReportButtons2"></app-tab> -->

<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-card-subtitle> </mat-card-subtitle>
        <mat-card-title>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <label>Month</label>
            <div class="input-group mb-2 mr-sm-2">
              <div>
                <input
                type="month"
                id="monthPicker"
                name="logged"
                class="form-control"
                [(ngModel)]="month"
                (ngModelChange)="monthChange()"
              />
              </div>
              <div>
                <div class="d-flex">
                  <button
                    class="ml-4"
                    mat-raised-button
                    style="background-color: #0066b2; color: white"
                    onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
                    onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
                    (click)="exportToExcel()"
                  >
                    Export
                  </button>
                  <div class="ml-4" *ngIf="getLoading()">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row" style="margin-top: 15px;">
  <div class="col">
    <mat-card style="margin-bottom: 25px;">
      <mat-card-header>
        <mat-card-subtitle> </mat-card-subtitle>
        <mat-card-title>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="data">
          <div class="row">
            <h4 class="mb-0">MONTHLY GAS FLARING REPORT</h4>
            <div class="col-md-7">
              <div class="row d-flex">
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th class="col-3"></th>
                      <th class="col-2">Gas Mixture</th>
                      <th class="col-2">Dry Gas</th>
                      <th class="col-2">Propane</th>
                      <th class="col-2">Butane</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="col-3">Volume, Nm3</td>
                      <td class="col-2">{{ formatNumberMV(data![3].items[0].value) }}</td>
                      <td class="col-2">{{ formatNumberMV(data![0].items[0].value) }}</td>
                      <td class="col-2">{{ formatNumberMV(data![2].items[0].value) }}</td>
                      <td class="col-2">{{ formatNumberMV(data![1].items[0].value) }}</td>
                    </tr>
                    <tr>
                      <td class="col-3">Mass, kg</td>
                      <td class="col-2">{{ formatNumberMV(data![3].items[1].value) }}</td>
                      <td class="col-2">{{ formatNumberMV(data![0].items[1].value) }}</td>
                      <td class="col-2">{{ formatNumberMV(data![2].items[1].value) }}</td>
                      <td class="col-2">{{ formatNumberMV(data![1].items[1].value) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 20px;">
            <h4 class="my-0">COMPOSITIONAL FLARED GAS ANALYSIS (WT.%)</h4>
            <div class="col-md-7">
              <div class="row d-flex">
                <table class="mat-elevation-z9">
                  <tbody>
                    <tr *ngFor="let row of count(); let i = index">
                      <td class="col-3">
                        {{ getFlaringReportCCName(data![0].items[i + 2].name) }}
                      </td>
                      <td class="col-2">
                        {{ formatNumberCC(data![3].items[i + 2].value) }}
                      </td>
                      <td class="col-2">
                        {{ formatNumberCC(data![0].items[i + 2].value) }}
                      </td>
                      <td class="col-2">
                        {{ formatNumberCC(data![2].items[i + 2].value) }}
                      </td>
                      <td class="col-2">
                        {{ formatNumberCC(data![1].items[i + 2].value) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
