import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/dynamictools/breadcrump.service';
import { TitleService } from 'src/app/core/services/dynamictools/title.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @Input() breadcrumbs: string[] = [];
  @Input() pageTitle: string = '';
  @Input() buttons: { label: string, link: string }[] = [];
  @Input() buttons2: { label: string, link: string }[] = [];
  @Input() buttons3: { label: string, link: string }[] = [];
    constructor(private breadcrumbService: BreadcrumbService,private titleService: TitleService,private router: Router) {}
  
    ngOnInit() {
      this.breadcrumbService.getBreadcrumbStream().subscribe(breadcrumbs => {
        this.breadcrumbs = breadcrumbs;
      });
      
    }
    getRouteLink(breadcrumb: string): string {
        switch (breadcrumb) {
          case 'TCO':
            return '/TCO';
          case 'PROCESS ENG':
            return '/TCO';
          case 'FD':
            return '/TCO';
          case 'LPG':
            return '/TCO';
          case 'INPUT':
            return '/TCO';
          case 'OPERATOR':
            return 'TCO/ProcessEng/FD/LPG/Input/Operator';
          case 'PE':
            return 'TCO/ProcessEng/FD/LPG/Input/PE/ProcessEngineer';
          case 'FINAL REPORT':
            return '/TCO'
          case 'LPGData':
            return 'TCO/ProcessEng/FD/LPG/FinalReport/LPGData';
          case 'FLARING REPORT':
            return 'TCO/ProcessEng/FD/LPG/FinalReport/FlaringReport';
          case 'KTL-SGP':
            return '/TCO';
          case 'SO2 EMISSIONS':
            return '/TCO/ProcessEng/FD/KTLSGP/So2Emissions';
          case 'FGD':
            return '/TCO';
          case 'IUS':
            return '/TCO';
          case 'MONTHLY REPORT':
            return '/TCO';
          case 'FGU':
            return '/TCO';
          case 'KTL':
            return '/TCO';
          case 'PRODUCTION GAS BALANCE':
            return '/TCO';
          case 'SGP':
              return '/TCO';
          case 'PROCESS ENGINEER':
                return '/TCO';
          default:
            return '';
        }
      }
      isButtonActive(button: { label: string, link: string }): boolean {
        return this.router.isActive(button.link, true);
      }
      isButton2Active(button: { label: string, link: string }): boolean {
        return this.router.isActive(button.link, true);
      }
      isButton3Active(button: { label: string, link: string }): boolean {
        return this.router.isActive(button.link, true);
      }
}