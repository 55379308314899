

export const environment = {
  production: false,
  API_BASE_URL_Append: '',
  envName: 'Development',
  cal: {
    autoSignIn: false,
    popupForLogin: false,
    instance: "https://login.microsoftonline.com/",
    tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
    graphScopes: ["User.Read", "Directory.Read.All"],

    clientId: "5d2ea035-2c03-43da-888b-435414ad2fab",
    oidcScopes: ["https://gasbalance-test.tengizchevroil.com/user_impersonation"],
    redirectUri: "https://gasbalance-dev.tengizchevroil.com/",
    cacheLocation: "localStorage",
  }
};

