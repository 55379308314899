import { Component, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { OperatorService } from 'src/app/core/services/lpg/operator.service';
import { EditOperator } from 'src/app/state/lpg/operator/operator.actions';
import {
  LPGGasType,
  LPGOperatorInputDTO,
  LPGPark,
} from 'src/app/web-api-client';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-operators-edit',
  templateUrl: './operators-edit.component.html',
  styleUrls: ['./operators-edit.component.scss'],
})
export class OperatorsEditComponent {
  operatorEditForm: FormGroup;

  parks: string[] = []; //cascading dropdown
  selectedPark: string = ''; //cascading dropdown
  gases: string[] = []; //cascading dropdown
  selectedGas: string = ''; //cascading dropdown
  options: string[] = []; //cascading dropdown
  selectedOptions: string = ''; //cascading dropdown
  isOtherSelected: boolean = false; //other input
  otherInputValue: string = ''; //other input

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private operatorService: OperatorService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<OperatorsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.operatorEditForm = this.fb.group({
      startTime: [
        this.commonService.convertToDateTimeZ(data.startTime),
        Validators.required,
      ],
      endTime: [
        this.commonService.convertToDateTimeZ(data.endTime),
        Validators.required,
      ],
      reason: [data.reason, Validators.required],
      techComments: data.techComments,
      lpgPark: [
        data.lpgPark === LPGPark.NewParkSGP
          ? 'New Park - SGP'
          : 'Old Park - KTL',
        Validators.required,
      ],
      gasType: [
        data.gasType === LPGGasType.Propane
          ? 'Propane'
          : data.gasType === LPGGasType.Butane
          ? 'Butane'
          : 'Fuel Gas',
        Validators.required,
      ],
      bullet: [data.bullet, Validators.required],
      other: [data.other],
    });
  }
  ngOnInit(): void {
    this.isOtherSelected = this.data.bullet === 'Other';
    this.parks = this.operatorService.getParks();
    this.gases = this.operatorService.getGasesForPark(
      this.operatorEditForm.value.lpgPark
    );
    this.options = this.operatorService.getOptionsForGas(
      this.operatorEditForm.value.lpgPark,
      this.operatorEditForm.value.gasType
    );
  }

  onOptionChange() {
    this.isOtherSelected = this.selectedOptions === 'Other';
    if (!this.isOtherSelected) {
      this.otherInputValue = '';
    }
  }

  onParkChange() {
    this.gases = this.operatorService.getGasesForPark(
      this.operatorEditForm.value.lpgPark
    );
    this.options = [];
  }

  onGasChange() {
    this.options = this.operatorService.getOptionsForGas(
      this.operatorEditForm.value.lpgPark,
      this.operatorEditForm.value.gasType
    );
    this.operatorEditForm.patchValue({
      bullet: '',
    });
  }

  handleSave() {
    const body: LPGOperatorInputDTO = new LPGOperatorInputDTO();
    const vals = this.operatorEditForm.value;
    if (this.operatorEditForm.valid) {
      (body.id = this.data.id),
        (body.bullet = vals.bullet),
        (body.endTime = vals.endTime),
        (body.gasType =
          vals.gasType === 'Propane' ? LPGGasType.Propane : LPGGasType.Butane),
        (body.isOperator = true),
        (body.lpgPark =
          vals.lpgPark === 'New Park - SGP'
            ? LPGPark.NewParkSGP
            : LPGPark.OldParkKTL),
        (body.reason = vals.reason),
        (body.startTime = vals.startTime),
        (body.other = vals.other),
        (body.techComments = vals.techComments);

      if (this.commonService.isOperatorLocked(body.startTime))
        this.store.dispatch(EditOperator(body));
      else alert('You cannot create/update/delete record.');
    }
    this.dialogRef.close('Saved');
  }
}
