<div class="row main">
  <div class="col">
      <mat-card>
          <mat-card-header>
            <mat-card-subtitle>
              <div class="breadcrumb">
                  <div class="row">
                      <nav aria-label="breadcrumb">
                          <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
                            <ng-container *ngIf="!last; else lastItem">
                              <a [routerLink]="getRouteLink(breadcrumb)"><b>{{ breadcrumb }}</b></a> /
                            </ng-container>
                            <ng-template #lastItem><b>{{ breadcrumb }}</b></ng-template>
                          </ng-container>
                        </nav>    
                  </div>
              </div>
          </mat-card-subtitle>

              <mat-card-title>
                <div class="row">
                  <h1 class="title">{{pageTitle }}</h1>
                  
                </div>
              </mat-card-title> 
            <!--
              <a mat-button class="offset-md-3 custom-tab-button" [routerLink]="['/TCO/ProcessEng/FD/LPG/Input/']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">TARGET/PERMIT</a>
              <a mat-button class="custom-tab-button" [routerLink]="['/TCO/ProcessEng/FD/LPG/Input/PE']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">PROCESS ENGINEER</a>
              <a mat-button class="custom-tab-button" [routerLink]="['/TCO/ProcessEng/FD/LPG/Input/Analyst']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">ANALYST</a>
              <a mat-button class="custom-tab-button" [routerLink]="['/TCO/ProcessEng/FD/LPG/Input/Operator']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">OPERATOR</a>
              
            -->
            <div class="row">
              <div class="row">
                <div class="custom-tab-buttons offset-md-3" >
                  <!--
<a mat-button class="custom-tab-button2" [routerLink]="['/TCO/ProcessEng/FD/LPG/Input/Operator']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{ 'active-button': isButtonActive(button) }">INPUT</a>
                  <a mat-button class="custom-tab-button2" [routerLink]="['/TCO/ProcessEng/FD/LPG/FuelGasFlaring']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">FUEL GAS FLARING</a>
                  <a mat-button class="custom-tab-button2" [routerLink]="['/TCO/ProcessEng/FD/LPG/CC']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">CHEMICAL COMPOSITIONS</a>
                  <a mat-button class="custom-tab-button2" [routerLink]="['/TCO/ProcessEng/FD/LPG/FinalReport/LPGData']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">FINAL REPORT</a>
               
                  -->
                  <a mat-button *ngFor="let button of buttons2"
                     [routerLink]="[button.link]"
                     routerLinkActive="active-link"
                     [routerLinkActiveOptions]="{ exact: true }"
                     [ngClass]="{ 'active-button': isButtonActive(button) }">{{ button.label }}</a>
                   </div>
                </div>
                
              <div class="row">
                <div class="custom-tab-buttons2 offset-md-3" >
                  <a mat-button *ngFor="let button of buttons"
                     [routerLink]="[button.link]"
                     routerLinkActive="active-link"
                     [routerLinkActiveOptions]="{ exact: true }"
                     [ngClass]="{ 'active-button': isButtonActive(button) }">{{ button.label }}</a>
                </div>
              </div>

              <div class="row">
                <div class="custom-tab-buttons3 offset-md-3" >
                  <a mat-button *ngFor="let button of buttons3"
                     [routerLink]="[button.link]"
                     routerLinkActive="active-link"
                     [routerLinkActiveOptions]="{ exact: true }"
                     [ngClass]="{ 'active-button': isButtonActive(button) }">{{ button.label }}</a>
                </div>
              </div>
             
            </div>
              
            </mat-card-header>
      </mat-card>
  </div>
</div>