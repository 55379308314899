<div class="select" [class.horizontal]="direction === 'horizontal'">
  <label
    *ngIf="label"
    class="label"
    [style.margin]="direction === 'horizontal' ? '0 24px 0 0' : '0 0 8px'"
    >{{ label }}</label
  >

  <select
    class="form-select"
    [style.width]="selectWidth"
    [formControl]="selectControl"
  >
    <option
      *ngFor="let option of options"
      [ngValue]="option.value"
      [disabled]="option.disabled ?? false"
    >
      {{ option.translate ? (option.title | translate) : option.title }}
    </option>
  </select>
</div>
