import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum SaveDialogAction {
  Save
}

@Component({
  selector: 'app-shared-save-confirmation',
  templateUrl: './shared-save-confirmation.component.html',
  styleUrls: ['./shared-save-confirmation.component.scss'],
})
export class SharedSaveConfirmationComponent {
  public SaveDialogAction = SaveDialogAction;

  constructor(
    public dialogRef: MatDialogRef<SharedSaveConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public action: any //SaveDialogAction = SaveDialogAction.Save
  ) {
    console.log('action', action, typeof action, action === SaveDialogAction.Save);
  }

  onCancelClick(): void {
    this.dialogRef.close(false); // Close the dialog with a 'false' result (cancelled).
  }

  onSaveClick(): void {
    this.dialogRef.close(true); // Close the dialog with a 'true' result (confirmed).
  }
}
