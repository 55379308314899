import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as PEAction from './pe.action';
import * as notificationActions from '../../notification/notification.action';
import { LPGClient } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class PEEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}

  listPE = createEffect(() =>
    this.actions.pipe(
      ofType(PEAction.PEList),
      exhaustMap((action) => {
        return this.lpgClient.monthlyPERecords(new Date(action.startTime)).pipe(
          map((res) => PEAction.PEListSuccess({ data: res })),
          catchError((err) => of(PEAction.PEListFailure(err)))
        );
      })
    )
  );

  addPE = createEffect(() =>
    this.actions.pipe(
      ofType(PEAction.PEAdd),
      exhaustMap((action) => {
        return this.lpgClient.addLPGPEInput(action).pipe(
          concatMap((res) => [
            PEAction.PEAddSuccess(res),
            PEAction.PEList({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(PEAction.PEAddFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  editPE = createEffect(() =>
    this.actions.pipe(
      ofType(PEAction.PEEdit),
      exhaustMap((action) => {
        return this.lpgClient.editLPGPEInput(action).pipe(
          concatMap((res) => [
            PEAction.PEEditSuccess(res),
            PEAction.PEList({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(PEAction.PEEditFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  deletePE = createEffect(() =>
    this.actions.pipe(
      ofType(PEAction.PEDelete),
      exhaustMap((action) => {
        return this.lpgClient.deleteLPGPEInput(action.id).pipe(
          concatMap((res) => [
            PEAction.PEDeleteSuccess(res),
            PEAction.PEList({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(PEAction.PEDeleteFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}
