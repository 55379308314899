import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as analystActions from './analyst.actions';
import * as notificationActions from '../../notification/notification.action';
import { LPGClient } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class AnalystEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}

  addAnalyst$ = createEffect(() =>
    this.actions.pipe(
      ofType(analystActions.AddAnalyst),
      exhaustMap((action) => {
        return this.lpgClient.addLPGAnalystInput(action).pipe(
          concatMap((res) => [
            analystActions.AddAnalystSuccess(res),
            analystActions.ListAnalyst({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(analystActions.AddAnalystFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  listAnalyst = createEffect(() =>
    this.actions.pipe(
      ofType(analystActions.ListAnalyst),
      exhaustMap((action) => {
        return this.lpgClient
          .monthlyAnalystRecords(new Date(action.startTime))
          .pipe(
            map((res) => analystActions.ListAnalystSuccess({ data: res })),
            catchError((err) => of(analystActions.ListAnalystFailure(err)))
          );
      })
    )
  );

  forAnalyst = createEffect(() =>
    this.actions.pipe(
      ofType(analystActions.ForAnalystData),
      exhaustMap((action) => {
        return this.lpgClient.dataForAnalyst(new Date(action.startTime)).pipe(
          map((res) => analystActions.ForAnalystDataSuccess({ data: res })),
          catchError((err) => of(analystActions.ForAnalystDataFailure(err)))
        );
      })
    )
  );

  editAnalyst$ = createEffect(() =>
    this.actions.pipe(
      ofType(analystActions.EditAnalyst),
      exhaustMap((action) => {
        return this.lpgClient.editLPGAnalystInput(action).pipe(
          concatMap((res) => [
            analystActions.EditAnalystSuccess(res),
            analystActions.ListAnalyst({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(analystActions.EditAnalystFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  deleteAnalyst$ = createEffect(() =>
    this.actions.pipe(
      ofType(analystActions.DeleteAnalyst),
      exhaustMap((action) => {
        return this.lpgClient.deleteLPGAnalystInput(action.id).pipe(
          concatMap((res) => [
            analystActions.DeleteAnalystSuccess(res),
            analystActions.ListAnalyst({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(analystActions.DeleteAnalystFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}
