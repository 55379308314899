<div class="icon-wrapper">
  <mat-icon class="icon">error</mat-icon>
</div>

<h1
  mat-dialog-title
  style="text-align: center; color: #0066b2; text-transform: uppercase"
>
  ARE YOU SURE?
</h1>

<div mat-dialog-content class="content" *ngIf="action.action === 'Save'">
  <p> You`re saving new values for streams if you want to save changes, please click <b>Save</b>. 
    <br />
    Click <b> Cancel </b> to refuse all changes.</p>
</div>

<div mat-dialog-content class="content" *ngIf="action.action === 'Refresh'">
  <p> You`re deleting entered value and returning automatically calculated value. If you want to save changes please click <b>Save</b>. 
    <br />
    Click <b> Cancel </b> to discard the change.</p>
</div>

<div mat-dialog-actions class="button-container">
  <button
    mat-button
    style="background-color: #0066b2; color: white"
    (click)="onSaveClick()"
    onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
    onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
  >
    SAVE
  </button>
  <button mat-button (click)="onCancelClick()">CANCEL</button>
</div>
