<div class="custom-dialog-container">
  <div class="row">
    <div class="col-md-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h1 class="title">FLOWMETER CALCULATION</h1>
          </mat-card-title>
          <mat-card-subtitle> </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form class="mb-4" [formGroup]="FlowmeterCalculationForm">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Start Time</label>
                  <div class="input-group mb-2 mr-sm-2">
                    <input
                      type="datetime-local"
                      id="starttimemonthPicker"
                      name="starttime"
                      class="form-control"
                      formControlName="startTime"
                      (change)="onGraphChange()"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>End Time</label>
                  <div class="input-group mb-2 mr-sm-2">
                    <input
                      type="datetime-local"
                      id="endtimemonthPicker"
                      name="endtime"
                      class="form-control"
                      formControlName="endTime"
                      (change)="onGraphChange()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                class="col-md-8"
              >
                <canvas id="MyChart">{{ chart }}</canvas>
              </div>
              <div class="col-md-2"></div>
            </div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Flaring Average Range From</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          (change)="onAverageChange()"
                          type="datetime-local"
                          id="FlaringAverageFrommonthPicker"
                          name="FlaringAverageFrom"
                          class="form-control"
                          formControlName="FlaringAverageFrom"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Flaring Average Range To</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          (change)="onAverageChange()"
                          type="datetime-local"
                          id="FlaringAverageTomonthPicker"
                          name="FlaringAverageTo"
                          class="form-control"
                          formControlName="FlaringAverageTo"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Average Before Flaring, Nm3/h</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          id="AverageBeforeFlaring"
                          name="AverageBeforeFlaring"
                          class="form-control"
                          formControlName="AverageBeforeFlaring"
                          type="number"
                          [disabled]="isDisabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Flaring Start Time</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          (change)="onFlaredVolumeChange()"
                          type="datetime-local"
                          id="FlaringStartTimemonthPicker"
                          name="FlaringStartTimeFrom"
                          class="form-control"
                          formControlName="FlaringStartTime"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Flaring End Time</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          (change)="onFlaredVolumeChange()"
                          type="datetime-local"
                          id="FlaringEndTimemonthPicker"
                          name="FlaringEndTimeFrom"
                          class="form-control"
                          formControlName="FlaringEndTime"
                        />
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Flared Volume, Nm3</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          id="FlaredVolume"
                          name="FlaredVolume"
                          class="form-control"
                          formControlName="FlaredVolume"
                          type="number"
                          (change)="onFlaredVolumeChange()"
                        />
                      </div>
                      <div
                        *ngIf="
                          FlowmeterCalculationForm.get('FlaredVolume')?.value <
                          0
                        "
                        class="text-danger"
                      >
                        Volume must be positive.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Flared Mass, Tons</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <input
                          id="FlaredMass"
                          name="FlaredMass"
                          class="form-control"
                          formControlName="FlaredMass"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="form-group">
                    <label>Enter link to SEEQ</label>
                    <textarea
                      class="form-control"
                      rows="1"
                      formControlName="linkToSEEQ"
                      name="linkToSEEQ"
                      (blur)="updateLink()"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <label>Link to SEEQ</label>
                  <a
                    [href]="
                      FlowmeterCalculationForm.get('linkToSEEQ')?.value || '#'
                    "
                    target="_blank"
                    style="text-decoration: none"
                  >
                    {{ FlowmeterCalculationForm.get("linkToSEEQ")?.value }}
                  </a>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="lpgPark">LPG Park</label>
                  <select
                    id="lpgPark"
                    class="form-select"
                    formControlName="lpgPark"
                    (change)="onParkChange()"
                  >
                    <option value="null" disabled>LPG Park</option>
                    <option *ngFor="let park of parks" [value]="park">
                      {{ park }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('lpgPark')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('lpgPark')?.touched
                  "
                  class="text-danger"
                >
                  LPG Park is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="gasType">Gas Type</label>
                  <select
                    id="gasType"
                    (change)="onGasChange()"
                    class="form-select"
                    formControlName="gasType"
                  >
                    <option value="null" disabled>Gas Type</option>
                    <option *ngFor="let gas of gases" [value]="gas">
                      {{ gas }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('gasType')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('gasType')?.touched
                  "
                  class="text-danger"
                >
                  Gas Type is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="purpose">Purpose</label>
                  <select
                    id="purpose"
                    class="form-select"
                    formControlName="purpose"
                  >
                    <option value="null" disabled>Purpose</option>
                    <option *ngFor="let purpose of purposes" [value]="purpose">
                      {{ purpose }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('purpose')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('purpose')?.touched
                  "
                  class="text-danger"
                >
                  Purpose is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="entryType">Entry Type</label>
                  <select
                    id="entryType"
                    class="form-select"
                    formControlName="entryType"
                  >
                    <option value="null" disabled>Entry Type</option>
                    <option
                      *ngFor="let entrytype of entrytypes"
                      [value]="entrytype"
                    >
                      {{ entrytype }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('entryType')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('entryType')?.touched
                  "
                  class="text-danger"
                >
                  Entry Type is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="bullet">Bullet</label>
                  <select
                    id="bullet"
                    class="form-select"
                    (change)="onOptionChange()"
                    formControlName="bullet"
                  >
                    <option value="null" disabled>Bullet</option>
                    <option *ngFor="let bullet of bullets" [value]="bullet">
                      {{ bullet }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('bullet')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('bullet')?.touched
                  "
                  class="text-danger"
                >
                  Bullet is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="mwSelectionPoint">MW Selection Point</label>
                  <select
                    id="mwSelectionPoint"
                    class="form-select"
                    formControlName="mwSelectionPoint"
                    (change)="onMassChange()"
                  >
                    <option value="null" disabled>MW Selection Point</option>
                    <option
                      *ngFor="let point of MWSelectionPoints"
                      [value]="point"
                    >
                      {{ point }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('mwSelectionPoint')?.hasError(
                      'required'
                    ) &&
                    FlowmeterCalculationForm.get('mwSelectionPoint')?.touched
                  "
                  class="text-danger"
                >
                  MW Selection Point is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="category">Category</label>
                  <select
                    id="category"
                    class="form-select"
                    formControlName="category"
                  >
                    <option value="null" disabled>Category</option>
                    <option
                      *ngFor="let category of categories"
                      [value]="category"
                    >
                      {{ category }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('category')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('category')?.touched
                  "
                  class="text-danger"
                >
                  Category is required.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Reason</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    formControlName="reason"
                    name="comment"
                  >
                  </textarea>
                </div>
                <div
                  *ngIf="
                    FlowmeterCalculationForm.get('reason')?.hasError(
                      'required'
                    ) && FlowmeterCalculationForm.get('reason')?.touched
                  "
                  class="text-danger"
                >
                  Reason is required.
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" *ngIf="isOtherSelected">
                  <label for="otherInput">Other</label>
                  <input
                    type="text"
                    id="otherInput"
                    formControlName="other"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </form>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions class="align-self-center actions">
          <div align="center">
            <button
              mat-raised-button
              mat-dialog-save
              [ngStyle]="{
                'background-color': (FlowmeterCalculationForm.invalid || FlowmeterCalculationForm.get('FlaredVolume')?.value < 0)
                  ? 'gray'
                  : '#0066b2',
                color: 'white'
              }"
              [disabled]="FlowmeterCalculationForm.invalid || FlowmeterCalculationForm.get('FlaredVolume')?.value < 0"
              (click)="handleSave()"
              onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
              onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
            >
              SAVE
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
