// approve.reducer.ts

import { createReducer, on } from '@ngrx/store';
import * as ApproveActions from './approve.actions';

export interface ApproveState {
  // for error codes
  result: any;
  // for message status
  status: string;
}

export const initialState: ApproveState = {
  result: null,
  status: ''
};

export const ApproveReducer = createReducer(

  initialState,
  on(ApproveActions.LPGApproveByChef, (state, result) => ({
    ...state,
  })),
  on(ApproveActions.LPGApproveByChefSuccess, (state, result) => ({
    ...state,
    result: result
  })),
  on(ApproveActions.LPGApproveByChefFailure, (state, result) => ({
    ...state,
    result: result
  })),
  on(ApproveActions.LPGApproveStatus, (state, result) => ({
    ...state,
  })),
  on(ApproveActions.LPGApproveStatusSuccess, (state, result) => ({
    ...state,
    status: result.message
  })),
  on(ApproveActions.LPGApproveStatusFailure, (state, result) => ({
    ...state,
    result: result
  }))
);
