<nav class="navbar navbar-expand-lg navbar-dark mb-4" style="z-index: 1100;">
  <img class="Logo" width="40" alt="TCO Logo" src=".\assets\TCO.png"/>
  <a class="navbar-brand" [routerLink]="['/TCO']">
    <b style="font-size: 1em;">TCO</b>
  </a>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active" style="margin-left: 30px;" > 
        <a class="nav-link" mat-button [routerLink]="['/TCO']" [ngClass]="{ 'active-button': isMapActive() }">
          <b style="font-size: 1em;">MAP</b>
        </a>
      </li>

      <li class="nav-item dropdown" style="margin-left: 10px;" *ngFor="let group of arr" >

        <a mat-button [ngClass]="{ 'active-button': isActive(group.name) }" *ngIf="group.name === 'FD'" class="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
          <b style="font-size: 1em;">{{ group.name }}</b>
        </a>
        
        <a mat-button [ngClass]="{ 'active-button': isActive(group.name) }" *ngIf="group.name === 'FGD'" class="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
          <b style="font-size: 1em;">{{ group.name }}</b>
        </a>

        <div *ngIf="group.name === 'FD'" class="dropdown-menu" aria-labelledby="navbarDropdown">
          <div class="dropdown-row">
            <ul>
              <li>            
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[0]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'KTL'">
                      <li *ngFor="let child of item.childs; index as k">
                        <ng-container *ngIf="child.name === 'Schema'; else normalLink">
                          <a class="dropdown-item" href="http://indxd.tengizchevroil.com/indx/sv/#::TCO/ProcessEng/FD/KTL_SGP/~Schema" target="_blank">{{ child.name }}</a>
                        </ng-container>
                        <ng-template #normalLink>
                          <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name }}</a>
                        </ng-template>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <br>
            <ul>
              <li>   
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[3]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'FIELD'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
          </div>
          <div class="dropdown-row">
            <ul>
              <li>              
                  <a class="dropdown-item" style="margin-left: 0;"> <b>{{ group.items[1]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'SGP'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
            <ul>
              <li>          
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[4]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'LPG'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
          </div>
          <div class="dropdown-row">
            <ul style="margin-bottom: 90px;">
              <li>              
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[2]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'SGI'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
            <ul>
              <li>
                  <a [routerLink]="['/TCO/ProcessEng/' + group.items[5]?.route]" class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[5]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'SO2 EMISSIONS'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name }}</a> <!--(click)="setActiveSubItem(item, child)"-->
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
          </div>
        </div>

        <div *ngIf="group.name === 'FGD'" class="dropdown-menu" aria-labelledby="navbarDropdown">
          <div  class="dropdown-row">
            <ul style="margin-bottom: 44px;">
              <li>
                  <a class="dropdown-item" style="margin-left: 0;"> <b>{{ group.items[0]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'KTL'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
            <ul>
              <li>
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[2]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'FIELD'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
          </div>
          <div  class="dropdown-row">
            <ul style="margin-bottom: 27px;">
              <li>              
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[1]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'SGP'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[3]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'LPG'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
          </div>
          <div class="dropdown-row">
            <ul>
              <li>
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[4]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'SGI'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
            <ul>
              <li>
                  <a class="dropdown-item" style="margin-left: 0;"><b>{{ group.items[5]?.name }}</b></a>
                <ul>
                  <li *ngFor="let item of group.items">
                    <ul *ngIf="item.name === 'INTERNAL USAGE'">
                      <li *ngFor="let child of item.childs; index as k">
                        <a class="dropdown-item" [ngClass]="{ 'active-button2': isActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">{{ child.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br>
          </div>
        </div>
      </li>
    </ul>

    <div class="languages">
      <div 
        *ngFor="let lang of languages" 
        class="languages__item" 
        [class.active]="translate.currentLang === lang"
        (click)="selectLang(lang)"
      >
        {{ lang }}
      </div>
    </div>
  </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-dark mb-4" *ngIf="!isMainPage && !isNotAuthorize" >

  <div class="collapse navbar-collapse" id="secondNavbarSupportedContent">

    <ul class="navbar-nav mr-auto" style="justify-content: center; display: flex; ">

      <div style="margin-left: 10px; " *ngFor="let group of arr" > <!--[ngClass]="{ 'activeSub-button': isSubActive(group.name) }"-->

        <div *ngFor="let item of group.items">

          <div *ngIf="!isSubFGDActive">

            <!-- <div *ngIf="!isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && group.name === 'FD' && item.name === 'KTL'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->

              <!-- <b  style=" text-transform: uppercase; color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b> -->
  
              <!-- <div *ngFor="let child of item.childs"  style="display: flex; list-style-type: none; padding: 0;"> -->
                
                <!-- <div> -->
  
                  <!-- <li class="nav-item active"  style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > [ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }" -->
    
                    <!-- <a class="nav-link" style="text-transform: uppercase; font-size: 1.1em;"  mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" href="{{ child.name === 'Schema' ? 'http://indxd.tengizchevroil.com/indx/sv/#::TCO/ProcessEng/FD/KTL_SGP/~Schema' : undefined }}" [routerLink]="child.name !== 'Schema' ? '/TCO/ProcessEng/' + child.route : undefined" target="{{ child.name === 'Schema' ? '_blank' : '_self' }}" > [routerLink]="child.name === 'Schema' ? 'http://indxd.tengizchevroil.com/indx/sv/#::TCO/ProcessEng/FD/KTL_SGP/~Schema' : '/TCO/ProcessEng/' + child.route" target="{{ child.name === 'Schema' ? '_blank' : '_self' }}"   [routerLink]="['/TCO/ProcessEng/' + child.route]" *ngIf="child.name === 'Schema'" -->
    
                      <!-- <b>{{ child.name }}</b> -->
    
                    <!-- </a> -->
    
                  <!-- </li> -->
  
                <!-- </div> -->
  
              <!-- </div> -->
  
            <!-- </div> --> 

            <div *ngIf="!isSubFDSGPActive && !isSubFDSGIActive && !isSubFDFIELDActive && !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && group.name === 'FD' && item.name === 'KTL'" style="display: flex; align-items: center;">
              <b style="text-transform: uppercase; color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
              
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
                <div>
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;">
                    <ng-container *ngIf="child.name === 'Schema'; else normalLink">
                      <a class="nav-link" style="text-transform: uppercase; font-size: 1.1em;" mat-button href="http://indxd.tengizchevroil.com/indx/sv/#::TCO/ProcessEng/FD/KTL_SGP/~Schema" target="_blank">
                        <b style="font-size: 1em;">{{ child.name }}</b>
                      </a>
                    </ng-container>
                    <ng-template #normalLink>
                      <a class="nav-link" style="text-transform: uppercase; font-size: 1.1em;" mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]">
                        <b style="font-size: 1em;">{{ child.name }}</b>
                      </a>
                    </ng-template>
                  </li>
                </div>
              </div>
            </div>
  
            <div *ngIf="!isSubFDKTLActive && !isSubFDSGIActive && group.name === 'FD' && !isSubFDFIELDActive &&  !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && item.name === 'SGP'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="  text-transform: uppercase; color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active"  style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFDKTLActive && !isSubFDSGPActive && group.name === 'FD' && !isSubFDFIELDActive &&  !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && item.name === 'SGI'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style=" text-transform: uppercase;color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active"  style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFDKTLActive && !isSubFDSGPActive && !isSubFDSGIActive && group.name === 'FD' &&  !isSubFDLPGActive && !isSubFDSO2EMISSIONSActive && item.name === 'FIELD'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFDKTLActive && !isSubFDSGPActive && !isSubFDSGIActive && group.name === 'FD' &&  !isSubFDFIELDActive && !isSubFDSO2EMISSIONSActive && item.name === 'LPG'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFDKTLActive && !isSubFDSGPActive && !isSubFDSGIActive && group.name === 'FD' && !isSubFDFIELDActive &&  !isSubFDLPGActive && item.name === 'SO2 EMISSIONS'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div *ngIf="child.name === 'Input   '">
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>

                <div *ngIf="child.name === 'Monthly Report  '">
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>

          </div>

          <div *ngIf="!isSubFDActive">

            <div *ngIf="!isSubFGDSGPActive && !isSubFGDSGIActive && !isSubFGDFIELDActive && !isSubFGDLPGActive && !isSubFGDInternalUsageActive && group.name === 'FGD' && item.name === 'KTL'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->

              <b style=" text-transform: uppercase; color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs"  style="display: flex; list-style-type: none; padding: 0;">
                
                <div>
  
                  <li class="nav-item active"  style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
    
                    <a class="nav-link" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFGDKTLActive && !isSubFGDSGIActive && group.name === 'FGD' && !isSubFGDFIELDActive &&  !isSubFGDLPGActive && !isSubFGDInternalUsageActive && item.name === 'SGP'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active"  style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFGDKTLActive && !isSubFGDSGPActive && group.name === 'FGD' && !isSubFGDFIELDActive &&  !isSubFGDLPGActive && !isSubFGDInternalUsageActive && item.name === 'SGI'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active"  style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFGDKTLActive && !isSubFGDSGPActive && !isSubFGDSGIActive && group.name === 'FGD' &&  !isSubFGDLPGActive && !isSubFGDInternalUsageActive && item.name === 'FIELD'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" mat-button style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFGDKTLActive && !isSubFGDSGPActive && !isSubFGDSGIActive && group.name === 'FGD' &&  !isSubFGDFIELDActive && !isSubFGDInternalUsageActive && item.name === 'LPG'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
  
            <div *ngIf="!isSubFGDKTLActive && !isSubFGDSGPActive && !isSubFGDSGIActive && group.name === 'FGD' && !isSubFGDFIELDActive &&  !isSubFGDLPGActive && item.name === 'INTERNAL USAGE'" style="display: flex; align-items: center; " > <!--!isSubFGDKTLActive && -->
  
              <b style="text-transform: uppercase; color: white; margin-right: 10px; margin-left: 20px; font-size: 1.2em;">{{ item.name }} :</b>
  
              <div *ngFor="let child of item.childs" style="display: flex; list-style-type: none; padding: 0;">
  
                <div>
  
                  <li class="nav-item active" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" > <!--[ngClass]="{ 'activeSubChild-button': isSubChildActive(child.name) }"-->
  
                    <a class="nav-link" style="margin-left: 10px; text-transform: uppercase; font-size: 1.1em;" mat-button [ngClass]="{ 'active-button': isSubChildActive(child.name) }" [routerLink]="['/TCO/ProcessEng/' + child.route]"> <!--*ngIf="child.name === 'Schema'" -->
    
                      <b style="font-size: 1em;">{{ child.name }}</b>
    
                    </a>
    
                  </li>
  
                </div>
  
              </div>
  
            </div>
            
          </div>

        </div>

      </div>
      
    </ul>

  </div>

</nav>