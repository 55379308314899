import { createAction, props } from '@ngrx/store';
import {
  LPGOperatorInputDTO,
  ILPGOperatorInputDTO,
} from 'src/app/web-api-client';

export const ListOperator = createAction(
  '[Operator] List Operator',
  props<{ startTime: Date }>()
);
export const ListOperatorSuccess = createAction(
  '[Operator] List Operator Success',
  props<{ data: LPGOperatorInputDTO[] }>()
);
export const ListOperatorFailure = createAction(
  '[Operator] List Operator Failure',
  props<{ message: string }>()
);

export const AddOperator = createAction(
  '[Operator] Add Operator',
  props<LPGOperatorInputDTO>()
);
export const AddOperatorSuccess = createAction(
  '[Operator] Add Operator Success',
  props<any>()
);
export const AddOperatorFailure = createAction(
  '[Operator] Add Operator Failure',
  props<{ message: string }>()
);

export const EditOperator = createAction(
  '[Operator] Edit Operator',
  props<LPGOperatorInputDTO>()
);
export const EditOperatorSuccess = createAction(
  '[Operator] Edit Operator Success',
  props<any>()
);
export const EditOperatorFailure = createAction(
  '[Operator] Edit Operator Failure',
  props<{ message: string }>()
);

export const DeleteOperator = createAction(
  '[Operator] Delete Operator',
  props<{ id: number; startTime: Date }>()
);
export const DeleteOperatorSuccess = createAction(
  '[Operator] Delete Operator Success',
  props<any>()
);
export const DeleteOperatorFailure = createAction(
  '[Operator] Delete Operator Failure',
  props<{ message: string }>()
);
