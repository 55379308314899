import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as notificationActions from './notification.action';

export interface NotificationState {
  message: string;
  isSuccess: boolean;
  isFailure: boolean;
  showNotification: boolean;
  isLoading: boolean;
}

const initialState: NotificationState = {
  message: '',
  isSuccess: false,
  isFailure: false,
  showNotification: false,
  isLoading: false,
};

export const NotificationReducer = createReducer(
  initialState,
  on(notificationActions.showNotificationSuccess, (state, result) => ({
    ...state,
    message: result.message,
    showNotification: true,
    isSuccess: true,
    isFailure: false,
    isLoading: false,
  })),
  on(notificationActions.showNotificationFailure, (state, result) => ({
    ...state,
    message: result.message,
    showNotification: true,
    isSuccess: false,
    isFailure: true,
    isLoading: false,
  })),
  on(notificationActions.clearNotification, (state) => ({
    ...state,
    message: '',
    showNotification: false,
    isSuccess: false,
    isFailure: false,
    isLoading: false,
  })),
  on(notificationActions.startLoading, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(notificationActions.stopLoading, (state) => ({
    ...state,
    isLoading: false,
  }))
);
