import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SO2EmissionsRecalculationActions from './so2-emissions-recalculation.actions';
import * as SO2EmissionsActions from '../so2-emissions/so2-emissions.actions';
import * as SO2ChartsActions from '../so2-charts/so2-charts.action';
import * as notificationActions from '../../notification/notification.action';
import { SO2Client } from '../../../web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SO2EmissionsRecalculationEffects {
  constructor(private actions: Actions, private so2Client: SO2Client) {}

  listRecalculationSO2Emissions = createEffect(() =>
    this.actions.pipe(
      ofType(SO2EmissionsRecalculationActions.ListSO2EmissionsRecalculation),
      exhaustMap((action) => {
        return this.so2Client
          .getSO2RecalculationData(new Date(action.startTime), action.train)
          .pipe(
            map((res) =>
              SO2EmissionsRecalculationActions.ListSO2EmissionsRecalculationSuccess(
                { data: res }
              )
            ),
            catchError((err) =>
              of(
                SO2EmissionsRecalculationActions.ListSO2EmissionsRecalculationFailure(
                  err
                )
              )
            )
          );
      })
    )
  );

  recalculationSO2Emissions = createEffect(() =>
    this.actions.pipe(
      ofType(SO2EmissionsRecalculationActions.AddSO2EmissionsRecalculation),
      exhaustMap((action) => {
        return this.so2Client.recalculation(action.props).pipe(
          concatMap((res) => [
            SO2EmissionsRecalculationActions.AddSO2EmissionsRecalculationSuccess(
              res
            ),
            SO2EmissionsRecalculationActions.ListSO2EmissionsRecalculation({
              startTime: action.props.startTime,
              train: action.props.area,
            }),
            SO2ChartsActions.getSO2EmissionsFlowmeterGraph({
              date: action.props.startTime,
              plant: action.props.plantType,
              areaType: action.props.area,
            }),
            SO2EmissionsActions.ListSO2Emissions({
              startTime: action.props.startTime,
            }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(
              SO2EmissionsRecalculationActions.AddSO2EmissionsRecalculationFailure(
                err
              )
            ),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  deleteRecalculationSO2Emissions = createEffect(() =>
    this.actions.pipe(
      ofType(SO2EmissionsRecalculationActions.DeleteSO2EmissionsRecalculation),
      exhaustMap((action) => {
        return this.so2Client.deleteSO2Recalculation(action.props.id).pipe(
          concatMap((res) => [
            SO2EmissionsRecalculationActions.DeleteSO2EmissionsRecalculationSuccess(
              {
                data: res,
              }
            ),
            SO2EmissionsRecalculationActions.ListSO2EmissionsRecalculation({
              startTime: action.props.startTime,
              train: action.props.area,
            }),
            SO2ChartsActions.getSO2EmissionsFlowmeterGraph({
              date: action.props.startTime,
              plant: action.props.plantType,
              areaType: action.props.area,
            }),
            SO2EmissionsActions.ListSO2Emissions({
              startTime: action.props.startTime,
            }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(
              SO2EmissionsRecalculationActions.DeleteSO2EmissionsRecalculationFailure(
                err
              )
            ),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}
