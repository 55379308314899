import { createAction, props } from '@ngrx/store';

import { EditFlareReportVolumePayload, FlareReportPayload } from '../flare-report/models';
import {
  BlowdownInput,
  BlowdownInputSavePayload,
  ChemicalCompositionTable,
  ManualRecordsSavePayload,
  TargetPermit,
  TargetPermitSavePayload,
} from '../models';

export const getDailyFlareReport = createAction(
  '[SGI] Get Daily Flare Report',
  props<{ date: string }>()
);
export const getDailyFlareReportSuccess = createAction(
  '[SGI] Get Daily Flare Report Success',
  props<{ payload: FlareReportPayload }>()
);
export const getDailyFlareReportFailure = createAction(
  '[SGI] Get Daily Flare Report Failure',
  props<{ error: string }>()
);

export const getChemicalCompositionRecords = createAction(
  '[SGI] Get Chemical Compositions',
  props<{ date: string }>()
);
export const getChemicalCompositionRecordsSuccess = createAction(
  '[SGI] Get Chemical Compositions Success',
  props<{ payload: ChemicalCompositionTable[] }>()
);
export const getChemicalCompositionRecordsFailure = createAction(
  '[SGI] Get Chemical Compositions Failure',
  props<{ error: string }>()
);

export const getBlowDownRecords = createAction(
  '[SGI] Get Blowdown Records',
  props<{ from: string; to: string }>()
);
export const getBlowDownRecordsSuccess = createAction(
  '[SGI] Get Blowdown Records Success',
  props<{ payload: BlowdownInput[] }>()
);
export const getBlowDownRecordsFailure = createAction(
  '[SGI] Get Blowdown Records Failure',
  props<{ error: string }>()
);

export const saveBlowDownRecord = createAction(
  '[SGI] Save Blowdown Record',
  props<{ date: string; payload: BlowdownInputSavePayload }>()
);
export const saveBlowDownRecordSuccess = createAction(
  '[SGI] Save Blowdown Record Success',
  props<{ date: string }>()
);
export const saveBlowDownRecordFailure = createAction(
  '[SGI] Save Blowdown Record Failure',
  props<{ error: string }>()
);

export const deleteBlowDownRecord = createAction(
  '[SGI] Delete Blowdown Record',
  props<{ date: string; id: number }>()
);
export const deleteBlowDownRecordSuccess = createAction(
  '[SGI] Delete Blowdown Record Success',
  props<{ date: string }>()
);
export const deleteBlowDownRecordFailure = createAction(
  '[SGI] Delete Blowdown Record Failure',
  props<{ error: string }>()
);

export const getTargetPermitRecords = createAction(
  '[SGI] Get Target/Permit Records',
  props<{ year: number }>()
);
export const getTargetPermitRecordsSuccess = createAction(
  '[SGI] Get Target/Permit Records Success',
  props<{ payload: TargetPermit[] }>()
);
export const getTargetPermitRecordsFailure = createAction(
  '[SGI] Get Target/Permit Records Failure',
  props<{ error: string }>()
);

export const saveTargetPermitRecord = createAction(
  '[SGI] Save Target/Permit Records',
  props<{ year: number; payload: TargetPermitSavePayload }>()
);
export const saveTargetPermitRecordSuccess = createAction(
  '[SGI] Save Target/Permit Records Success'
);
export const saveTargetPermitRecordFailure = createAction(
  '[SGI] Save Target/Permit Records Failure',
  props<{ error: string }>()
);

export const saveManualRecord = createAction(
  '[SGI] Save Manual Record',
  props<{ date: string; payload: ManualRecordsSavePayload }>()
);
export const saveManualRecordSuccess = createAction(
  '[SGI] Save Manual Record Success'
);
export const saveManualRecordFailure = createAction(
  '[SGI] Save Manual Record Failure',
  props<{ error: string }>()
);

export const approveSgiFlareReport = createAction(
  '[SGI] Approve SGI Flare Report',
  props<{ date: string }>()
);
export const approveSgiFlareReportSuccess = createAction(
  '[SGI] Approve SGI Flare Report Success'
);
export const approveSgiFlareReportFailure = createAction(
  '[SGI] Approve SGI Flare Report Failure',
  props<{ error: string }>()
);

export const getApproveTimestamp = createAction(
  '[SGI] Get Approve timestamp',
  props<{ date: string }>()
);
export const getApproveTimestampSuccess = createAction(
  '[SGI] Get Approve timestamp Success',
  props<{ payload: string | null }>()
);
export const getApproveTimestampFailure = createAction(
  '[SGI] Get Approve timestamp Failure',
  props<{ error: string }>()
);

export const exportMonthlyReport = createAction(
  '[SGI] Export Monthly Report',
  props<{ date: string }>()
);
export const exportMonthlyReportSuccess = createAction(
  '[SGI] Export Monthly Report Success'
);
export const exportMonthlyReportFailure = createAction(
  '[SGI] Export Monthly Report Failure',
  props<{ error: string }>()
);


export const editFlareRecordVolume = createAction(
  '[SGI] Edit Flare Record Volume',
  props<{ date: string, payload: EditFlareReportVolumePayload }>()
);
export const editFlareRecordVolumeSuccess = createAction(
  '[SGI] Edit Flare Record Volume Success'
);
export const editFlareRecordVolumeFailure = createAction(
  '[SGI] Edit Flare Record Volume Failure',
  props<{ error: string }>()
);

export const deleteFlareRecordVolume = createAction(
  '[SGI] Delete Flare Record Volume',
  props<{ date: string, payload: EditFlareReportVolumePayload }>()
);
export const deleteFlareRecordVolumeSuccess = createAction(
  '[SGI] Delete Flare Record Volume Success'
);
export const deleteFlareRecordVolumeFailure = createAction(
  '[SGI] Delete Flare Record Volume Failure',
  props<{ error: string }>()
);
