import { createReducer, on } from '@ngrx/store';
import * as so2ChartsActions from './so2-charts.action';
import { SO2FlowmeterGraphDTO } from 'src/app/web-api-client';

export interface SO2ChartsState {
  data: SO2FlowmeterGraphDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: SO2ChartsState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const SO2ChartsReducer = createReducer(
  initialState,
  on(
    so2ChartsActions.getSO2EmissionsFlowmeterGraph,
    (state, { date, plant, areaType }) => ({
      ...state,
      data: null,
      result: null,
      isLoading: true,
    })
  ),
  on(
    so2ChartsActions.getSO2EmissionsFlowmeterGraphSuccess,
    (state, result) => ({
      data: result.data,
      result,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
    })
  ),
  on(
    so2ChartsActions.getSO2EmissionsFlowmeterGraphFailure,
    (state, result) => ({
      ...state,
      isLoading: false,
      isLoadingFailure: true,
    })
  )
);
