import { createAction, props } from '@ngrx/store';
import { ChemicalCompoundRecord } from 'src/app/web-api-client';

export const GetCCData = createAction(
  '[CC] Get CC Data',
  props<{ startTime: Date }>()
);
export const GetCCDataSuccess = createAction(
  '[CC] Get CC Data Success',
  props<{ data: ChemicalCompoundRecord[] }>()
);
export const GetCCDataFailure = createAction(
  '[CC] Get CC Data Failure',
  props<{ message: string }>()
);
