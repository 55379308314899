import { Component, Input, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

export type RadioTogleOption = string | number | null;

@Component({
  selector: 'app-radio-toggle',
  templateUrl: './radio-toggle.component.html',
  styleUrls: ['./radio-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioToggleComponent),
      multi: true,
    },
  ],
})
export class RadioToggleComponent implements ControlValueAccessor {
  @Input()
  public label: string | null = null;

  @Input()
  public options: RadioTogleOption[] = [];

  // public checkedIdx: number = 0;

  public radioControl = new FormControl<RadioTogleOption>(null);

  public onChange = (value: RadioTogleOption) => {};

  public onTouched = () => {};

  constructor() {
    this.radioControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.onChange(value);
      });
  }

  writeValue(value: RadioTogleOption): void {
    this.radioControl.setValue(value);
  }

  registerOnChange(fn: (value: RadioTogleOption) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
