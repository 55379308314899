import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as FlaringActions from '../state/flaring.actions';
import * as notificationActions from '../../../../../../../../../state/notification/notification.action';
import { KTLSGPClient } from '../../../../../../../../../web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class FlaringEffects {
  constructor(
    private actions: Actions,
    private ktlfdClient: KTLSGPClient,
    private store: Store<AppState>
  ) {}

  chemicalCompound = createEffect(() =>
    this.actions.pipe(
      ofType(FlaringActions.getChemicalCompound),
      exhaustMap(() => {
        return this.ktlfdClient.getChemicalCompoundsID().pipe(
          map((res) =>
            FlaringActions.getChemicalCompoundSuccess({ data: res })
          ),
          catchError((err) =>
            of(FlaringActions.getChemicalCompoundFailure(err))
          )
        );
      })
    )
  );

  chemicalCompoundsEquipment = createEffect(() =>
    this.actions.pipe(
      ofType(FlaringActions.getChemicalCompoundsEquipment),
      exhaustMap(() => {
        // todo: check this hardcoded params

        return this.ktlfdClient.getChemicalCompoundsEquipment(null, 1).pipe(
          map((res) =>
            FlaringActions.getChemicalCompoundsEquipmentSuccess({
              dataEquipment: res,
            })
          ),
          catchError((err) =>
            of(FlaringActions.getChemicalCompoundsEquipmentFailure(err))
          )
        );
      })
    )
  );

  StreamsName$ = createEffect(() =>
    this.actions.pipe(
      ofType(FlaringActions.getStreamsName),
      exhaustMap(() => {
        this.store.dispatch(notificationActions.startLoading());

        return this.ktlfdClient.getAllStreams().pipe(
          map((res) => {
            this.store.dispatch(notificationActions.stopLoading());
            return FlaringActions.getStreamsNameSuccess({ streams: res });
          }),
          catchError((err) => of(FlaringActions.getStreamsNameFailure(err)))
        );
      })
    )
  );

  addManualRecord = createEffect(() =>
    this.actions.pipe(
      ofType(FlaringActions.AddManualRecord),
      exhaustMap((action) => {
        return this.ktlfdClient.addManualRecord(action).pipe(
          concatMap((res) => [
            FlaringActions.AddManualRecordSuccess(res),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(FlaringActions.AddManualRecordFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}
