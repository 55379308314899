import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { OperatorService } from 'src/app/core/services/lpg/operator.service';

import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';

@Component({
  selector: 'app-shared-dropdown-gas-park',
  templateUrl: './shared-dropdown-gas-park.component.html',
  styleUrls: ['./shared-dropdown-gas-park.component.scss'],
})
export class SharedDropdownGasParkComponent implements OnInit {
  @Input() operatorAddForm: FormGroup = new FormGroup({});
  gasParkForm: FormGroup;

  parks: string[] = []; //cascading dropdown
  selectedPark: string = ''; //cascading dropdown
  gases: string[] = []; //cascading dropdown
  selectedGas: string = ''; //cascading dropdown
  options: string[] = []; //cascading dropdown
  selectedOptions: string = ''; //cascading dropdown
  isOtherSelected: boolean = false; //other input
  otherInputValue: string = ''; //other input

  constructor(
    private dataService: OperatorService,
    private formBuilder: FormBuilder
  ) {
    this.gasParkForm = this.formBuilder.group({
      lpgPark: ['', Validators.required],
      gasType: ['', Validators.required],
      bullet: ['', Validators.required],
      other: ['', Validators.minLength(1)],
    });
  }

  ngOnInit(): void {
    this.parks = this.dataService.getParks();
    this.gases = [];
    this.selectedGas = '';
    this.options = [];
    this.selectedOptions = '';

    this.operatorAddForm.addControl('gasParkForm', this.gasParkForm);
  }

  onOptionChange() {
    this.isOtherSelected = this.selectedOptions === 'Other';
    if (!this.isOtherSelected) {
      this.otherInputValue = '';
    }
  }

  onParkChange() {
    this.gases = this.dataService.getGasesForPark(this.selectedPark);
    this.selectedGas = '';
    this.options = [];
    this.selectedOptions = '';
    this.isOtherSelected = false;
  }

  onGasChange() {
    this.options = this.dataService.getOptionsForGas(
      this.selectedPark,
      this.selectedGas
    );
    this.selectedOptions = '';
    this.isOtherSelected = false;
  }
}
