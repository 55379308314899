<div *ngIf="showNavbar">
  <app-navbar />
</div>

<div *ngIf="isMainPage || isKTLMonthlyReport || isKTLCC || isKTLSchema || isSGPDailyReport || isSGPMonthlyReport || isSGPCC || isSGPSchema || isSGIFlareReport || isSGITargetPermit || isSGICC || isLPGFuelGasFlaring || isLPGCC || isSO2EmissionsMonthlyReport || isFGDKTLDailyReport || isSGPGasUsageReport || isFlowmeterCalc || isFGDSGPDailyReport || isFGDSGPMonthlyReport || isFGDKTLInput || isFGDKTLMonthlyReport || isNotAuthorize">
    <router-outlet />
</div>

<div *ngIf="!isMainPage && !isKTLMonthlyReport && !isKTLCC && !isKTLSchema && !isSGPDailyReport && !isSGPMonthlyReport && !isSGPCC && !isSGPSchema && !isSGIFlareReport && !isSGITargetPermit && !isSGICC && !isLPGFuelGasFlaring && !isLPGCC && !isSO2EmissionsMonthlyReport && !isFGDKTLDailyReport && !isSGPGasUsageReport && !isFlowmeterCalc && !isFGDSGPDailyReport && !isFGDSGPMonthlyReport && !isFGDKTLInput && !isFGDKTLMonthlyReport && !isNotAuthorize"> <!-- && !isKTLCC && !isKTLSchema-->
  <div class="row">
    <div class="col-md-2">
      <app-sidebar />
    </div>
    <div class="col-md-10">
      <router-outlet />
    </div>
  </div>
</div>

<app-shared-notification />

<app-shared-loading-spinner />

<app-shared-scroll-to-top />
