import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
    private breadcrumbs = new BehaviorSubject<string[]>(['TCO']);

    constructor() {}
  
    getBreadcrumbStream() {
      return this.breadcrumbs.asObservable();
    }
  
    updateBreadcrumb(newItem: string) {
      const currentBreadcrumbs = this.breadcrumbs.getValue();
      const newBreadcrumbs = [...currentBreadcrumbs, newItem];
      this.breadcrumbs.next(newBreadcrumbs);
    }
  
    resetBreadcrumbTo(path: string[]) {
        this.breadcrumbs.next(['TCO', ...path]);
      } 
}