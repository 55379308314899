import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  mergeMap,
  concatMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as fuelGasFlaringAction from './fuel-gas-flaring.actions';
import * as notificationActions from '../../notification/notification.action';
import * as fuelGasFlaringDefaultActions from '../../../features/tco/processeng/fd/lpg/fuel-gas-flaring/store/fuel-gas-flaring-default.action';
import { LPGClient } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class FuelGasFlaringEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}

  fuelGasFlaringData = createEffect(() =>
    this.actions.pipe(
      ofType(fuelGasFlaringAction.FuelGasFlaringData),
      exhaustMap((action) => {
        return this.lpgClient
          .getFuelGasFlaring(new Date(action.startTime))
          .pipe(
            map((res) =>
              fuelGasFlaringAction.FuelGasFlaringDataSuccess({ data: res })
            ),
            catchError((err) =>
              of(fuelGasFlaringAction.FuelGasFlaringDataFailure(err))
            )
          );
      })
    )
  );

  editFuelGasFlaringData = createEffect(() =>
    this.actions.pipe(
      ofType(fuelGasFlaringAction.EditFuelGasFlaringData),
      exhaustMap((action) => {
        return this.lpgClient.addPEMonthlyInput(action.data).pipe(
          concatMap((res) => [
            fuelGasFlaringAction.EditFuelGasFlaringDataSuccess(res),
            fuelGasFlaringAction.FuelGasFlaringData({
              startTime: action.data.startTime,
            }),
            fuelGasFlaringDefaultActions.getFuelGasFlaringDefault({
              date: action.data.startTime,
            }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(fuelGasFlaringAction.EditFuelGasFlaringDataFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  // addOperator = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(OperatorAction.AddOperator),
  //     exhaustMap((action) => {
  //       return this.lpgClient.addLPGOperatorInput(action).pipe(
  //         concatMap((res) => [
  //           OperatorAction.AddOperatorSuccess(res),
  //           OperatorAction.ListOperator({
  //             startTime: action.startTime,
  //           }),
  //           notificationActions.showNotification({ isSuccess: true }),
  //         ]),
  //         catchError((err) => [
  //           of(OperatorAction.AddOperatorFailure(err)),
  //           notificationActions.showNotification({ isSuccess: false }),
  //         ])
  //       );
  //     })
  //   )
  // );

  // deleteOperator = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(OperatorAction.DeleteOperator),
  //     exhaustMap((action) => {
  //       return this.lpgClient.deleteLPGOperatorInput(action.id).pipe(
  //         concatMap((res) => [
  //           OperatorAction.DeleteOperatorSuccess(res),
  //           OperatorAction.ListOperator({ startTime: action.startTime }),
  //           notificationActions.showNotification({ isSuccess: true }),
  //         ]),
  //         catchError((err) => [
  //           of(OperatorAction.DeleteOperatorFailure(err)),
  //           notificationActions.showNotification({ isSuccess: false }),
  //         ])
  //       );
  //     })
  //   )
  // );
}
