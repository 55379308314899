import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';
import * as AuthActions from './auth.action';
import { UserClient, UserGroups } from '../../web-api-client';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private userClient: UserClient
  ) { }

  getUserGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserGroups),
      switchMap(action => {
        const storedUser = localStorage.getItem('currentUser');
        if (storedUser === "") {
          console.log(storedUser);
          console.log("effect auth stored user");
          const user = this.parseUser(storedUser);
          return of(AuthActions.getUserGroupsSuccess({ userGroups: user }));
        } else {
          return this.userClient.getCurrentUserGroups()
            .pipe(
              retry(3),
              map(userGroups => AuthActions.getUserGroupsSuccess({ userGroups })),
                catchError(() => of(AuthActions.getUserGroupsFailure()))
          )
        }
      })
    )
  );

  getUserGroupsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserGroupsSuccess),
      map(action => {
        localStorage.setItem('currentUser', JSON.stringify(action.userGroups));
        return { type: '[No Action]' }; // No further action required
      })
    ),
    { dispatch: false }
  );

  private parseUser(storedUser: string): UserGroups[] {
    const parsedUser = JSON.parse(storedUser);
    var myUserGroups = parsedUser.map((role: string) => UserGroups[role as keyof typeof UserGroups]);
    return myUserGroups;
  }
  //logout$ = createEffect(() =>
  //  this.actions$.pipe(
  //    ofType(AuthActions.logout),
  //    map(() => {
  //      localStorage.removeItem('currentUser');
  //      return { type: '[No Action]' }; // No further action required
  //    })
  //  ),
  //  { dispatch: false }
  //);
}
