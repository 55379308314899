import { Action, createReducer, on } from '@ngrx/store';
import * as FuelGasFlaringAction from './flaring-report.actions';
import { LPGFlaringReportDTO } from 'src/app/web-api-client';

export interface FlaringReportState {
  data: LPGFlaringReportDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: FlaringReportState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const FlaringReportReducer = createReducer(
  initialState,
  // on(FuelGasFlaringAction.AddOperator, (state, result) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(FuelGasFlaringAction.AddOperatorSuccess, (state, result) => ({
  //   data: result,
  //   result,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  //   isLoadingFailure: false,
  // })),
  // on(FuelGasFlaringAction.AddOperatorFailure, (state, result) => ({
  //   ...state,
  //   result,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // })),
  on(FuelGasFlaringAction.FlaringReportData, (state, result) => ({
    ...state,
    data: null,
    isLoading: true,
  })),
  on(FuelGasFlaringAction.FlaringReportDataSuccess, (state, result) => ({
    ...state,
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(FuelGasFlaringAction.FlaringReportDataFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
  // on(OperatorAction.EditOperator, (state, result) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(OperatorAction.EditOperatorSuccess, (state, result) => ({
  //   data: result.data,
  //   result,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  //   isLoadingFailure: false,
  // })),
  // on(OperatorAction.EditOperatorFailure, (state, result) => ({
  //   ...state,
  //   result,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // })),
  // on(OperatorAction.DeleteOperator, (state, result) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(OperatorAction.DeleteOperatorSuccess, (state, result) => ({
  //   data: result.data,
  //   result,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  //   isLoadingFailure: false,
  // })),
  // on(OperatorAction.DeleteOperatorFailure, (state, result) => ({
  //   ...state,
  //   result,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // }))
);
