import { createAction, props } from '@ngrx/store';
import {
  LPGFuelGasFlaringReportDTO,
  LPGMonthlyInputRecordDTO,
} from 'src/app/web-api-client';

export const FuelGasFlaringData = createAction(
  '[Fuel Gas Flaring] Get Data Fuel Gas Flaring',
  props<{ startTime: Date }>()
);
export const FuelGasFlaringDataSuccess = createAction(
  '[Fuel Gas Flaring] Get Data Fuel Gas Flaring Success',
  props<{ data: LPGFuelGasFlaringReportDTO }>()
);
export const FuelGasFlaringDataFailure = createAction(
  '[Fuel Gas Flaring] Get Data Fuel Gas Flaring Failure',
  props<{ message: string }>()
);

export const EditFuelGasFlaringData = createAction(
  '[Fuel Gas Flaring] Edit Data Fuel Gas Flaring',
  props<{ data: LPGMonthlyInputRecordDTO }>()
);
export const EditFuelGasFlaringDataSuccess = createAction(
  '[Fuel Gas Flaring] Edit Data Fuel Gas Flaring Success',
  props<any>()
);
export const EditFuelGasFlaringDataFailure = createAction(
  '[Fuel Gas Flaring] Edit Data Fuel Gas Flaring Failure',
  props<{ message: string }>()
);

// export const AddOperator = createAction(
//   '[Operator] Add Operator',
//   props<LPGOperatorInputDTO>()
// );
// export const AddOperatorSuccess = createAction(
//   '[Operator] Add Operator Success',
//   props<any>()
// );
// export const AddOperatorFailure = createAction(
//   '[Operator] Add Operator Failure',
//   props<{ message: string }>()
// );

// export const EditOperator = createAction(
//   '[Operator] Edit Operator',
//   props<LPGOperatorInputDTO>()
// );
// export const EditOperatorSuccess = createAction(
//   '[Operator] Edit Operator Success',
//   props<any>()
// );
// export const EditOperatorFailure = createAction(
//   '[Operator] Edit Operator Failure',
//   props<{ message: string }>()
// );

// export const DeleteOperator = createAction(
//   '[Operator] Delete Operator',
//   props<{ id: number; startTime: Date }>()
// );
// export const DeleteOperatorSuccess = createAction(
//   '[Operator] Delete Operator Success',
//   props<any>()
// );
// export const DeleteOperatorFailure = createAction(
//   '[Operator] Delete Operator Failure',
//   props<{ message: string }>()
// );
