import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  mergeMap,
  concatMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as TargetPermitAction from './target-permit.actions';
import * as notificationActions from '../../notification/notification.action';
import { LPGClient, LPGTargetPermitInputDTO } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class TargetPermitEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}

  listTargetPermit = createEffect(() =>
    this.actions.pipe(
      ofType(TargetPermitAction.ListTargetPermit),
      exhaustMap((action) => {
        return this.lpgClient
          .getTargetPermitInputs(action.year)
          .pipe(
            map((res) => TargetPermitAction.ListTargetPermitSuccess({ data: res })),
            catchError((err) => of(TargetPermitAction.ListTargetPermitFailure(err)))
          );
      })
    )
  );

  addTargetPermit = createEffect(() =>
    this.actions.pipe(
      ofType(TargetPermitAction.AddTargetPermit),
      exhaustMap((action) => {
        return this.lpgClient.addTargetPermitInput(action).pipe(
          concatMap((res) => [
            TargetPermitAction.AddTargetPermitSuccess(res),
            TargetPermitAction.ListTargetPermit({ year: action.year }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(TargetPermitAction.AddTargetPermitFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}