import { createAction, props } from '@ngrx/store';
import { FileResponse, DailyReportInputRecordDTO, MonthlyInputRecordDTO, CalculatedStreamDTO, KTLFGDOverrideRecordDTO } from 'src/app/web-api-client';

export const getDailyFuelGas = createAction(
  '[KTL] Get Daily Fuel Gas',
  props<{ startTime: Date}>()
);
export const getDailyFuelGasSuccess = createAction(
  '[KTL] Get Daily Fuel Gas Success',
  props<{ data: CalculatedStreamDTO[] }>()
);
export const getDailyFuelGasFailure = createAction(
  '[KTL] Get Daily Fuel Gas Failure',
  props<{ message: string }>()
);

// export const getMonthlyFuelGas = createAction(
//   '[KTL] Get Monthly Fuel Gas',
//   props<{ startTime: Date }>()
// );
// export const getMonthlyFuelGasSuccess = createAction(
//   '[KTL] Get Monthly Fuel Gas Success',
//   props<{ data: CalculatedStreamDTO[] }>()
// );
// export const getMonthlyFuelGasFailure = createAction(
//   '[KTL] Get Monthly Fuel Gas Failure',
//   props<{ message: string }>()
// );

// export const AddDailyManualRecord = createAction(
//   '[KTL] Add Daily Manual Record',
//   props<DailyReportInputRecordDTO>()
// );
// export const AddDailyManualRecordSuccess = createAction(
//   '[KTL] Add Daily Manual Record Success',
//   props<any>()
// );
// export const AddDailyManualRecordFailure = createAction(
//   '[KTL] Add Daily Manual Record Failure',
//   props<{ message: string }>()
// );

// export const AddMonthlyManualRecord = createAction(
//   '[KTL] Add Monthly Manual Record',
//   props<MonthlyInputRecordDTO>()
// );
// export const AddMonthlyManualRecordSuccess = createAction(
//   '[KTL] Add Monthly Manual Record Success',
//   props<any>()
// );
// export const AddMonthlyManualRecordFailure = createAction(
//   '[KTL] Add Monthly Manual Record Failure',
//   props<{ message: string }>()
// );

// export const AddOverrideManualRecord = createAction(
//   '[KTL] Add Override Manual Record',
//   props<{props: KTLFGDOverrideRecordDTO}>()
// );
// export const AddOverrideManualRecordSuccess = createAction(
//   '[KTL] Add Override Manual Record Success',
//   props<any>()
// );
// export const AddOverrideManualRecordFailure = createAction(
//   '[KTL] Add Override Manual Record Failure',
//   props<{ message: string }>()
// );

export const AddOverrideManualRecords = createAction(
  '[KTL] Add Override Manual Records',
  props<{date: Date; props: KTLFGDOverrideRecordDTO[]}>()
);
export const AddOverrideManualRecordsSuccess = createAction(
  '[KTL] Add Override Manual Records Success',
  props<any>()
);
export const AddOverrideManualRecordsFailure = createAction(
  '[KTL] Add Override Manual Records Failure',
  props<{ message: string }>()
);

export const getIsDailyRecordExist = createAction(
  '[KTL] Get Is Daily Record Exist',
  props<{ startTime: Date }>()
);
export const getIsDailyRecordExistSuccess = createAction(
  '[KTL] Get Is Daily Record Exist Success',
  props<{ isDailyRecordExistdata: boolean }>()
);
export const getIsDailyRecordExistFailure = createAction(
  '[KTL] Get Is Daily Record Exist Failure',
  props<{ message: string }>()
);

export const DeleteOverrideRecord = createAction(
  '[KTL] Override Record Delete',
  props<{ streamId: number; startTime: Date }>()
);
export const DeleteOverrideRecordSuccess = createAction(
  '[KTL] Override Record Delete Success',
  props<any>()
);
export const DeleteOverrideRecordFailure = createAction(
  '[KTL] Override Record Delete Failure',
  props<{ message: string }>()
);