<!-- <app-tab [pageTitle]="pageTitle" [buttons2]="ccButtons2"></app-tab> -->

<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <label>Month</label>
            <div class="input-group mb-2 mr-sm-2">
              <div>
                <input
                  type="month"
                  id="monthPicker"
                  name="logged"
                  class="form-control"
                  [(ngModel)]="month"
                  (ngModelChange)="monthChange()"
                />
              </div>
              <div>
                <button
                  class="ml-4"
                  mat-raised-button
                  style="background-color: #0066b2; color: white"
                  onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
                  onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
                  (click)="exportToExcel()"
                >
                  Export
                </button>
              </div>
              <div class="ml-4" *ngIf="getLoading()">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col-md-3">
    <mat-card>
      <mat-card-header>
        <h1>FUEL GAS FROM SALES GAS LINE</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z8">
            <thead>
              <col />
              <colgroup span="2"></colgroup>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center"></th>
              </tr>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center"></th>
              </tr>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-3"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.fuelGasFromSalesGasLine, key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6">
    <mat-card>
      <mat-card-header>
        <h1>FUEL GAS FROM KTL</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z9">
            <thead>
              <col />
              <colgroup span="4"></colgroup>
              <tr>
                <th colspan="1"></th>
                <th colspan="4" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
              <tr>
                <th class="col-2"></th>

                <th class="col-2">KTL-1</th>
                <th class="col-2">KTL-2</th>
                <th class="col-2">KTL-2.3</th>
                <th class="col-2">Average</th>
              </tr>
              <tr>
                <th class="col-2">LIMS SP Names</th>

                <th class="col-2">70-07-1</th>
                <th class="col-2">70-07-2</th>
                <th class="col-2">70-15-3-2</th>
                <th class="col-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-3"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.fuelGasFromKTL!["KTL-1"], key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.fuelGasFromKTL!["KTL-2"], key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.fuelGasFromKTL!["KTL-2.3"], key) }}
                </td>
                <td class="col-2">
                  {{ getObjectValue(data.fuelGasFromKTL!["Average"], key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-3">
    <mat-card>
      <mat-card-header>
        <h1>DRY GAS WEIGHTED AVERAGE</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z10">
            <thead>
              <col />
              <colgroup span="2"></colgroup>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center"></th>
              </tr>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center"></th>
              </tr>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-3"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.dryGasWeightedAverage, key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col-md-8">
    <mat-card>
      <mat-card-header>
        <h1>PROPANE</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z11">
            <thead>
              <col />
              <colgroup span="6"></colgroup>
              <tr>
                <th colspan="1"></th>
                <th colspan="6" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
              <tr>
                <th class="col-2">LIMS SP Names</th>
                <th class="col-1">E-902</th>
                <th class="col-1">F-3501</th>
                <th class="col-1">F-3502</th>
                <th class="col-1">F-3503</th>
                <th class="col-2">Old park average</th>
                <th class="col-2">New park average</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propane!["E-902"], key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propane!["F-3501"], key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propane!["F-3502"], key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propane!["F-3503"], key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propane!["Old park average"], key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propane!["New park average"], key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-4">
    <mat-card>
      <mat-card-header>
        <h1>PROPANE WEIGHTED AVERAGE</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z12">
            <thead>
              <col />
              <colgroup span="2"></colgroup>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center"></th>
              </tr>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-3"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.propaneWeightedAverage, key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col-md-8">
    <mat-card>
      <mat-card-header>
        <h1>BUTANE</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z13">
            <thead>
              <col />
              <colgroup span="6"></colgroup>
              <tr>
                <th colspan="1"></th>
                <th colspan="6" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
              <tr>
                <th class="col-2">LIMS SP Names</th>
                <th class="col-1">E-901</th>
                <th class="col-1">F-3511</th>
                <th class="col-1">F-3512</th>
                <th class="col-1">F-3513</th>
                <th class="col-2">Old park average</th>
                <th class="col-2">New park average</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butane!["E-901"], key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butane!["F-3511"], key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butane!["F-3512"], key) }}
                </td>
                <td
                  class="col-1"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butane!["F-3513"], key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butane!["Old park average"], key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butane!["New park average"], key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-4">
    <mat-card>
      <mat-card-header>
        <h1>BUTANE WEIGHTED AVERAGE</h1>
      </mat-card-header>
      <mat-card-content>
        <span *ngIf="data">
          <table class="mat-elevation-z14">
            <thead>
              <col />
              <colgroup span="2"></colgroup>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center"></th>
              </tr>
              <tr>
                <th colspan="2" scope="colgroup" class="text-center">
                  weight %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let key of ccKeys">
                <td
                  class="col-3"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectName(key) }}
                </td>
                <td
                  class="col-2"
                  [ngStyle]="key === 'mw' ? { 'font-weight': 'bold' } : {}"
                >
                  {{ getObjectValue(data.butaneWeightedAverage, key) }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
</div>
