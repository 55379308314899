import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hint-error',
  templateUrl: './hint-error.component.html',
  styleUrls: ['./hint-error.component.scss'],
})
export class HintErrorComponent {
  @Input()
  public text: string = 'The field is required.';
}
