import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { CommonService } from 'src/app/core/services/common/common.service';
import { ExportService } from 'src/app/core/services/export/export.service';
import { FlaringReportData } from 'src/app/state/lpg/flaring-report/flaring-report.actions';
import { FlaringReportSelector } from 'src/app/state/lpg/flaring-report/flaring-report.selector';
import {
  LPGFlaringReportElementsType,
  LPGFlaringReportItemDTO,
} from 'src/app/web-api-client';

@Component({
  selector: 'app-flaring-report',
  templateUrl: './flaring-report.component.html',
  styleUrls: ['./flaring-report.component.scss'],
})
export class FlaringReportComponent implements OnInit {
  month: string = '';
  data?: {
    items: LPGFlaringReportItemDTO[];
    elementsType: LPGFlaringReportElementsType;
  }[];

  isLoading: boolean = false;

  constructor(
    private store: Store<AppState>,
    private commonService: CommonService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.month = this.commonService.formatDate(new Date(), 'YYYY-MM');
    this.store.dispatch(FlaringReportData({ startTime: new Date() }));
    this.store.select(FlaringReportSelector).subscribe((list) => {
      if (list) this.data = list;
    });
  }

  count() {
    return Array(this.data![0].items.length - 2);
  }

  getFlaringReportCCName(name: string) {
    if (name === 'C6Plus') return 'C6+';
    else return name;
  }

  monthChange() {
    this.store.dispatch(FlaringReportData({ startTime: new Date(this.month) }));
  }
  exportToExcel() {
    this.exportService.exportFlaringReport(new Date(this.month));
  }
  getLoading(): boolean {
    return this.exportService.getIsLoading();
  }
  formatNumberCC(value: any): string {
    return this.commonService.formatNumberCC(value);
  }
  formatNumberMV(value: any): string {
    return this.commonService.formatNumberMV(value);
  }
}
