export interface CCType {
  [key: string]: string;
}

export const CCNames: CCType = {
  n2: 'N2',
  cO2: 'CO2',
  c1: 'C1',
  c2: 'C2',
  c3: 'C3',
  iC4: 'IC4',
  nC4: 'nC4',
  iC5: 'IC5',
  nC5: 'nC5',
  c6Plus: 'C6+',
  h2S: 'H2S',
  cH3SH: 'CH3SH',
  c2H5SH: 'C2H5SH',
  iC3H7SH: 'IC3H7SH',
  nC3H7SH: 'NC3H7SH',
  c4H9SH: 'C4H9SH',
  cos: 'COS',
  h2O: 'H2O',
  o2: 'O2',
  h2: 'H2',
  he: 'HE',
  mw: 'MW',
};

export const FlaringReportNames: CCType = {
  n2: 'N2',
  co2: 'CO2',
  c1: 'C1',
  c2: 'C2',
  c3: 'C3',
  ic4: 'IC4',
  nc4: 'nC4',
  ic5: 'IC5',
  nc5: 'nC5',
  c6plus: 'C6+',
  h2s: 'H2S',
  ch3sh: 'CH3SH',
  c2h5sh: 'C2H5SH',
  ic3h7sh: 'IC3H7SH',
  nc3h7sh: 'NC3H7SH',
  c4h9sh: 'C4H9SH',
  cos: 'COS',
  h2o: 'H2O',
  o2: 'O2',
  h2: 'H2',
  he: 'HE',
  mw: 'MW',
};
