import { Action, createReducer, on } from '@ngrx/store';
import * as TargetPermitAction from './target-permit.actions';
import { LPGTargetPermitInputDTO } from 'src/app/web-api-client';

export interface TargetPermitState {
  data: LPGTargetPermitInputDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: TargetPermitState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const TargetPermitReducer = createReducer(
  initialState,
  on(TargetPermitAction.AddTargetPermit, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(TargetPermitAction.AddTargetPermitSuccess, (state, result) => ({
    data: result,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(TargetPermitAction.AddTargetPermitFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TargetPermitAction.ListTargetPermit, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(TargetPermitAction.ListTargetPermitSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(TargetPermitAction.ListTargetPermitFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
