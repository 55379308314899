import { createSelector } from '@ngrx/store';
import { AppState } from '../../core/models/appState.model';
import { LPGFlowmeterValuesDTO } from 'src/app/web-api-client';

export const chartTitleSelector = createSelector(
  (state: AppState) => state.chartjs.data?.title,
  (data: string | undefined) => data
);

export const chartDataSelector = createSelector(
  (state: AppState) => state.chartjs.data?.values,
  (data: LPGFlowmeterValuesDTO[] | undefined) => data
  // {
  //   return data?.map((val) => {
  //     return { x: val.timeStamp.getTime(), y: val.value };
  //   });
  // }
);

export const chartDataXLabelSelector = createSelector(
  (state: AppState) => state.chartjs.data?.values,
  (values: LPGFlowmeterValuesDTO[] | undefined) => {
    return values?.map((value) => value.timeStamp.toISOString());
  }
);

export const chartDataYLabelSelector = createSelector(
  (state: AppState) => state.chartjs.data?.values,
  (values: LPGFlowmeterValuesDTO[] | undefined) => {
    return values?.map((value) => value.value);
  }
);
