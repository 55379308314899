import { Action, createReducer, on } from '@ngrx/store';
import * as F205Actions from './f-205.actions';
import { F205TotalDTO } from 'src/app/web-api-client';

export interface F205State{
  data: F205TotalDTO | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: F205State = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const F205Reducer = createReducer(
  initialState,
  on(F205Actions.getF205, state => ({
    ...state,
    isLoading: true,
  })),
  on(F205Actions.getF205Success, (state, result) => ({
    ...state,
     data: result.data,
     result,
     isLoading: false,
     isLoadingSuccess: true,
     isLoadingFailure: false,
  })),
  on(F205Actions.getF205Failure, (state, result) => ({
    ...state,
    result: result,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  }))
);