<div class="row">
    <div class="col">
       
    </div>
    <div class="col">
        <h1 class="text-center">Something`s wrong here...</h1>
        <h2 class="text-center">We can`t find the page or the page isn`t available. Please contact support if you receive an error.</h2>
    </div>
    <div class="col">

    </div>
</div>