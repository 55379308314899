import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum DeleteDialogAction {
  Delete,
  Reset,
}

@Component({
  selector: 'app-shared-delete-confirmation',
  templateUrl: './shared-delete-confirmation.component.html',
  styleUrls: ['./shared-delete-confirmation.component.scss'],
})
export class SharedDeleteConfirmationComponent {
  public action: DeleteDialogAction;
  public DeleteDialogAction = DeleteDialogAction;

  constructor(
    public dialogRef: MatDialogRef<SharedDeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA)
    action: DeleteDialogAction
  ) {
    this.action = action ?? DeleteDialogAction.Delete;
  }

  onCancelClick(): void {
    this.dialogRef.close(false); // Close the dialog with a 'false' result (cancelled).
  }

  onDeleteClick(): void {
    this.dialogRef.close(true); // Close the dialog with a 'true' result (confirmed).
  }
}
