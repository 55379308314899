import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FlaringReportComponent } from './flaring-report/flaring-report.component';
import { LPGDataComponent } from './lpg-data/lpg-data.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'LPGData',
    pathMatch: 'full',
  },
  {
    path: 'FlaringReport',
    component: FlaringReportComponent,
  },
  {
    path: 'LPGData',
    component: LPGDataComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinalReportRoutingModule {}