import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FinalReportRoutingModule } from './features/tco/processeng/fd/lpg/final-report/final-report-routing.module';
import { TCORoutingModule } from './features/tco/tco-routing.module';
import { TCOComponent } from './features/tco/tco.component';
import { ProcessEngRoutingModule } from './features/tco/processeng/processeng-routing.module';
import { FDRoutingModule } from './features/tco/processeng/fd/fd-routing.module';
import { LPGRoutingModule } from './features/tco/processeng/fd/lpg/lpg-routing.module';
import { FDInputRoutingModule } from './features/tco/processeng/fd/lpg/input/input-routing.module';
import { SharedUnauthorizedPageComponent } from './shared/shared-unathorized-page/shared-unauthorized-page.component';
import { TCOMapComponent } from './features/tco/tco-map.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'TCO',
    pathMatch: 'full'
  },
  {
    path: 'TCO',
    children: [
      {
        path: '',
        component: TCOMapComponent,
      },
      {
        path: 'ProcessEng',
        loadChildren: () => import('./features/tco/processeng/processeng.module').then((m) => m.ProcessEngModule),
      },
    ],
    data: { title: 'TCO' },
  },
  {
    path: 'TCOList',
    component: TCOComponent,
    loadChildren: () =>
      import('./features/tco/tco.module').then((m) => m.TCOModule),
    data: { title: 'TCO' },
  },
  {
    path: 'not-authorized',
    component: SharedUnauthorizedPageComponent,
    data: { title: 'TCO' },
  },
  {
    path: '**',
    redirectTo: 'TCO',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    TCORoutingModule,
    ProcessEngRoutingModule,
    FDRoutingModule,
    LPGRoutingModule,
    FinalReportRoutingModule,
    FDInputRoutingModule,
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
