import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { selectUserGroups } from 'src/app/state/auth/auth.selector';

@Component({
  selector: 'app-shared-unauthorized-page',
  templateUrl: './shared-unauthorized-page.component.html',
  styleUrls: ['./shared-unauthorized-page.component.scss']
})
export class SharedUnauthorizedPageComponent {
  constructor(private store: Store<AppState>) {
    this.store.select(selectUserGroups).pipe(takeUntilDestroyed()).subscribe(roles => {})
  }
}
