import { createAction, props } from '@ngrx/store';

export const showNotification = createAction(
  '[Notify] Show Notification',
  props<{ isSuccess: boolean, message?: string }>()
);

export const showNotificationSuccess = createAction(
  '[Notify] Show Success',
  props<{ message: string }>()
);

export const showNotificationFailure = createAction(
  '[Notify] Show Failure',
  props<{ message: string }>()
);

export const showCustomNotification = createAction(
  '[Notify] Show Custom Notification',
  props<{ isSuccess: boolean; message: string }>()
);

export const clearNotification = createAction('[Notify] Cleared');

export const startLoading = createAction('[Loading] Start Loading');
export const stopLoading = createAction('[Loading] Stop Loading');
