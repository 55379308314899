import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as chartJsActions from './chartjs.action';
import { LPGClient, SO2Client } from '../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class ChartJsEffects {
  constructor(
    private actions: Actions,
    private lpgClient: LPGClient,
    private so2Client: SO2Client
  ) {}

  loadChartData = createEffect(() =>
    this.actions.pipe(
      ofType(chartJsActions.getChartData),
      exhaustMap((action) => {
        return this.lpgClient
          .getFlowmeterGraphs(
            action.startTime,
            action.endTime,
            action.graphType
          )
          .pipe(
            map((res) => chartJsActions.getChartDataSuccess(res)),
            catchError((err) => of(chartJsActions.getChartDataFailure(err)))
          );
      })
    )
  );

  getFlowmeterCalculationChart = createEffect(() =>
    this.actions.pipe(
      ofType(chartJsActions.getFlowMeterChartData),
      exhaustMap((action) => {
        return this.lpgClient
          .getFlowmeterCalculationData(
            action.startTime,
            action.endTime,
            action.graphType
          )
          .pipe(
            map((res) => chartJsActions.getChartDataSuccess(res)),
            catchError((err) => of(chartJsActions.getChartDataFailure(err)))
          );
      })
    )
  );

}
