import { createAction, props } from '@ngrx/store';
import { CalculatedStreamInputRecordDTO, DailyReportDTO } from 'src/app/web-api-client';

export const AddGenerateReports = createAction(
  '[KTL] Add Generate Reports',
  props<{startTime: Date | undefined}>()
);
export const AddGenerateReportsSuccess = createAction(
  '[KTL] Add Generate Reports Success',
  props<any>()
);
export const AddGenerateReportsFailure = createAction(
  '[KTL] Add Generate Reports Failure',
  props<{ message: string }>()
);

export const getDailyReport = createAction(
  '[KTL] Get Daily Reports',
  props<{ dt: Date; flare: string }>()
);
export const getDailyReportSuccess = createAction(
  '[KTL] Get Daily Reports Success',
  props<{ data: DailyReportDTO[] }>()
);
export const getDailyReportFailure = createAction(
  '[KTL] Get Daily Reports Failure',
  props<{ message: string }>()
);

export const getDailyReportStreamDetails = createAction(
  '[KTL] Get Daily Report Stream Details',
  props<{ dt: Date; streamId: number }>()
);
export const getDailyReportStreamDetailsSuccess = createAction(
  '[KTL] Get Daily Report Stream Details Success',
  props<{ data: CalculatedStreamInputRecordDTO[] }>()
);
export const getDailyReportStreamDetailsFailure = createAction(
  '[KTL] Get Daily Report Stream Details Failure',
  props<{ message: string }>()
);


