import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

type ControlType = string | number | boolean | null;

export interface SelectOption {
  title: string;
  value: ControlType;
  disabled?: boolean;
  translate?: boolean;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Input()
  public label: string | null = null;

  @Input()
  public selectWidth: string = '100%';

  @Input()
  public direction: 'vertical' | 'horizontal' = 'vertical';

  @Input()
  public options: SelectOption[] = [];

  public selectControl = new FormControl<ControlType>(null);

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: ControlType): void {
    this.selectControl.setValue(value);
    this._cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor(private _cdr: ChangeDetectorRef) {
    this.selectControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.onChange(value);
      });
  }
}
