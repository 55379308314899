import { Action, createReducer, on } from '@ngrx/store';
import * as OperatorAction from './operator.actions';
import { LPGOperatorInputDTO } from 'src/app/web-api-client';

export interface OperatorState {
  data: LPGOperatorInputDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: OperatorState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const OperatorReducer = createReducer(
  initialState,
  on(OperatorAction.AddOperator, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(OperatorAction.AddOperatorSuccess, (state, result) => ({
    data: result,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(OperatorAction.AddOperatorFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(OperatorAction.ListOperator, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(OperatorAction.ListOperatorSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(OperatorAction.ListOperatorFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(OperatorAction.EditOperator, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(OperatorAction.EditOperatorSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(OperatorAction.EditOperatorFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(OperatorAction.DeleteOperator, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(OperatorAction.DeleteOperatorSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(OperatorAction.DeleteOperatorFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
