import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OperatorsEditComponent } from 'src/app/features/tco/processeng/fd/lpg/input/operator/operators-edit/operators-edit.component';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  private data: any = {
    'Old Park - KTL': {
      Propane: [
        'E-902/21',
        'E-902/22',
        'E-902/23',
        'E-902/24',
        'E-902/25',
        'E-902/26',
        'E-902/27',
        'E-902/28',
        'E-902/29',
        'E-902/30',
        'E-902/31',
        'E-902/32',
        'E-902/33',
        'E-903',
        'E-904',
        'E-909',
        'Other',
      ],
      Butane: [
        'E-901/1',
        'E-901/2',
        'E-901/3',
        'E-901/4',
        'E-901/5',
        'E-901/6',
        'E-902/7',
        'E-903',
        'E-904',
        'E-909',
        'Other',
      ],
      'Fuel Gas': ['Other'],
    },
    'New Park - SGP': {
      Propane: ['F-3501', 'F-3502', 'F-3503', 'F-3524', 'F-3525', 'Other'],
      Butane: ['F-3511', 'F-3512', 'F-3513', 'F-3524', 'F-3525', 'Other'],
      'Fuel Gas': ['Other'],
    },
  };

  constructor(private dialog: MatDialog) {}

  openEditDialog(data: any) {
    const dialogRef = this.dialog.open(OperatorsEditComponent, {
      width: '1000px', // Adjust the width as needed
      data: data, // Pass the data to the dialog
    });
    return dialogRef.afterClosed();
  }

  getParks() {
    return Object.keys(this.data);
  }

  getGasesForPark(park: string) {
    return Object.keys(this.data[park] || {});
  }

  getOptionsForGas(park: string, gas: string) {
    return this.data[park]?.[gas] || [];
  }
}
