import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-shared-scroll-to-top',
  templateUrl: './shared-scroll-to-top.component.html',
  styleUrls: ['./shared-scroll-to-top.component.scss'],
})
export class SharedScrollToTopComponent {
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const showButtonThreshold = 200; // Show the button when the user has scrolled down 200 pixels
    const shouldShow = window.scrollY > showButtonThreshold;
    const button = document.getElementById('scrollToTopButton');
    if (button) {
      button.style.display = shouldShow ? 'block' : 'none';
    }
  }
}
