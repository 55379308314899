import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  concatMap,
  toArray,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as FuelGasActions from './ktl-fgd-fuel-gas.actions';
import * as notificationActions from '../../../../../../../state/notification/notification.action';
import { KTLFGDClient } from '../../../../../../../web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';
import { ProductionGasBalanceService } from '../services/production-gas-balance.service';

@Injectable({
  providedIn: 'root',
})
export class KTLFGDFuelGasEffects {
  constructor(
    private actions: Actions,
    private ktlfgdClient: KTLFGDClient,
    private store: Store<AppState>,
    private dataService: ProductionGasBalanceService,
  ) {}

  dailyFuelGas = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.getDailyFuelGas),
      exhaustMap((action) => {
        return this.ktlfgdClient
          .getDailyRecords(new Date(action.startTime))
          .pipe(
            map((res) => FuelGasActions.getDailyFuelGasSuccess({ data: res })),
            catchError((err) => of(FuelGasActions.getDailyFuelGasFailure(err)))
          );
      })
    )
  );

  isDailyRequestExist = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.getIsDailyRecordExist),
      exhaustMap((action) => {
        return this.ktlfgdClient
          .isDailyRecordsExist(new Date(action.startTime))
          .pipe(
            map((res) =>
              FuelGasActions.getIsDailyRecordExistSuccess({
                isDailyRecordExistdata: res,
              })
            ),
            catchError((err) =>
              of(FuelGasActions.getIsDailyRecordExistFailure(err))
            )
          );
      })
    )
  );

  // monthlyFuelGas = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(FuelGasActions.getMonthlyFuelGas),
  //     exhaustMap((action) => {
  //       return this.ktlfgdClient
  //         .getMonthlyRecords(new Date(action.startTime))
  //         .pipe(
  //           map((res) =>
  //             FuelGasActions.getMonthlyFuelGasSuccess({ data: res })
  //           ),
  //           catchError((err) =>
  //             of(FuelGasActions.getMonthlyFuelGasFailure(err))
  //           )
  //         );
  //     })
  //   )
  // );

  // addDailyManualRecord = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(FuelGasActions.AddDailyManualRecord),
  //     exhaustMap((action) => {
  //       return this.ktlfgdClient.addManualRecord(action).pipe(
  //         concatMap((res) => [
  //           FuelGasActions.AddDailyManualRecordSuccess(res),
  //           FuelGasActions.getDailyFuelGas({
  //             startTime: action.startTime,
  //           }),
  //           notificationActions.showNotification({ isSuccess: true }),
  //         ]),
  //         catchError((err) => [
  //           of(FuelGasActions.AddDailyManualRecordFailure(err)),
  //           notificationActions.showNotification({ isSuccess: false }),
  //         ])
  //       );
  //     })
  //   )
  // );

  // addMonthlyManualRecord = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(FuelGasActions.AddMonthlyManualRecord),
  //     exhaustMap((action) => {
  //       return this.ktlfgdClient.addMonthlyManualRecord(action).pipe(
  //         concatMap((res) => [
  //           FuelGasActions.AddMonthlyManualRecordSuccess(res),
  //           FuelGasActions.getDailyFuelGas({
  //             startTime: action.startTime,
  //           }),
  //           notificationActions.showNotification({ isSuccess: true }),
  //         ]),
  //         catchError((err) => [
  //           of(FuelGasActions.AddMonthlyManualRecordFailure(err)),
  //           notificationActions.showNotification({ isSuccess: false }),
  //         ])
  //       );
  //     })
  //   )
  // );

  // addOverrideManualRecord = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(FuelGasActions.AddOverrideManualRecord),
  //     exhaustMap((action) => {
  //       return this.ktlfgdClient.overrideManualRecord(action.props).pipe(
  //         concatMap((res) => [
  //           FuelGasActions.AddOverrideManualRecordSuccess(res),
  //           FuelGasActions.getDailyFuelGas({
  //             startTime: action.props.startTime,
  //           }),
  //           FuelGasActions.getIsDailyRecordExist({
  //             startTime: action.props.startTime,
  //           }),
  //           notificationActions.showNotification({ isSuccess: true }),
  //         ]),
  //         catchError((err) => [
  //           of(FuelGasActions.AddOverrideManualRecordFailure(err)),
  //           notificationActions.showNotification({ isSuccess: false }),
  //         ])
  //       );
  //     })
  //   )
  // );

  addOverrideManualRecords = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.AddOverrideManualRecords),
      exhaustMap((action) => {
        const startTime = action.date;
        const req$ = from(action.props).pipe(
          concatMap(
            prop => this.ktlfgdClient.overrideManualRecord(prop)
          )
        );

        this.store.dispatch(notificationActions.startLoading())

        return req$.pipe(
          toArray(),
          concatMap((res) => [
            FuelGasActions.AddOverrideManualRecordsSuccess(res),
            FuelGasActions.getDailyFuelGas({ startTime }),
            FuelGasActions.getIsDailyRecordExist({ startTime }),
            notificationActions.showNotification({ isSuccess: true }),
            notificationActions.stopLoading(),
          ]),
          catchError((err) => [
            of(FuelGasActions.AddOverrideManualRecordsFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
            notificationActions.stopLoading(),
          ])
        );
      }),
    )
  );

  deleteOverrideRecord = createEffect(() =>
    this.actions.pipe(
      ofType(FuelGasActions.DeleteOverrideRecord),
      exhaustMap((action) => {
        return this.ktlfgdClient
          .deleteOverrideRecord(action.streamId, action.startTime)
          .pipe(
            concatMap((res) => [
              FuelGasActions.getDailyFuelGas({
                startTime: this.dataService.selectedDateSource?.value ?? new Date(),
              }),
              FuelGasActions.DeleteOverrideRecordSuccess(res),
              notificationActions.showNotification({ isSuccess: true }),
            ]),
            catchError((err) => [
              of(FuelGasActions.DeleteOverrideRecordFailure(err)),
              notificationActions.showNotification({ isSuccess: false }),
            ])
          );
      })
    )
  );
}
