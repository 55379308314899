import { createAction, props } from '@ngrx/store';
import { LPGFlowmeterCalculateType, LPGPEInputDTO, LPGStreamCCType } from 'src/app/web-api-client';

  export const getCalculation = createAction(
    '[Flowmeter Calculation] Get Calculation',
    props<{ startTime: Date; endTime: Date; calculationType: LPGFlowmeterCalculateType; avg: number; volume: number; mw: LPGStreamCCType }>()
  );
  
  export const getCalculationSuccess = createAction(
    '[Flowmeter Calculation] Get Calculation Success',
    props<{ result: number, calculationType: LPGFlowmeterCalculateType }>()
  );
  
  export const getCalculationFailure = createAction(
    '[Flowmeter Calculation] Get Calculation Failure',
    props<{ error: any }>()
  );

  export const LPGFlowmeterCalculationInputAdd = createAction(
    '[Flowmeter Calculation] LPG Flowmeter Calculation Input Add',
    props<LPGPEInputDTO>()
  );
  export const LPGFlowmeterCalculationInputAddSuccess = createAction(
    '[Flowmeter Calculation] LPG Flowmeter Calculation Input Add Success',
    props<any>()
  );
  export const LPGFlowmeterCalculationInputAddFailure = createAction(
    '[Flowmeter Calculation] LPG Flowmeter Calculation Input Add Failure',
    props<{ message: string }>()
  );

  export const PEList = createAction(
    '[Process Engineer] PE List',
    props<{ startTime: Date }>()
  );
  export const PEListSuccess = createAction(
    '[Process Engineer] PE List Success',
    props<{ data: LPGPEInputDTO[] }>()
  );
  export const PEListFailure = createAction(
    '[Process Engineer] PE List Failure',
    props<{ message: string }>()
  );
  