import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FlowmeterCalculationComponent } from 'src/app/features/tco/processeng/fd/lpg/input/pe/flowmeter-calc/flowmeter-calculation/flowmeter-calculation.component';
import { ProcengEditComponent } from 'src/app/features/tco/processeng/fd/lpg/input/pe/process-engineer/proceng-edit/proceng-edit.component';
import { LPGStreamCCType } from 'src/app/web-api-client';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PEService {

  private dialogClosedSubject = new Subject<boolean>();

  private data: any = {
    'Old Park - KTL': {
      Propane: [
        'E-902/21',
        'E-902/22',
        'E-902/23',
        'E-902/24',
        'E-902/25',
        'E-902/26',
        'E-902/27',
        'E-902/28',
        'E-902/29',
        'E-902/30',
        'E-902/31',
        'E-902/32',
        'E-902/33',
        'E-903',
        'E-904',
        'E-909',
        'Other',
      ],
      Butane: [
        'E-901/1',
        'E-901/2',
        'E-901/3',
        'E-901/4',
        'E-901/5',
        'E-901/6',
        'E-902/7',
        'E-903',
        'E-904',
        'E-909',
        'Other',
      ],
      'Fuel Gas': ['Other'],
    },
    'New Park - SGP': {
      Propane: ['F-3501', 'F-3502', 'F-3503', 'F-3524', 'F-3525', 'Other'],
      Butane: ['F-3511', 'F-3512', 'F-3513', 'F-3524', 'F-3525', 'Other'],
      'Fuel Gas': ['Other'],
    },
  };
  private purpose: any = {
    Flared: {},
    Leakage: {},
  };
  private entryType: any = {
    'Manual Entry': {}
  };
  private flowMeterEntryType: any = {
    'Manual Entry': {},
    'Flowmeter Calc': {},
  };
  private Category: any = {
    V6: {},
    V7: {},
    V8: {},
    V9: {},
  };

  getParks() {
    return Object.keys(this.data);
  }

  getGasesForPark(park: string) {
    return Object.keys(this.data[park] || {});
  }

  getOptionsForGas(park: string, gas: string) {
    return this.data[park]?.[gas] || [];
  }

  getPurpose() {
    return Object.keys(this.purpose);
  }

  getEntryType() {
    return Object.keys(this.entryType);
  }

  getFlowMeterEntryType(){
    return Object.keys(this.flowMeterEntryType);
  }

  getMWSelectionPoints(gas: string): string[] {
    if (gas === 'Propane') {
      return [
        'Old Park average',
        'New Park average',
        'E-902',
        'F-3501',
        'F-3502',
        'F-3503',
      ];
    } else if (gas === 'Butane') {
      return [
        'Old Park average',
        'New Park average',
        'E-901',
        'F-3511',
        'F-3512',
        'F-3513',
      ];
    } else if (gas === 'Fuel Gas') {
      return ['Fuel Gas From Sales Gas Line', 'Fuel Gas From KTL (Average)'];
    } else {
      return [];
    }
  }

  getCategory() {
    return Object.keys(this.Category);
  }

  constructor(private dialog: MatDialog) {}
/*
  openEditDialog(data: any) {
    const dialogRef = this.dialog.open(ProcengEditComponent, {
      width: '1000px', // Adjust the width as needed
      data: data, // Pass the data to the dialog
    });
    return dialogRef.afterClosed();
  }
*/

openEditDialog(data: any, editComponent: Type<any>) {
  const dialogRef = this.dialog.open(editComponent, {
    width: '1000px', // Adjust the width as needed
    data: data, // Pass the data to the dialog
  });
  return dialogRef.afterClosed();
}

  openFlowmeterCalculationDialog(): Observable<boolean> {
    const dialogRef: MatDialogRef<FlowmeterCalculationComponent> = this.dialog.open(FlowmeterCalculationComponent, {
      width: '1400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogClosedSubject.next(!!result); // Emit a boolean indicating whether the dialog was closed
    });

    return this.dialogClosedSubject.asObservable();
  }

  selectedTableName: string = '';

  setSelectedTableName(tableName: string) {
    this.selectedTableName = tableName;
  }

  formatMWSelectionPoint(mw: string, gas: string): LPGStreamCCType {
    switch (mw) {
      case 'Old Park average':
        if (gas === 'Propane') return LPGStreamCCType.OldParkAvgPropane;
        return LPGStreamCCType.OldParkAvgButane;
      case 'New Park average':
        if (gas === 'Propane') return LPGStreamCCType.NewParkAvgPropane;
        return LPGStreamCCType.NewParkAvgButane;
      case 'E-902':
        return LPGStreamCCType.E902;
      case 'F-3501':
        return LPGStreamCCType.F3501;
      case 'F-3502':
        return LPGStreamCCType.F3502;
      case 'F-3503':
        return LPGStreamCCType.F3503;
      case 'E-901':
        return LPGStreamCCType.E901;
      case 'F-3511':
        return LPGStreamCCType.F3511;
      case 'F-3512':
        return LPGStreamCCType.F3512;
      case 'F-3513':
        return LPGStreamCCType.F3513;
      case 'Fuel Gas From Sales Gas Line':
        return LPGStreamCCType.Korolev;
      case 'Fuel Gas From KTL (Average)':
        return LPGStreamCCType.KTLAvg;
      default:
        return LPGStreamCCType.Nothing;
    }
  }

  formatMWSelectionPointToName(mw: number): string {
    switch (mw) {
      case LPGStreamCCType.OldParkAvgPropane:
      case LPGStreamCCType.OldParkAvgButane:
        return 'Old Park average';
      case LPGStreamCCType.NewParkAvgPropane:
      case LPGStreamCCType.NewParkAvgButane:
        return 'New Park average';
      case LPGStreamCCType.E902:
        return 'E-902';
      case LPGStreamCCType.F3501:
        return 'F-3501';
      case LPGStreamCCType.F3502:
        return 'F-3502';
      case LPGStreamCCType.F3503:
        return 'F-3503';
      case LPGStreamCCType.E901:
        return 'E-901';
      case LPGStreamCCType.F3511:
        return 'F-3511';
      case LPGStreamCCType.F3512:
        return 'F-3512';
      case LPGStreamCCType.F3513:
        return 'F-3513';
      case LPGStreamCCType.Korolev:
        return 'Fuel Gas From Sales Gas Line';
      case LPGStreamCCType.KTLAvg:
        return 'Fuel Gas From KTL (Average)';
      default:
        return '';
    }
  }
}
