import { Injectable, isDevMode } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../models/appState.model';
import { Observable, map } from 'rxjs';
import { selectUserGroups } from '../../state/auth/auth.selector';
import { UserGroups } from 'src/app/web-api-client';
import { RedirectTypes } from '../models/redirect-types.enum';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const expectedRole = route.data['expectedRole'];
    const redirectType = route.data['redirectCase']
    expectedRole.push(UserGroups.Chief_Technologist, UserGroups.Read_OnlyUser, UserGroups.SuperUser_Support)
    const jsonData = localStorage.getItem('currentUser');
    const user = jsonData ? JSON.parse(jsonData) : null;

    if (user?.some((item: any) => expectedRole.includes(item))) {
      return true;
    }

    return this.store.select(selectUserGroups).pipe(
      map((role) => {
        if (isDevMode() && role?.length === 0) { // local launch case
          return true;
        }

        if (role == null) { // API request not completed yet
          this.router.navigate(['TCO']);
          return false;
        }

        if (!role?.some((item) => expectedRole.includes(item))) {

          // redirect on special cases

          // field
          if (redirectType === RedirectTypes.Field && role.includes(UserGroups.Field_Operator)){
            this.router.navigate(['TCO/ProcessEng/FD/field/input/operator'])
            return true;
          }
          if (redirectType === RedirectTypes.Field && role.includes(UserGroups.Field_ProdPlanAnalyst)){
            this.router.navigate(['TCO/ProcessEng/FD/field/final-report/gas-balance'])
            return true;
          }

          // ktl
          if (redirectType === RedirectTypes.KTL && role.includes(UserGroups.KTL_Diagnostic)) {
            this.router.navigate(['TCO/ProcessEng/FD/KTL/Input/Diagnostician/Leakage'])
            return true;
          }
          if (redirectType === RedirectTypes.KTL && role.includes(UserGroups.KTL_Operator)) {
            this.router.navigate(['TCO/ProcessEng/FD/KTL/Input/Operator'])
            return true;
          }

          // sgp
          if (redirectType === RedirectTypes.SGP && role.includes(UserGroups.SGP_Operator)) {
            this.router.navigate(['TCO/ProcessEng/FD/SGP/Input/Operator/Flaring'])
            return true;
          }

          // lpg
          if (redirectType === RedirectTypes.LPG && role.includes(UserGroups.LPG_Operator)) {
            this.router.navigate(['TCO/ProcessEng/FD/LPG/Input/Operator'])
            return true;
          }
          if (redirectType === RedirectTypes.LPG && role.includes(UserGroups.LPG_ProdPlanAnalyst)) {
            this.router.navigate(['TCO/ProcessEng/FD/LPG/Input/Analyst'])
            return true;
          }


          this.router.navigate(['not-authorized'], {
            queryParams: { redirectBack: route?.routeConfig?.path ?? null },
          });
          return false;
        }

        return true;
      })
    );
  }
}
