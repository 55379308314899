<div class="row" [formGroup]="gasParkForm">
  <div class="col-md-3">
    <div class="form-group">
      <label for="ParkSelectInput">ТП СУГ / LPG Park</label>
      <select
        id="ParkSelectInput"
        [(ngModel)]="selectedPark"
        (change)="onParkChange()"
        class="form-select"
        formControlName="lpgPark"
      >
        <option [value]="null" [disabled]="!selectedPark">ТП СУГ / LPG Park</option>
        <option *ngFor="let park of parks" [value]="park">{{ park }}</option>
      </select>
    </div>
    <div
      *ngIf="
        gasParkForm.get('lpgPark')?.hasError('required') &&
        gasParkForm.get('lpgPark')?.touched
      "
      class="text-danger"
    >
      LPG Park is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="gasSelect">Тип газа / Gas Type</label>
      <select
        id="gasSelect"
        [(ngModel)]="selectedGas"
        (change)="onGasChange()"
        class="form-select"
        formControlName="gasType"
      >
        <option value="null" [disabled]="!selectedGas">Тип газа / Gas Type</option>
        <option *ngFor="let gas of gases" [value]="gas">{{ gas }}</option>
      </select>
    </div>
    <div
      *ngIf="
        gasParkForm.get('gasType')?.hasError('required') &&
        gasParkForm.get('gasType')?.touched
      "
      class="text-danger"
    >
      Gas Type is required.
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="optionSelect">Буллит / Bullit</label>
      <select
        id="optionSelect"
        [(ngModel)]="selectedOptions"
        class="form-select"
        (change)="onOptionChange()"
        formControlName="bullet"
      >
        <option value="null" [disabled]="!selectedOptions">Буллит / Bullit</option>
        <option *ngFor="let option of options" [value]="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div
      *ngIf="
        gasParkForm.get('bullet')?.hasError('required') &&
        gasParkForm.get('bullet')?.touched
      "
      class="text-danger"
    >
      Bullit is required.
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group" *ngIf="isOtherSelected">
      <label for="otherInput">Другое / Other</label>
      <input
        type="text"
        id="otherInput"
        [(ngModel)]="otherInputValue"
        class="form-control"
        formControlName="other"
      />
    </div>
  </div>
</div>
