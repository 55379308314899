import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../material/material.module';

import { ChartjsComponent } from './components/chartjs/chartjs.component';
import { SharedButtonComponent } from './components/shared-button/shared-button.component';
import { SharedAComponent } from './components/shared-a/shared-a.component';
import { TabComponent } from './components/shared-Tab/tab.component';
import { DynamicTitleComponent } from './components/shared-dynamic-title/dynamic-title.component';
import { SharedHeaderComponent } from './components/shared-header/shared-header.component';
import { SharedDropdownGasParkComponent } from './components/shared-dropdown-gas-park/shared-dropdown-gas-park.component';
import { SharedDropdownPeComponent } from './components/shared-dropdown-pe/shared-dropdown-pe.component';
import { SharedNotificationComponent } from './components/shared-notification/shared-notification.component';
import { SharedLoadingSpinnerComponent } from './components/shared-loading-spinner/shared-loading-spinner.component';
import { SharedDeleteConfirmationComponent } from './components/shared-delete-confirmation/shared-delete-confirmation.component';
import { SharedErrorPageComponent } from './shared-error-page/shared-error-page.component';
import { SharedScrollToTopComponent } from './components/shared-scroll-to-top/shared-scroll-to-top.component';
import { SelectComponent } from './components/select/select.component';
import { RadioToggleComponent } from './components/radio-toggle/radio-toggle.component';
import { SidebarComponent } from './components/shared-sidebar/sidebar.component';
import { HintErrorComponent } from './components/hint-error/hint-error.component';
import { SharedSaveConfirmationComponent } from './components/shared-save-confirmation/shared-save-confirmation.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SharedButtonComponent,
    SharedAComponent,
    TabComponent,
    DynamicTitleComponent,
    SharedHeaderComponent,
    SharedDropdownGasParkComponent,
    ChartjsComponent,
    SharedDropdownPeComponent,
    SharedNotificationComponent,
    SharedLoadingSpinnerComponent,
    SharedDeleteConfirmationComponent,
    SharedErrorPageComponent,
    SharedScrollToTopComponent,
    SelectComponent,
    RadioToggleComponent,
    SidebarComponent,
    HintErrorComponent,
    SharedSaveConfirmationComponent,
    SidebarComponent,
    CheckboxComponent,
  ],
  imports: [RouterModule, MaterialModule, ReactiveFormsModule, TranslateModule],
  exports: [
    SharedButtonComponent,
    SharedAComponent,
    TabComponent,
    DynamicTitleComponent,
    SharedDropdownGasParkComponent,
    ChartjsComponent,
    SharedDropdownPeComponent,
    SharedNotificationComponent,
    SharedLoadingSpinnerComponent,
    SharedDeleteConfirmationComponent,
    SharedErrorPageComponent,
    SharedScrollToTopComponent,
    SelectComponent,
    RadioToggleComponent,
    SidebarComponent,
    HintErrorComponent,
    SharedSaveConfirmationComponent,
    SidebarComponent,
    CheckboxComponent,
  ],
})
export class SharedModule {}
