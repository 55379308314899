import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  concatMap,
  withLatestFrom,
  toArray,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as GURActions from './gas-usage-report.actions';
import * as notificationActions from '../../../../../../../state/notification/notification.action';
import { SGPFGDClient } from '../../../../../../../web-api-client';
import { SGPFGDCalculatedDTO } from 'src/app/web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';


@Injectable({
  providedIn: 'root',
})
export class GUREffects {
  constructor(
    private actions: Actions,
    private sgpfgdClient: SGPFGDClient,
    private store: Store<AppState>
  ) {}

  getGUR = createEffect(() =>
    this.actions.pipe(
      ofType(GURActions.getGUR),
      exhaustMap((action) => {
        return this.sgpfgdClient
          .getGUR(new Date(action.startTime))
          .pipe(
            map((res) =>
              GURActions.getGURSuccess({ data: res })
            ),
            catchError((err) =>
              of(GURActions.getGURFailure(err))
            )
          );
      })
    )
  );

  ManualRecord = createEffect(() =>
    this.actions.pipe(
      ofType(GURActions.FGDSGPGURManualRecord),
      exhaustMap((action) => {
        return this.sgpfgdClient.postGUR(action.props).pipe(
          concatMap((res) => [
            GURActions.FGDSGPGURManualRecordSuccess(res),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(GURActions.FGDSGPGURManualRecordFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

}
