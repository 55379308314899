import { Action, createReducer, on } from '@ngrx/store';
import * as FlaringActions from './flaring.actions';
import { ChemicalCompoundsDTO, ChemicalCompoundsEquipmentDTO, KTLSGPStreamDTO } from 'src/app/web-api-client';

export interface FlaringState{
  data: ChemicalCompoundsDTO[] | null ;
  dataEquipment: ChemicalCompoundsEquipmentDTO[] | null ;
  streams: KTLSGPStreamDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: FlaringState = {
  data: null,
  dataEquipment: null,
  streams: null,
  result: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const FlaringReducer = createReducer(
  initialState,
  on(FlaringActions.getChemicalCompound, state => ({
    ...state,
    isLoading: true,
  })),
  on(FlaringActions.getChemicalCompoundSuccess, (state, result) => ({
    ...state,
     data: result.data,
     dataEquipment: null,
     streams: null,
     result: null,
     isLoading: false,
     isLoadingSuccess: true,
     isLoadingFailure: false,
  })),
  on(FlaringActions.getChemicalCompoundFailure, (state, result) => ({
    ...state,
    result: result,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(FlaringActions.getChemicalCompoundsEquipment, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(FlaringActions.getChemicalCompoundsEquipmentSuccess, (state, result) => ({
    ...state,
    data: null,
    dataEquipment: result.dataEquipment,
    streams:null,
    result: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(FlaringActions.getChemicalCompoundsEquipmentFailure, (state, result) => ({
    ...state,
    result: result,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(FlaringActions.getStreamsName, state => ({
    ...state,
    isLoading: true,
  })),
  on(FlaringActions.getStreamsNameSuccess, (state, result) => ({
    ...state,
     data: null,
     dataEquipment: null,
     streams: result.streams,
     result: null,
     isLoading: false,
     isLoadingSuccess: true,
     isLoadingFailure: false,
  })),
  on(FlaringActions.getStreamsNameFailure, (state, result) => ({
    ...state,
    result: result,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(FlaringActions.AddManualRecord, (state, payload) => ({
    ...state,
    isLoading: true,
  })),
  on(FlaringActions.AddManualRecordSuccess, (state, payload) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
  })),
  on(FlaringActions.AddManualRecordFailure, (state, payload) => ({
    ...state,
    result: payload.message,
    isLoading: false,
    isLoadingFailure: true,
  })),
);