import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import {
  AnalystReportType,
  BulletsReportType,
  LPGDataReportType,
} from 'src/app/core/models/lpgDataReport.model';
import { CommonService } from 'src/app/core/services/common/common.service';
import { LPGData } from 'src/app/state/lpg/lpg-data/lpg-data.actions';
import { LPGDataSelector } from 'src/app/state/lpg/lpg-data/lpg-data.selector';
import {
  LPGApproveByChef,
  LPGApproveStatus,
} from 'src/app/state/lpg/approve/approve.actions';
import { ApproveStatusResponse } from '../../../../../../../state/lpg/approve/approve.selector';
import { ExportService } from 'src/app/core/services/export/export.service';

@Component({
  selector: 'app-lpg-data',
  templateUrl: './lpg-data.component.html',
  styleUrls: ['./lpg-data.component.scss'],
})
export class LPGDataComponent implements OnInit {
  month: string = '';
  approveStatus: string = '';
  data1?: LPGDataReportType;
  data2?: AnalystReportType;
  data3?: BulletsReportType;

  constructor(
    private store: Store<AppState>,
    private commonService: CommonService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.month = this.commonService.formatDate(new Date(), 'YYYY-MM');
    this.store.dispatch(LPGData({ startTime: new Date() }));
    this.store.select(LPGDataSelector).subscribe((res) => {
      if (res) {
        this.data1 = res.data1;
        this.data2 = res.data2;
        this.data3 = res.data3;
      }
    });
    this.store.dispatch(LPGApproveStatus({ startTime: new Date() }));
    this.store.select(ApproveStatusResponse).subscribe((res) => {
      this.approveStatus = res;
    });
  }

  countItem(number: number) {
    return new Array(number);
  }

  formatDate(date: Date): string {
    return this.commonService.formatDate(date, 'MMM-YY');
  }

  monthChange() {
    this.store.dispatch(LPGData({ startTime: new Date(this.month) }));
    this.store.dispatch(LPGApproveStatus({ startTime: new Date(this.month) }));
  }

  exportToExcel() {
    this.exportService.exportLPGDataReports(new Date(this.month));
  }

  getLoading(): boolean {
    return this.exportService.getIsLoading();
  }

  Approve() {
    this.store.dispatch(LPGApproveByChef({ startTime: new Date(this.month) }));
  }

  formatNumberCC(value: any): string {
    return this.commonService.formatNumberCC(value);
  }
  formatNumberMV(value: any): string {
    return this.commonService.formatNumberMV(value);
  }
}
