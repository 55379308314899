import { createAction, props } from '@ngrx/store';
import { LPGPEInputDTO } from 'src/app/web-api-client';

export const PEList = createAction(
  '[Process Engineer] PE List',
  props<{ startTime: Date }>()
);
export const PEListSuccess = createAction(
  '[Process Engineer] PE List Success',
  props<{ data: LPGPEInputDTO[] }>()
);
export const PEListFailure = createAction(
  '[Process Engineer] PE List Failure',
  props<{ message: string }>()
);

export const PEAdd = createAction(
  '[Process Engineer] PE Add',
  props<LPGPEInputDTO>()
);
export const PEAddSuccess = createAction(
  '[Process Engineer] PE Add Success',
  props<any>()
);
export const PEAddFailure = createAction(
  '[Process Engineer] PE Add Failure',
  props<{ message: string }>()
);

export const PEEdit = createAction(
  '[Process Engineer] PE Edit',
  props<LPGPEInputDTO>()
);
export const PEEditSuccess = createAction(
  '[Process Engineer] PE Edit Success',
  props<any>()
);
export const PEEditFailure = createAction(
  '[Process Engineer] PE Edit Failure',
  props<{ message: string }>()
);

export const PEDelete = createAction(
  '[Process Engineer] PE Delete',
  props<{ id: number; startTime: Date }>()
);
export const PEDeleteSuccess = createAction(
  '[Process Engineer] PE Delete Success',
  props<any>()
);
export const PEDeleteFailure = createAction(
  '[Process Engineer] PE Delete Failure',
  props<{ message: string }>()
);
