import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'LPG',
        loadChildren: () => import('./lpg/lpg.module').then((m) => m.LPGModule),
      },
      {
        path: 'KTLSGP',
        loadChildren: () =>
          import('./ktl-sgp/ktl-sgp.module').then((m) => m.KtlSgpModule),
      },
      {
        path: 'field',
        loadChildren: () =>
          import('./field/field.module').then((m) => m.FieldModule),
      },
      {
        path: 'SGI',
        loadChildren: () => import('./sgi/sgi.module').then((m) => m.SgiModule),
      },
      {
        path: 'KTL',
        loadChildren: () => import('./ktl/ktl.module').then((m) => m.FDKTLModule),
      },
      {
        path: 'SGP',
        loadChildren: () => import('./sgp/sgp.module').then((m) => m.FDSGPModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FDRoutingModule {}
