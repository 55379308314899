import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  mergeMap,
  concatMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as notificationActions from '../../notification/notification.action';
import { LPGClient } from '../../../web-api-client';
import * as ApproveActions from './approve.actions';

@Injectable({
  providedIn: 'root',
})
export class ApproveEffects {

  constructor(private actions: Actions, private lpgClient: LPGClient) { }

  approveByChef$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApproveActions.LPGApproveByChef),
      exhaustMap((action) =>
        this.lpgClient.approveByChef(action.startTime).pipe(
          concatMap((fileResponse) => [
            ApproveActions.LPGApproveByChefSuccess({ fileResponse }),
            ApproveActions.LPGApproveStatus({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true })
          ]),
          catchError((error) => [
            of(ApproveActions.LPGApproveByChefFailure(error)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        )
      )
    )
  );


  approveByChefStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApproveActions.LPGApproveStatus),
      mergeMap((action) =>
        this.lpgClient.latestApprove(action.startTime).pipe(
          map((fileResponse) =>
            ApproveActions.LPGApproveStatusSuccess({ message: fileResponse })
          ),
          catchError((error) =>
            of(ApproveActions.LPGApproveStatusFailure(error))
          )
        )
      )
    )
  );

}

