import { Injectable } from '@angular/core';
import moment, { unitOfTime } from 'moment';
import {
  BehaviorSubject,
  Observable,
  fromEvent,
  map,
} from 'rxjs';
import {
  LPGGasType,
  PermitTargetInputCategory,
  PermitTargetInputType,
  StreamStatuses,
} from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public static MONTH_FORMAT = 'YYYY-MM';
  public static DATE_FORMAT = 'YYYY-MM-DD';
  public static DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
  public static DATE_VIEW_FORMAT = 'DD-MM-YYYY';
  public static DATE_NEW_VIEW_FORMAT = 'DD/MM/YYYY';
  public static DATETIME_VIEW_FORMAT = 'DD-MMM-YYYY HH:mm';
  public static DATETIME_VIEW_NEW_FORMAT = 'DD/MM/YYYY HH:mm';

  constructor() {}

  convertToDateTimeZ(date: string) {
    const newDate = new Date(date);
    return `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${newDate
      .getDate()
      .toString()
      .padStart(2, '0')}T${newDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}`;
  }

  convertDateToMonth(date: Date) {
    return moment(date).format('YYYY-MM');
  }

  manipulateDate(
    date: Date | string,
    options?: {
      amount?: number;
      unit?: unitOfTime.DurationConstructor;
      time?: { hours: number; minutes: number; seconds?: number };
      isUTC?: boolean;
    }
  ) {
    let newDate = options?.isUTC ? moment.utc(date) : moment(date);
    if (options) {
      if (options.unit) {
        newDate = newDate.add(options.amount, options.unit);
      }
      if (options.time) {
        newDate.set('hour', options.time.hours);
        newDate.set('minute', options.time.minutes);
        if (options.time.seconds != null) {
          newDate.set('second', options.time.seconds);
        }
      }
    }
    return newDate.toDate();
  }

  formatDate(date: Date | string, format: string) {
    let newDate = moment(date);
    return newDate.format(format);
  }

  formatDateWithShift(value?: Date, isLocal = false, format = CommonService.DATETIME_VIEW_FORMAT): string | null {
    if (value && moment(value).isValid()) {
      const date = isLocal
        ? moment(value.toLocaleString()).toDate()
        : moment.utc(value.toLocaleString()).toDate();

      return moment(date).local().format(format);
    }

    return null;    
  }

  convertLocalTimeAsUTC(value?: Date, format = CommonService.DATETIME_VIEW_FORMAT) {
    return value ? moment.utc(value).format(format) : null;
  }

  getCategoryName(category: PermitTargetInputCategory | undefined): string {
    switch (category) {
      case PermitTargetInputCategory.V6:
        return 'V6';
      case PermitTargetInputCategory.V7:
        return 'V7';
      case PermitTargetInputCategory.V8:
        return 'V8';
      case PermitTargetInputCategory.V9:
        return 'V9';
      default:
        return '';
    }
  }

  getGasTypeName(type: number): string {
    switch (type) {
      case LPGGasType.Dry:
        return 'Fuel Gas';
      case LPGGasType.Propane:
        return 'Propane';
      case LPGGasType.Butane:
        return 'Butane';
      default:
        return '';
    }
  }

  public getStreamStatusColor(
    status: StreamStatuses
  ): Record<string, string> | null {
    switch (status) {
      case StreamStatuses.Issue:
        return {
          background: '#F44336',
        };
      case StreamStatuses.Modified:
        return {
          background: '#4CAF50',
        };
      default:
        return null;
    }
  }

  getGasTypeTargetPermitName(type: number): string {
    switch (type) {
      case LPGGasType.Dry:
        return 'Dry Gas';
      case LPGGasType.Propane:
        return 'Propane';
      case LPGGasType.Butane:
        return 'Butane';
      default:
        return '';
    }
  }

  getLimitName(type: number): string {
    switch (type) {
      case PermitTargetInputType.Permit:
        return 'Permit';
      case PermitTargetInputType.Target:
        return 'Target';
      default:
        return '';
    }
  }

  formatNumberCC(value: any): string {
    // formate numbers for Chemical components
    if (typeof value !== 'number') {
      return value; // If not a number, return the original value as string
    }

    const formattedValue =
      value % 1 === 0
        ? value.toLocaleString('en-US')
        : value.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });

    return formattedValue.replace(/(\.[0-9]*[1-9])0+$/, '$1');
  }
  /*
  formatNumberMV(value: any): string {  // formate numbers for Mass and Value
    if (typeof value !== 'number') {
      return value; // If not a number, return the original value as string
    }

    let formattedValue: string;

    if (value % 1 === 0) {
      formattedValue = value.toLocaleString('en-US');
    } else {
      formattedValue = value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return formattedValue.replace(/(\.[0-9]*[1-9])0+$/, '$1');
  }*/

  formatNumberMV(value: any): string {
    if (typeof value !== 'number') {
      return value.toString();
    }

    let formattedValue: string;

    if (Number.isInteger(value)) {
      formattedValue = value.toLocaleString('en-US') + '.00';
    } else {
      formattedValue = value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return formattedValue;
  }

  formatNumber(value: any, digits = 2): string {
    if (typeof value !== 'number') {
      return value.toString();
    }

    return value.toLocaleString('en-US', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  }

  /*
  formatNumberMV(value: any): string {
    // Format numbers for Mass and Value
    if (typeof value !== 'number') {
      return value.toString(); // If not a number, return the original value as string
    }
  
    // Convert scientific notation to a regular number
    const convertedValue = Number(value);
  
    // Round the value to 2 decimal places
    const roundedValue = Number(convertedValue.toFixed(2));
  
    let formattedValue: string;
  
    if (Number.isInteger(roundedValue) && value.toString().indexOf('e') === -1) {
      // For whole numbers without 'e', explicitly add '.00' to ensure two decimal places
      formattedValue = roundedValue.toLocaleString('en-US') + '.00';
    } else {
      formattedValue = roundedValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  
    return formattedValue;
  }
  */

  private selectedRowSubject = new BehaviorSubject<any>(null);
  selectedRow$: Observable<any> = this.selectedRowSubject.asObservable();

  setSelectedRow(row: any) {
    this.selectedRowSubject.next(row);
  }

  closePage() {
    window.close();
  }

  getClipboardDataFromDocument(): Observable<string> {
    return fromEvent<ClipboardEvent>(document, 'paste').pipe(
      map((event: ClipboardEvent) => {
        if (event.clipboardData) {
          return event.clipboardData.getData('text');
        } else if ((window as any).clipboardData) {
          return (window as any).clipboardData.getData('Text');
        } else {
          console.error('Clipboard data not available.');
          return '';
        }
      }),
      map(data => {
        console.log('pipe data', data);
        
        return data
      })
    );
  }

  
  getClipboardData(event: ClipboardEvent): number[] {
    const data = event.clipboardData?.getData('text');
    let values: number[] = [];

    if (data) {
      if (data.includes(',')) {
        values = data?.split(',').map((item) => Number(item));
      }

      // vertical excel data
      const matchVertical = /\r|\n/.exec(data);
      if (matchVertical) {
        values = data
          .split('\r\n')
          .filter((item) => item?.length)
          .map((value) => Number(value.replace(/,/g, '.')));
      }

      // horizontal excel data
      const matchHorizontal = /\t/.exec(data);
      if (matchHorizontal) {
        values = data
          .split('\t')
          .filter((item) => item?.length)
          .map((value) => Number(value.replace(/,/g, '.')));
      }
    }

    return values;
  }

  isOperatorLocked(date: Date): boolean {
    const recordDate = moment(date);
    const today = moment();
    const month = today.month();
    const year = today.year();
    const start = moment(`${month + 1}/2/${year} 14:00`, 'MM/DD/YYYY h:mm');

    console.log('recordDate: ', recordDate);
    console.log('today:', today);
    console.log('start', start);

    if (recordDate.isBefore(start) && today.isAfter(start)) return false;
    return true;
  }
}
