<div class="row map">
  <div class="col">
    <div class="Map">
      <mat-card>
        <a [routerLink]="['/TCO']">Map View</a>
      </mat-card>
    </div>
  </div>
</div>

<div class="row" style="margin-top: 50px;">
  <div class="col" *ngFor="let group of arr" style="margin-bottom: 20px;">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h1>{{ group.name }}</h1>
        </mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ul style="margin-top: 20px; margin-bottom: 20px;">
          <li *ngFor="let item of group.items" style="background-color: #f8f8f8; border-radius: 10px 10px 10px 10px; padding-top: 10px; padding-bottom: 10px; margin-right: 30px;">
            <div class="hover-container">
              <span class="cursor-pointer caret" *ngIf="item.expand" (click)="item.expand = !item.expand"><div class="icon-container"><mat-icon>expand_more</mat-icon></div></span>
              <span class="cursor-pointer caret" *ngIf="!item.expand" (click)="item.expand = !item.expand"><div class="icon-container"><mat-icon>chevron_right</mat-icon></div></span>
              <span class="cursor-pointer" (click)="item.expand = !item.expand">
                <a mat-button [routerLink]="['/TCO/ProcessEng/' + item.route]" [disabled]="item.disabled" [ngStyle]="item.disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}">{{ item.name }}</a></span>
            </div>
            <div *ngIf="item.childs">
                <ul [class.d-none]="!item.expand">
                  <li *ngFor="let child of item.childs; index as k">
                    <div class="hover-container">
                      <span class="cursor-pointer caret" *ngIf="child.expand" (click)="child.expand = !child.expand"><div class="icon-container"><mat-icon>expand_more</mat-icon></div></span>
                      <span class="cursor-pointer caret" *ngIf="!child.expand" (click)="child.expand = !child.expand"><div class="icon-container"><mat-icon>chevron_right</mat-icon></div></span>
                      <span class="cursor-pointer" (click)="child.expand = !child.expand">
                        <a mat-button [routerLink]="['/TCO/ProcessEng/' + child.route]" [disabled]="child.disabled" [ngStyle]="child.disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}" > 
                          {{child.name }}
                        </a>
                      </span>
                    </div>
                    <div *ngIf="child.schilds">
                      <ul [class.d-none]="!child.expand">
                        <li *ngFor="let ch of child.schilds; index as k">
                          <div class="hover-container">
                          <span class="cursor-pointer caret" *ngIf="ch.expand" (click)="ch.expand = !ch.expand"><div class="icon-container"><mat-icon>expand_more</mat-icon></div></span>
                          <span class="cursor-pointer caret" *ngIf="!ch.expand" (click)="ch.expand = !ch.expand"><div class="icon-container"><mat-icon>chevron_right</mat-icon></div></span>
                          <span class="cursor-pointer" (click)="ch.expand = !ch.expand">
                            <a mat-button [routerLink]="['/TCO/ProcessEng/' + ch.route]" [disabled]="ch.disabled" [ngStyle]="ch.disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}" >
                              {{ ch.name }}
                            </a></span>
                          </div>
                          <div *ngIf="ch.tchilds">
                            <ul [class.d-none]="!ch.expand">
                              <li *ngFor="let tch of ch.tchilds; index as k">
                                <div class="hover-container">
                                <span class="cursor-pointer caret" *ngIf="tch.expand" (click)="tch.expand = !tch.expand"><div class="icon-container"><mat-icon>expand_more</mat-icon></div></span>
                                <span class="cursor-pointer caret" *ngIf="!tch.expand" (click)="tch.expand = !tch.expand"><div class="icon-container"><mat-icon>chevron_right</mat-icon></div></span>
                                <span class="cursor-pointer" (click)="tch.expand = !tch.expand">
                                  <a mat-button [routerLink]="['/TCO/ProcessEng/' + tch.route]" [disabled]="tch.disabled" [ngStyle]="tch.disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}" >
                                    {{ tch.name }}
                                  </a></span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
            </div>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>