import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';

@Component({
  selector: 'app-tco',
  templateUrl: './tco.component.html',
  styleUrls: ['./tco.component.scss'],
})
export class TCOComponent {
  arr = [
    {
      id: 'FGD',
      name: 'FGD',
      route: '',
      items: [
        {
          id: 'FGD-KTL',
          name: 'KTL',
          expand: true,
          disabled: true,
          route: '',
          childs: [
            {
              id: 'FGD-KTL-PRODUCTION-GAS-BALANCE',
              name: 'FUEL GAS DASHBOARD',
              disabled: false,
              expand: false,
              route: 'FGD/KTL/ProductionGasBalance',
              schilds: [],
            },
          ],
        },
        {
          id: 'FGD-SGP',
          name: 'SGP',
          expand: true,
          disabled: true,
          route: '',
          childs: [
            {
              id: 'FGD-SGP-FUEL-GAS-DASHBOARD',
              name: 'FUEL GAS DASHBOARD',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/FuelGasDashboard',
              schilds: [],
            },
            {
              id: 'FGD-SGP-GAS-USAGE-REPORT',
              name: 'GAS USAGE REPORT',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/GasUsageReport',
              schilds: [],
            },
          ],
        },
        {
          id: 'FGD-FIELD',
          name: 'FIELD',
          route: '',
          expand: false,
          disabled: true,
          childs: [],
        },
        {
          id: 'FGD-IUS',
          name: 'IUS',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-IUS-INPUT',
              name: 'INPUT',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FGD-IUS-INPUT-BOILERHOUSEDATA',
                  name: 'BOILER HOUSE DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/Input/boilers-motohours-data',
                },
                {
                  id: 'FGD-IUS-INPUT-HEATERSDATA',
                  name: 'HEATERS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/Input/heaters-motohours-data',
                },
                {
                  id: 'FGD-IUS-INPUT-METERSDATA',
                  name: 'METERS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/Input/meters-data',
                },
              ],
            },
            {
              id: 'FGD-IUS-CALCULATIONS',
              name: 'FUEL GAS USAGE',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FGD-IUS-FGU-BOILER',
                  name: 'BOILERS MOTOHOURS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/calculations/boilers-motohours-data',
                },
                {
                  id: 'FGD-IUS-FGU-HEATER',
                  name: 'HEATERS MOTOHOURS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/calculations/heaters-motohours-data',
                },
              ],
            },
            {
              id: 'FGD-IUS-MONTHLY-REPORT',
              name: 'MONTHLY-REPORT',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FGD-IUS-MONTHLY-REPORT-MONTHLY-CALC',
                  name: 'MONTHLY CALC',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/MonthlyReport/monthly-calc',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'FD',
      name: 'FD',
      route: '',
      items: [
        {
          id: 'FD-KTL',
          name: 'KTL',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-KTL-INPUT',
              name: 'INPUT',
              disabled: true,
              route: '',
              expand: true,
              schilds: [
                {
                  id: 'FD-KTL-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-FLARING',
                      name: 'FLARING',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Flaring',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-MW',
                      name: 'MW',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/MW',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-LEAKAGE',
                      name: 'LEAKAGE',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Leakages',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-CONSTANTS',
                      name: 'CONSTANTS',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Constants',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-PERMITS',
                      name: 'PERMITS',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-EQUIPMENT',
                      name: 'EQUIPMENT',
                      disabled: true,
                      expand: false,
                      route: '',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-DIAGNOSTICIAN',
                      name: 'DIAGNOSTICIAN',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Diagnostician',
                    },
                  ],
                },
                {
                  id: 'FD-KTL-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: true,
                  expand: false,
                  route: '/FD/KTL/Input/Operator',
                },
                {
                  id: 'FD-KTL-INPUT-DIAGNOSTICIAN',
                  name: 'DIAGNOSTICIAN',
                  disabled: true,
                  expand: false,
                  route: '/FD/KTL/Input/Diagnostician',
                },
              ],
            },
            {
              id: 'FD-KTL-DAILY-REPORT',
              name: 'DAILY REPORT',
              disabled: false,
              route: '/FD/KTL/DailyReport',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-KTL-MONTHLY-REPORT',
              name: 'MONTHLY REPORT',
              disabled: false,
              route: '/FD/KTL/MonthlyReport',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-KTL-SCHEMA',
              name: 'SCHEMA',
              disabled: false,
              route: '/FD/KTL/Schema',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-KTL-CHEMICAL-COMPOSITION',
              name: 'CHEMICAL COMPOSITION',
              disabled: false,
              route: '/FD/KTL/cc',
              expand: false,
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-SGP',
          name: 'SGP',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-SGP-INPUT',
              name: 'INPUT',
              disabled: true,
              route: '',
              expand: true,
              schilds: [
                {
                  id: 'FD-SGP-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-FLARING',
                      name: 'FLARING',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Flaring',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-MW',
                      name: 'MW',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/MW',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-LEAKAGE',
                      name: 'LEAKAGE',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Leakages',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-CONSTANTS',
                      name: 'CONSTANTS',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Constants',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-PERMITS',
                      name: 'PERMITS',
                      disabled: true,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-EQUIPMENT',
                      name: 'EQUIPMENT',
                      disabled: true,
                      expand: false,
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-SGP-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-SGP-INPUT-DIAGNOSTICIAN',
                  name: 'DIAGNOSTICIAN',
                  disabled: true,
                  expand: false,
                  route: '',
                },
              ],
            },
            {
              id: 'FD-SGP-DAILY-REPORT',
              name: 'DAILY REPORT',
              disabled: false,
              route: '/FD/SGP/DailyReport',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-SGP-MONTHLY-REPORT',
              name: 'MONTHLY REPORT',
              disabled: true,
              route: '',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-SGP-SCHEMA',
              name: 'SCHEMA',
              disabled: true,
              route: '',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-SGP-CHEMICAL-COMPOSITION',
              name: 'CHEMICAL COMPOSITION',
              disabled: true,
              route: '',
              expand: false,
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-SGI',
          name: 'SGI',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-SGI-FLARE-REPORT',
              name: 'FLARE REPORT',
              disabled: false,
              expand: false,
              route: 'FD/SGI/flare-report',
              schilds: [],
            },
            {
              id: 'FD-SGI-TARGET-PERMIT',
              name: 'TARGET PERMIT',
              disabled: false,
              expand: false,
              route: 'FD/SGI/target-permit',
              schilds: [],
            },
            {
              id: 'FD-SGI-CC',
              name: 'CHEMICAL COMPOSITION',
              disabled: false,
              expand: false,
              route: 'FD/SGI/cc',
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-LPG',
          name: 'LPG',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-LPG-INPUT',
              name: 'INPUT',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FD-LPG-INPUT-TARGETPERMIT',
                  name: 'TARGET/PERMIT',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/TargetPermit',
                },
                {
                  id: 'FD-LPG-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Operator',
                },
                {
                  id: 'FD-LPG-INPUT-ANALYST',
                  name: 'ANALYST',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Analyst',
                },
                {
                  id: 'FD-LPG-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/PE/ProcessEngineer',
                },
              ],
            },
            {
              id: 'FD-LPG-FUEL-GAS-FLARING',
              name: 'FUEL GAS FLARING',
              disabled: false,
              expand: false,
              route: 'FD/LPG/FuelGasFlaring',
              schilds: [],
            },
            {
              id: 'FD-LPG-CC',
              name: 'CHEMICAL COMPOSITIONS',
              disabled: false,
              expand: false,
              route: 'FD/LPG/CC',
              schilds: [],
            },
            {
              id: 'FD-LPG-FINAL-REPORT',
              name: 'FINAL REPORT',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FD-LPG-FINAL-REPORT-LPG-DATA',
                  name: 'LPG DATA',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/LPGData',
                },
                {
                  id: 'FD-LPG-FINAL-REPORT-FLARING-REPORT',
                  name: 'FLARING REPORT',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/FlaringReport',
                },
              ],
            },
          ],
        },
        {
          id: 'FD-FIELD',
          name: 'FIELD',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-FIELD-INPUT',
              name: 'INPUT',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FD-FIELD-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-FIELD-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-FIELD-INPUT-METRICS',
                  name: 'METRICS',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-FIELD-INPUT-PERMITS',
                  name: 'PERMITS',
                  disabled: true,
                  expand: false,
                  route: '',
                },
              ],
            },
            {
              id: 'FD-FIELD-CC',
              name: 'CHEMICAL COMPOSITION',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FD-FIELD-CC-FUELGAS',
                  name: 'FUEL GAS',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-FIELD-CC-RESERVOIRGAS',
                  name: 'RESERVOIR GAS',
                  disabled: true,
                  expand: false,
                  route: '',
                },
              ],
            },
            {
              id: 'FD-FIELD-FIELD-CALC',
              name: 'FIELD CALC',
              disabled: false,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FD-FIELD-FIELD-CALC-DRY-GAS',
                  name: 'DRY GAS',
                  disabled: false,
                  expand: false,
                  route: 'FD/field/field-calc/dry-gas',
                },
                {
                  id: 'FD-FIELD-FIELD-CALC-RESERVOIR-GAS',
                  name: 'RESERVOIR GAS',
                  disabled: false,
                  expand: false,
                  route: 'FD/field/field-calc/reservoir-gas',
                },
                {
                  id: 'FD-FIELD-FIELD-CALC-PURGE-AND-PILOT',
                  name: 'PURGE AND PILOT',
                  disabled: false,
                  expand: false,
                  route: 'FD/field/field-calc/purge-n-pilot',
                },
              ],
            },
            {
              id: 'FD-FIELD-FINAL-REPORT',
              name: 'FINAL REPORT',
              disabled: true,
              expand: true,
              route: '',
              schilds: [
                {
                  id: 'FD-FIELD-FINAL-REPORT-GAS-BALANCE',
                  name: 'GAS BALANCE',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-FIELD-FINAL-REPORT-FLARING-REPORT',
                  name: 'FLARING REPORT',
                  disabled: true,
                  expand: false,
                  route: '',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'SO2-EMISSIONS',
      name: 'SO2 EMISSIONS',
      route: '',
      items: [
        {
          id: 'SO2-EMISSIONS-KTLSGP',
          name: 'SO2 EMISSIONS',
          disabled: false,
          route: 'FD/KTLSGP/So2Emissions',
          expand: true,
          childs: [
            {
              id: 'SO2EMISSIONS-KTLSGP-PROJECTEDDATA',
              name: 'PROJECTED DATA',
              disabled: false,
              route: 'FD/KTLSGP/So2Emissions/ProjectedData',
              expand: false,
              schilds: [],
            },
          ],
        },
      ],
    },
    {
      id: 'PMO',
      name: 'PMO',
      route: '',
      items: [],
    },
  ];

  constructor(private router: Router) {}

  navigateTo(route: string): void {
    if (route) {
      this.router.navigate([route]);
    }
  }
}
