<h1 mat-dialog-title class="TitleColor">{{ title }}</h1>
<div mat-dialog-content>
  <form [formGroup]="fuelGasFlaringEditForm">
    <div class="container">
      <div class="row" style="margin-bottom: 20px">
        <div class="col" *ngIf="inputs.volume">
          <label>Volume, kNm3</label>
          <input type="text" class="form-control" formControlName="volume" />
        </div>
        <div class="col" *ngIf="inputs.pilotGasRate">
          <label>Pilot Gas Rate, Nm3/hour</label>
          <input
            type="text"
            class="form-control"
            formControlName="pilotGasRate"
          />
        </div>
        <div class="col" *ngIf="inputs.volume">
          <label>Calculation</label>
          <select
            id="CalculationSelect"
            class="form-select"
            formControlName="calculation"
          >
            <option value="" disabled>Calculation:</option>
            <option>Auto</option>
            <option>Manual</option>
          </select>
        </div>
        <div class="col" *ngIf="inputs.purgeRate">
          <label>Purge Rate, Nm3/h</label>
          <input type="text" class="form-control" formControlName="purgeRate" />
        </div>
        <div class="col" *ngIf="inputs.purgeRate2">
          <label>Purge Rate2, Nm3/h</label>
          <input
            type="text"
            class="form-control"
            formControlName="purgeRate2"
          />
        </div>
        <div class="col" *ngIf="inputs.numberOfFuelGasLine">
          <label>Number of Fuel Gas Line</label>
          <input
            type="text"
            class="form-control"
            formControlName="numberOfFuelGasLine"
          />
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px">
        <label> Comment</label>
        <textarea
          class="form-control"
          rows="5"
          id="comment"
          formControlName="comment"
          placeholder="Comment"
        ></textarea>
      </div>
    </div>
  </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="center">
  <button
    mat-raised-button
    mat-dialog-save
    [ngStyle]="{
      'background-color': fuelGasFlaringEditForm.invalid ? 'gray' : '#0066b2',
      color: 'white'
    }"
    [disabled]="fuelGasFlaringEditForm.invalid"
    onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
    onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
    (click)="handleSave()"
  >
    SAVE
  </button>
  <button mat-raised-button mat-dialog-close>CLOSE</button>
</div>
