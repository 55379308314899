import { AfterViewInit, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';

@Component({
  selector: 'app-tco-map',
  templateUrl: './tco-map.component.html',
  styleUrls: ['./tco-map.component.scss'],
})
export class TCOMapComponent { 

  constructor(private router: Router) {}

  navigateTo(route: string): void {
    if (route) {
      this.router.navigate([route]);
    }
  }

}