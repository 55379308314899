import { LPGFuelGasFlaringDefaultDTO } from 'src/app/web-api-client';

export const fuelGasFlaringDefaultFeatureKey = 'fuelGasFlaringDefault';

export interface FuelGasFlaringDefaultState {
  data: LPGFuelGasFlaringDefaultDTO | null;
  error: string | null;
  loading: boolean;
}

export const initialState: FuelGasFlaringDefaultState = {
  data: null,
  error: null,
  loading: false,
};
