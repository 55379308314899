import { createAction, props } from '@ngrx/store';
import { UserGroups } from '../../web-api-client';

export const getUserGroups = createAction('[Auth] Get User Groups');

export const getUserGroupsSuccess = createAction(
  '[Auth] Get User Groups Success',
  props<{ userGroups: UserGroups[] }>()
);

export const getUserGroupsFailure = createAction('[Auth] Get User Groups Failure');
