import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CcComponent } from './cc/cc.component';
import { FuelGasFlaringComponent } from './fuel-gas-flaring/fuel-gas-flaring.component';
import { UserGroups } from '../../../../../web-api-client';
import { RoleGuard } from '../../../../../core/guards/role.guard';

const routes: Routes = [
{
    path: '',
    children: [
      {
        path: 'FinalReport',
        loadChildren: () => import('./final-report/final-report.module').then(m => m.FinalReportModule),
        data: {
          expectedRole: [UserGroups.LPG_Engineer],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'Input',
        loadChildren: () => import('./input/input.module').then(m => m.FDInputModule)
      },
      {
        path: 'CC',
        component: CcComponent,
        data: {
          expectedRole: [UserGroups.LPG_Engineer],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'FuelGasFlaring',
        component: FuelGasFlaringComponent,
        data: {
          expectedRole: [UserGroups.LPG_Engineer],
        },
        canActivate: [RoleGuard],
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LPGRoutingModule {}
