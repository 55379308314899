import { createAction, props } from '@ngrx/store';
import {
  LPGAnalystInputDTO,
  LPGDataForAnalystDTO,
} from 'src/app/web-api-client';

export const AddAnalyst = createAction(
  '[Analyst] Add Analyst',
  props<LPGAnalystInputDTO>()
);
export const AddAnalystSuccess = createAction(
  '[Analyst] Add Analyst Success',
  props<any>()
);
export const AddAnalystFailure = createAction(
  '[Analyst] Add Analyst Failure',
  props<{ message: string }>()
);

export const ListAnalyst = createAction(
  '[Analyst] List Analyst',
  props<{ startTime: Date }>()
);
export const ListAnalystSuccess = createAction(
  '[Analyst] List Analyst Success',
  props<{ data: LPGAnalystInputDTO[] }>()
);
export const ListAnalystFailure = createAction(
  '[Analyst] List Analyst Failure',
  props<{ message: string }>()
);

export const ForAnalystData = createAction(
  '[Analyst] For Analyst Data',
  props<{ startTime: Date }>()
);
export const ForAnalystDataSuccess = createAction(
  '[Analyst] For Analyst Data Success',
  props<{ data: LPGDataForAnalystDTO[] }>()
);
export const ForAnalystDataFailure = createAction(
  '[Analyst] For Analyst Data Failure',
  props<{ message: string }>()
);

export const EditAnalyst = createAction(
  '[Analyst] Edit Analyst',
  props<LPGAnalystInputDTO>()
);
export const EditAnalystSuccess = createAction(
  '[Analyst] Edit Analyst Success',
  props<any>()
);
export const EditAnalystFailure = createAction(
  '[Analyst] Edit Analyst Failure',
  props<{ message: string }>()
);

export const DeleteAnalyst = createAction(
  '[Analyst] Delete Analyst',
  props<{ id: number; startTime: Date }>()
);
export const DeleteAnalystSuccess = createAction(
  '[Analyst] Delete Analyst Success',
  props<any>()
);
export const DeleteAnalystFailure = createAction(
  '[Analyst] Delete Analyst Failure',
  props<{ message: string }>()
);
