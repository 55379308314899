import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
//reducers
import { chartJsReducer } from '../state/chartjs/chartjs.reducer';
import { PEReducer } from '../state/lpg/pe/pe.reducer';
import { AnalystReducer } from '../state/lpg/analyst/analyst.reducer';
import { OperatorReducer } from '../state/lpg/operator/operator.reducer';
import { NotificationReducer } from '../state/notification/notification.reducer';
import { CCReducer } from './lpg/cc/cc.reducer';
import { FuelGasFlaringReducer } from './lpg/fuel-gas-flaring/fuel-gas-flaring.reducer';
import { FlaringReportReducer } from './lpg/flaring-report/flaring-report.reducer';
import { LPGDataReducer } from './lpg/lpg-data/lpg-data.reducer';
import { ProjectedDataReducer } from './ktlsgp/projected-data/projected-data.reducer';
import { SO2EmissionsReducer } from './ktlsgp/so2-emissions/so2-emissions.reducer';
import { SO2EmissionsRecalculationReducer } from './ktlsgp/so2-emissions-recalculation/so2-emissions-recalculation.reducer';
import { SO2ChartsReducer } from './ktlsgp/so2-charts/so2-charts.reducer';
import { TargetPermitReducer } from './lpg/target-permit/target-permit.reducer';
import { ApproveReducer } from './lpg/approve/approve.reducer';
import { GURReducer } from '../features/tco/processeng/fgd/sgp/gas-usage-report/state/gas-usage-report.reducer';
import { AuthReducer } from '../state/auth/auth.reducer';
//effects
import { ChartJsEffects } from '../state/chartjs/chartjs.effects';
import { PEEffects } from '../state/lpg/pe/pe.effects';
import { AnalystEffects } from '../state/lpg/analyst/analyst.effects';
import { OperatorEffects } from '../state/lpg/operator/operator.effects';
import { NotificationEffects } from '../state/notification/notification.effects';
import { CCEffects } from './lpg/cc/cc.effects';
import { FuelGasFlaringEffects } from './lpg/fuel-gas-flaring/fuel-gas-flaring.effects';
import { FlaringReportEffects } from './lpg/flaring-report/flaring-report.effects';
import { LPGDataEffects } from './lpg/lpg-data/lpg-data.effects';
import { ProjectedDataEffects } from './ktlsgp/projected-data/projected-data.effects';
import { SO2EmissionsEffects } from './ktlsgp/so2-emissions/so2-emissions.effects';
import { SO2EmissionsRecalculationEffects } from './ktlsgp/so2-emissions-recalculation/so2-emissions-recalculation.effects';
import { SO2ChartsEffects } from './ktlsgp/so2-charts/so2-charts.effects';
import { TargetPermitEffects } from './lpg/target-permit/target-permit.effects';
import { ApproveEffects } from './lpg/approve/approve.effects';
import { flowmeterCalculationReducer } from './lpg/flowmeter-calculation/flowmeter-calculation.reducer';
import { FlowmeterCalculationEffects } from './lpg/flowmeter-calculation/flowmeter-calculation.effects';
import { FuelGasEffects } from '../features/tco/processeng/fgd/sgp/fuel-gas-dashboard/state/fuel-gas.effects';
import { FuelGasReducer } from '../features/tco/processeng/fgd/sgp/fuel-gas-dashboard/state/fuel-gas.reducer';
import { KTLFGDFuelGasReducer } from '../features/tco/processeng/fgd/ktl/production-gas-balance/state/ktl-fgd-fuel-gas.reducer';
import { KTLFGDFuelGasEffects } from '../features/tco/processeng/fgd/ktl/production-gas-balance/state/ktl-fgd-fuel-gas.effects';
import { FlaringReducer } from '../features/tco/processeng/fd/ktl/input/process-engineer/flaring/state/flaring.reducer';
import { FlaringEffects } from '../features/tco/processeng/fd/ktl/input/process-engineer/flaring/state/flaring.effects';
import { SGPFlaringReducer } from '../features/tco/processeng/fd/sgp/input/process-engineer/flaring/state/flaring.reducer';
import { SGPFlaringEffects } from '../features/tco/processeng/fd/sgp/input/process-engineer/flaring/state/flaring.effects';
import { DailyReportReducer } from '../features/tco/processeng/fd/ktl/daily-report/state/daily-report.reducer';
import { DailyReportEffects } from '../features/tco/processeng/fd/ktl/daily-report/state/daily-report.effects';
import { GUREffects } from '../features/tco/processeng/fgd/sgp/gas-usage-report/state/gas-usage-report.effects';
import { F205Reducer } from '../features/tco/processeng/fd/ktl/f-205/state/f-205.reducer';
import { F205Effects } from '../features/tco/processeng/fd/ktl/f-205/state/f-205.effects';
import { AuthEffects } from '../state/auth/auth.effects';

@NgModule({
  imports: [
    StoreModule.forRoot({
      chartjs: chartJsReducer,
      processEngineer: PEReducer,
      analyst: AnalystReducer,
      operator: OperatorReducer,
      notification: NotificationReducer,
      cc: CCReducer,
      fuelGasFlaring: FuelGasFlaringReducer,
      flaringReport: FlaringReportReducer,
      lpgData: LPGDataReducer,
      projectedData: ProjectedDataReducer,
      so2Emissions: SO2EmissionsReducer, 
      so2EmissionsRecalculation: SO2EmissionsRecalculationReducer,
      so2Charts: SO2ChartsReducer,
      targetPermit: TargetPermitReducer,
      approve: ApproveReducer,
      flowmeterCalc: flowmeterCalculationReducer,
      sgpFgdFuelGas: FuelGasReducer,
      ktlFgdFuelGas: KTLFGDFuelGasReducer,  
      ktlFDInput: FlaringReducer,
      sgpFDInput: SGPFlaringReducer,
      ktlFDDailyReport: DailyReportReducer,
      sgpFgdGUR: GURReducer,
      ktlFDF205: F205Reducer,
      authUserGroups: AuthReducer
    }),
    EffectsModule.forRoot([
      ChartJsEffects,
      PEEffects,
      AnalystEffects,
      OperatorEffects,
      NotificationEffects,
      CCEffects,
      FuelGasFlaringEffects,
      FlaringReportEffects,
      LPGDataEffects,
      ProjectedDataEffects,
      SO2EmissionsEffects,
      SO2EmissionsRecalculationEffects,
      SO2ChartsEffects,
      TargetPermitEffects,
      ApproveEffects,
      FlowmeterCalculationEffects,
      FuelGasEffects,
      KTLFGDFuelGasEffects,
      FlaringEffects,
      SGPFlaringEffects,
      DailyReportEffects,
      GUREffects,
      F205Effects,
      AuthEffects
    ]),
    StoreDevtoolsModule.instrument(),
  ],
})
export class StateModule {}
