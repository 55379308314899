import { createAction, props } from '@ngrx/store';
import { IPagedListOfKTLSGPSO2ProjectedDataInputDTO, KTLSGPSO2ProjectedDataInputDTO, PagedListOfKTLSGPSO2ProjectedDataInputDTO } from 'src/app/web-api-client';

export const ListProjectedData = createAction(
  '[ProjectedData] List Projected Data',
  props<{ startTime: Date; pageNumber: number | null; pageSize: number | null }>()
);
export const ListProjectedDataSuccess = createAction(
  '[ProjectedData] List Projected Data Success',
  props<{ data: PagedListOfKTLSGPSO2ProjectedDataInputDTO }>() // IPagedListOfKTLSGPSO2ProjectedDataInputDTO KTLSGPSO2ProjectedDataInputDTO[]
);
export const ListProjectedDataFailure = createAction(
  '[ProjectedData] List Projected Data Failure',
  props<{ message: string }>()
);

export const AddProjectedData = createAction(
  '[ProjectedData] Add Projected Data',
  props<KTLSGPSO2ProjectedDataInputDTO>()
);
export const AddProjectedDataSuccess = createAction(
  '[ProjectedData] Add Projected Data Success',
  props<any>()
);
export const AddProjectedDataFailure = createAction(
  '[ProjectedData] Add Projected Data Failure',
  props<{ message: string }>()
);

export const DeleteProjectedData = createAction(
  '[ProjectedData] Delete Projected Data',
  props<{ id: number; startTime: Date; pageNumber: number | null; pageSize: number | null }>()
);
export const DeleteProjectedDataSuccess = createAction(
  '[ProjectedData] Delete Projected Data Success',
  props<any>()
);
export const DeleteProjectedDataFailure = createAction(
  '[ProjectedData] Delete Projected Data Failure',
  props<{ message: string }>()
);
