import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TCOLanguages } from '../../models/i18n.model';

@Injectable({
  providedIn: 'root',
})
export class I18NService {
  public get languages$() {
    return this._languages$.asObservable();
  }

  private _languages$ = new BehaviorSubject<TCOLanguages[]>([]);

  public setLanguages(languages: TCOLanguages[]): void {
    this._languages$.next(languages);
  }
}
