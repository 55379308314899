import { createReducer, on } from '@ngrx/store';

import * as chartJsActions from './chartjs.action';
import { LPGFlowmeterGraphDTO } from 'src/app/web-api-client';

export interface ChartjsState {
  data: LPGFlowmeterGraphDTO | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: ChartjsState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const chartJsReducer = createReducer(
  initialState,
  on(
    chartJsActions.getChartData,
    (state, { startTime, endTime, graphType }) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(chartJsActions.getChartDataSuccess, (state, result) => ({
    data: result,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(chartJsActions.getChartDataFailure, (state, result) => ({
    ...state,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
