import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { TCOLanguages } from './core/models/i18n.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showNavbar: boolean = !window.location.href.includes('flowmeter');

  isMainPage: boolean = true;

  isNotAuthorize: boolean = true;

  //FD
  //KTL
  isKTLMonthlyReport: boolean = true;
  isKTLCC: boolean = true;
  isKTLSchema: boolean = true;

  //SGP
  isSGPDailyReport: boolean = true;
  isSGPMonthlyReport: boolean = true;
  isSGPCC: boolean = true;
  isSGPSchema: boolean = true;

  //SGI
  isSGIFlareReport: boolean = true;
  isSGITargetPermit: boolean = true;
  isSGICC: boolean = true;

  //LPG
  isLPGFuelGasFlaring: boolean = true;
  isLPGCC: boolean = true;
  isFlowmeterCalc: boolean = true;

  //SO2
  isSO2EmissionsMonthlyReport: boolean = true;

  //FGD
  //KTL
  // isKTLFuelGasDashboard: boolean = true;
  isFGDKTLInput: boolean = true;
  isFGDKTLDailyReport: boolean = true;
  isFGDKTLMonthlyReport: boolean = true;

  //SGP
  isFGDSGPDailyReport: boolean = true;
  isFGDSGPMonthlyReport: boolean = true;
  isSGPGasUsageReport: boolean = true;

  constructor(private _router: Router, private _translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    _translate.setDefaultLang(TCOLanguages.RU);
    _translate.addLangs([TCOLanguages.EN, TCOLanguages.RU, TCOLanguages.KZ]);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    _translate.use(TCOLanguages.RU);

    this._router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveItemByRoute(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
    // this.router.navigate(['TCO']);
  }

  setActiveItemByRoute(route: string) {
    this.isMainPage = route === '/' || route === '/TCO' || route === '/TCO/ProcessEng' || route === '/FD';
    this.isNotAuthorize = route === '/not-authorized';

    //FD
    //KTL
    this.isKTLMonthlyReport = route === '/TCO/ProcessEng/FD/KTL/MonthlyReport';
    this.isKTLCC = route === '/TCO/ProcessEng/FD/KTL/cc';
    this.isKTLSchema = route === '/TCO/ProcessEng/FD/KTL/Schema';

    //SGP
    this.isSGPDailyReport = route === '/TCO/ProcessEng/FD/SGP/DailyReport';
    this.isSGPMonthlyReport = route === '/TCO/ProcessEng/FD/SGP/MonthlyReport';
    this.isSGPCC = route === '/TCO/ProcessEng/FD/SGP/cc';
    this.isSGPSchema = route === '/TCO';

    //SGI
    this.isSGIFlareReport = route === '/TCO/ProcessEng/FD/SGI/flare-report';
    this.isSGITargetPermit = route === '/TCO/ProcessEng/FD/SGI/target-permit';
    this.isSGICC = route === '/TCO/ProcessEng/FD/SGI/cc';

    //LPG
    this.isLPGFuelGasFlaring =
      route === '/TCO/ProcessEng/FD/LPG/FuelGasFlaring';
    this.isLPGCC = route === '/TCO/ProcessEng/FD/LPG/CC';
    this.isFlowmeterCalc =
      route === '/TCO/ProcessEng/FD/LPG/Input/PE/flowmetercalc';

    //SO2
    this.isSO2EmissionsMonthlyReport =
      route === '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/MonthlyReport';

    //FGD
    //KTL
    this.isFGDKTLInput = route === '/TCO/ProcessEng/FGD/KTL/Input';

    this.isFGDKTLDailyReport =
      route === '/TCO/ProcessEng/FGD/KTL/ProductionGasBalance';

    this.isFGDKTLMonthlyReport =
      route === '/TCO/ProcessEng/FGD/KTL/monthly-report';

    //SGP
    this.isFGDSGPDailyReport = route === '/TCO/ProcessEng/FGD/SGP/DailyReport';
    this.isFGDSGPMonthlyReport =
      route === '/TCO/ProcessEng/FGD/SGP/MonthlyReport';
    this.isSGPGasUsageReport =
      route === '/TCO/ProcessEng/FGD/SGP/GasUsageReport';
  }
}
