import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TCOLanguages } from 'src/app/core/models/i18n.model';
import { I18NService } from 'src/app/core/services/i18n/i18n.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  arr = [
    {
      id: 'FD',
      name: 'FD',
      route: '',
      items: [
        {
          id: 'FD-KTL',
          name: 'KTL',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-KTL-INPUT',
              name: 'Input',
              disabled: true,
              route: '/FD/KTL/Input/ProcessEngineer/Flaring',
              expand: true,
              schilds: [
                {
                  id: 'FD-KTL-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-FLARING',
                      name: 'FLARING',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Flaring',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-MW',
                      name: 'MW',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/MW',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-LEAKAGE',
                      name: 'LEAKAGE',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Leakages',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-CONSTANTS',
                      name: 'CONSTANTS',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Constants',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-PERMITS',
                      name: 'PERMITS',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-EQUIPMENT',
                      name: 'EQUIPMENT',
                      disabled: true,
                      expand: false,
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-KTL-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: true,
                  expand: false,
                  route: '/FD/KTL/Input/Operator',
                },
                {
                  id: 'FD-KTL-INPUT-DIAGNOSTICIAN',
                  name: 'DIAGNOSTICIAN',
                  disabled: true,
                  expand: false,
                  route: '/FD/KTL/Input/Diagnostician',
                },
              ],
            },
            {
              id: 'FD-KTL-DAILY-REPORT',
              name: 'Daily Report',
              disabled: false,
              route: '/FD/KTL/DailyReport',
              expand: false,
              schilds: [
                {
                  id: 'FD-KTL-DAILY-REPORT-REPORTS',
                  name: 'REPORTS',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-KTL-DAILY-REPORT-REPORTS-DAILY-REPORT',
                      name: 'DAILY REPORT',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/DailyReport',
                    },
                    {
                      id: 'FD-KTL-DAILY-REPORT-REPORTS-F-205-KTL2',
                      name: 'F-205-KTL2',
                      disabled: false,
                      expand: false,
                      route: 'FD/KTL/F205/KTL2',
                    },
                    {
                      id: 'FD-KTL-DAILY-REPORT-REPORTS-F-205-KTL23',
                      name: 'F-205-KTL23',
                      disabled: false,
                      expand: false,
                      route: 'FD/KTL/F205/KTL23',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-KTL-MONTHLY-REPORT',
              name: 'Monthly Report',
              disabled: false,
              route: '/FD/KTL/MonthlyReport',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-KTL-CHEMICAL-COMPOSITION',
              name: 'Chemical Composition',
              disabled: false,
              route: '/FD/KTL/cc',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-KTL-SCHEMA',
              name: 'Schema',
              disabled: false,
              route: '/FD/KTL/Schema',
              expand: false,
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-SGP',
          name: 'SGP',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-SGP-INPUT',
              name: 'Input ',
              disabled: true,
              route: '/FD/SGP/Input/ProcessEngineer/Flaring',
              expand: true,
              schilds: [
                {
                  id: 'FD-SGP-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-FLARING',
                      name: 'FLARING',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Flaring',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-MW',
                      name: 'MW',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/MW',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-LEAKAGE',
                      name: 'LEAKAGE',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Leakages',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-CONSTANTS',
                      name: 'CONSTANTS',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Constants',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-PERMITS',
                      name: 'PERMITS',
                      disabled: true,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-EQUIPMENT',
                      name: 'EQUIPMENT',
                      disabled: true,
                      expand: false,
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-SGP-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: true,
                  expand: false,
                  route: '',
                },
                {
                  id: 'FD-SGP-INPUT-DIAGNOSTICIAN',
                  name: 'DIAGNOSTICIAN',
                  disabled: true,
                  expand: false,
                  route: '',
                },
              ],
            },
            {
              id: 'FD-SGP-DAILY-REPORT',
              name: 'Daily Report ',
              disabled: false,
              route: '/FD/SGP/DailyReport',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-SGP-MONTHLY-REPORT',
              name: 'Monthly Report ',
              disabled: true,
              route: '/FD/SGP/MonthlyReport',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-SGP-CHEMICAL-COMPOSITION',
              name: 'Chemical Composition ',
              disabled: true,
              route: '/FD/SGP/cc',
              expand: false,
              schilds: [],
            },
            {
              id: 'FD-SGP-SCHEMA',
              name: 'Schema ',
              disabled: true,
              route: '',
              expand: false,
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-SGI',
          name: 'SGI',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-SGI-FLARE-REPORT',
              name: 'Flare Report',
              disabled: false,
              expand: false,
              route: 'FD/SGI/flare-report',
              schilds: [],
            },
            {
              id: 'FD-SGI-TARGET-PERMIT',
              name: 'Target Permit',
              disabled: false,
              expand: false,
              route: 'FD/SGI/target-permit',
              schilds: [],
            },
            {
              id: 'FD-SGI-CC',
              name: 'Chemical Composition  ',
              disabled: false,
              expand: false,
              route: 'FD/SGI/cc',
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-FIELD',
          name: 'FIELD',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-FIELD-INPUT',
              name: 'Input       ',
              disabled: false,
              expand: true,
              route: 'FD/field/input',
              schilds: [],
            },
            {
              id: 'FD-FIELD-FIELD-CALC',
              name: 'Field Calc',
              disabled: false,
              expand: false,
              route: 'FD/field/field-calc',
              schilds: [],
            },
            {
              id: 'FD-FIELD-CC',
              name: 'Chemical Composition     ',
              disabled: false,
              expand: false,
              route: 'FD/field/cc',
              schilds: [],
            },
            {
              id: 'FD-FIELD-FINAL-REPORT',
              name: 'Final Report ',
              disabled: false,
              expand: false,
              route: 'FD/field/final-report',
              schilds: [],
            },
          ],
        },
        {
          id: 'FD-LPG',
          name: 'LPG',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-LPG-INPUT',
              name: 'Input  ',
              disabled: true,
              expand: true,
              route: '/FD/LPG/Input/PE/ProcessEngineer',
              schilds: [
                {
                  id: 'FD-LPG-INPUT-TARGETPERMIT',
                  name: 'TARGET/PERMIT',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/TargetPermit',
                },
                {
                  id: 'FD-LPG-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Operator',
                },
                {
                  id: 'FD-LPG-INPUT-ANALYST',
                  name: 'ANALYST',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Analyst',
                },
                {
                  id: 'FD-LPG-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/PE/ProcessEngineer',
                },
              ],
            },
            {
              id: 'FD-LPG-FUEL-GAS-FLARING',
              name: 'Fuel Gas Flaring',
              disabled: false,
              expand: false,
              route: 'FD/LPG/FuelGasFlaring',
              schilds: [],
            },
            {
              id: 'FD-LPG-CC',
              name: 'Chemical Composition   ',
              disabled: false,
              expand: false,
              route: 'FD/LPG/CC',
              schilds: [],
            },
            {
              id: 'FD-LPG-FINAL-REPORT',
              name: 'Final Report',
              disabled: true,
              expand: true,
              route: '/FD/LPG/FinalReport/LPGData',
              schilds: [
                {
                  id: 'FD-LPG-FINAL-REPORT-LPG-DATA',
                  name: 'LPG DATA',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/LPGData',
                },
                {
                  id: 'FD-LPG-FINAL-REPORT-FLARING-REPORT',
                  name: 'FLARING REPORT',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/FlaringReport',
                },
              ],
            },
          ],
        },
        {
          id: 'SO2-EMISSIONS',
          name: 'SO2 EMISSIONS',
          route: 'FD/KTLSGP/So2Emissions/MonthlyReport',
          disabled: false,
          childs: [
            {
              id: 'SO2EMISSIONS-KTLSGP-PROJECTEDDATA',
              name: 'Input   ',
              disabled: false,
              route: 'FD/KTLSGP/So2Emissions/ProjectedData',
              expand: false,
              schilds: [
                {
                  id: 'SO2EMISSIONS-KTLSGP-PROJECTEDDATA',
                  name: 'Projected Data',
                  disabled: true,
                  expand: false,
                  route: 'FD/KTLSGP/So2Emissions/ProjectedData',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'SO2EMISSIONS-KTLSGP-PROJECTEDDATA-KTL-PERMITS',
                  name: 'KTL Permits',
                  disabled: true,
                  expand: false,
                  route: '/FD/KTL/Input/ProcessEngineer/TargetPermit',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'SO2EMISSIONS-KTLSGP-PROJECTEDDATA-SGP-PERMITS',
                  name: 'SGP Permits',
                  disabled: true,
                  expand: false,
                  route: '/FD/SGP/Input/ProcessEngineer/TargetPermit',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'SO2EMISSIONS-KTLSGP-MONTHLY-REPORT',
              name: 'Monthly Report  ',
              disabled: false,
              route: 'FD/KTLSGP/So2Emissions/MonthlyReport',
              expand: false,
              schilds: [
                {
                  id: '',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'FGD',
      name: 'FGD',
      route: '',
      items: [
        {
          id: 'FGD-KTL',
          name: 'KTL',
          expand: true,
          disabled: true,
          route: '',
          childs: [
            // {
            //   id: 'FGD-KTL-INPUT',
            //   name: 'Input       ',
            //   disabled: false,
            //   expand: false,
            //   route: '',
            //   schilds: [],
            // },
            {
              id: 'FGD-KTL-DAILY-REPORT',
              name: 'Daily Report    ', //Fuel Gas Dashboard
              disabled: false,
              expand: false,
              route: 'FGD/KTL/ProductionGasBalance',
              schilds: [],
            },
            {
              id: 'FGD-KTL-MONTHLY-REPORT',
              name: 'Monthly Report      ', //Fuel Gas Dashboard
              disabled: false,
              expand: false,
              route: 'FGD/KTL/monthly-report',
              schilds: [],
            },
          ],
        },
        {
          id: 'FGD-SGP',
          name: 'SGP',
          expand: true,
          disabled: true,
          route: '',
          childs: [
            {
              id: 'FGD-SGP-FUEL-GAS-DASHBOARD',
              name: 'Daily Report  ',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/DailyReport',
              schilds: [],
            },
            {
              id: 'FGD-SGP-MONTHLY-REPORT',
              name: 'Monthly Report     ',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/MonthlyReport',
              schilds: [],
            },
            {
              id: 'FGD-SGP-GAS-USAGE-REPORT',
              name: 'Gas Usage Report',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/GasUsageReport',
              schilds: [],
            },
          ],
        },
        {
          id: 'FGD-FIELD',
          name: 'FIELD',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-FIELD-INPUT',
              name: 'Input       ',
              disabled: false,
              expand: true,
              route: 'FD/field/input',
              schilds: [],
            },
            {
              id: 'FGD-FIELD-FIELD-CALC',
              name: 'Field Calc',
              disabled: false,
              expand: false,
              route: 'FD/field/field-calc',
              schilds: [],
            },
            {
              id: 'FGD-FIELD-CC',
              name: 'Chemical Composition     ',
              disabled: false,
              expand: false,
              route: 'FD/field/cc',
              schilds: [],
            },
            {
              id: 'FGD-FIELD-FINAL-REPORT',
              name: 'Final Report ',
              disabled: false,
              expand: false,
              route: 'FD/field/final-report',
              schilds: [],
            },
          ],
        },
        {
          id: 'FGD-LPG',
          name: 'LPG',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-LPG-INPUT',
              name: 'Input  ',
              disabled: true,
              expand: true,
              route: '/FD/LPG/Input/PE/ProcessEngineer',
              schilds: [
                {
                  id: 'FGD-LPG-INPUT-TARGETPERMIT',
                  name: 'TARGET/PERMIT',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/TargetPermit',
                },
                {
                  id: 'FGD-LPG-INPUT-OPERATOR',
                  name: 'OPERATOR',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Operator',
                },
                {
                  id: 'FGD-LPG-INPUT-ANALYST',
                  name: 'ANALYST',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Analyst',
                },
                {
                  id: 'FGD-LPG-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/PE/ProcessEngineer',
                },
              ],
            },
            {
              id: 'FGD-LPG-FUEL-GAS-FLARING',
              name: 'Fuel Gas Flaring',
              disabled: false,
              expand: false,
              route: 'FD/LPG/FuelGasFlaring',
              schilds: [],
            },
            {
              id: 'FGD-LPG-CC',
              name: 'Chemical Composition',
              disabled: false,
              expand: false,
              route: 'FD/LPG/CC',
              schilds: [],
            },
            {
              id: 'FGD-LPG-FINAL-REPORT',
              name: 'Final Report',
              disabled: true,
              expand: true,
              route: '/FD/LPG/FinalReport/LPGData',
              schilds: [
                {
                  id: 'FGD-LPG-FINAL-REPORT-LPG-DATA',
                  name: 'LPG DATA',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/LPGData',
                },
                {
                  id: 'FGD-LPG-FINAL-REPORT-FLARING-REPORT',
                  name: 'FLARING REPORT',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/FlaringReport',
                },
              ],
            },
          ],
        },
        {
          id: 'FGD-SGI',
          name: 'SGI',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-SGI-FLARE-REPORT',
              name: 'Flare Report',
              disabled: false,
              expand: false,
              route: 'FD/SGI/flare-report',
              schilds: [],
            },
            {
              id: 'FGD-SGI-TARGET-PERMIT',
              name: 'Target Permit',
              disabled: false,
              expand: false,
              route: 'FD/SGI/target-permit',
              schilds: [],
            },
            {
              id: 'FGD-SGI-CC',
              name: 'Chemical Composition  ',
              disabled: false,
              expand: false,
              route: 'FD/SGI/cc',
              schilds: [],
            },
          ],
        },
        {
          id: 'FGD-IUS',
          name: 'INTERNAL USAGE',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-IUS-INPUT',
              name: 'Input    ',
              disabled: true,
              expand: true,
              route: 'FGD/IUS/Input/boilers-motohours-data',
              schilds: [
                {
                  id: 'FGD-IUS-INPUT-BOILERHOUSEDATA',
                  name: 'BOILER HOUSE DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/Input/boilers-motohours-data',
                },
                {
                  id: 'FGD-IUS-INPUT-HEATERSDATA',
                  name: 'HEATERS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/Input/heaters-motohours-data',
                },
                {
                  id: 'FGD-IUS-INPUT-METERSDATA',
                  name: 'METERS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/Input/meters-data',
                },
              ],
            },
            {
              id: 'FGD-IUS-CALCULATIONS',
              name: 'Calculations',
              disabled: true,
              expand: true,
              route: 'FGD/IUS/calculations/boilers-motohours-data',
              schilds: [
                {
                  id: 'FGD-IUS-FGU-BOILER',
                  name: 'BOILERS MOTOHOURS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/calculations/boilers-motohours-data',
                },
                {
                  id: 'FGD-IUS-FGU-HEATER',
                  name: 'HEATERS MOTOHOURS DATA',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/calculations/heaters-motohours-data',
                },
              ],
            },
            {
              id: 'FGD-IUS-MONTHLY-REPORT',
              name: 'Monthly Report   ',
              disabled: true,
              expand: true,
              route: 'FGD/IUS/MonthlyReport/monthly-calc',
              schilds: [
                {
                  id: 'FGD-IUS-MONTHLY-REPORT-MONTHLY-CALC',
                  name: 'MONTHLY CALC',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/MonthlyReport/monthly-calc',
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  activeSubItem: any = null;

  selectedItem: any = null;
  isMainPage: boolean = true;
  isNotAuthorize: boolean = true;

  currentRouteSub: string = '';

  //FD route
  isSubFDActive: boolean = true;
  isSubFDKTLActive: boolean = true;
  isSubFDSGPActive: boolean = true;
  isSubFDSGIActive: boolean = true;
  isSubFDFIELDActive: boolean = true;
  isSubFDLPGActive: boolean = true;
  isSubFDSO2EMISSIONSActive: boolean = true;

  //FGD route
  isSubFGDActive: boolean = true;
  isSubFGDKTLActive: boolean = true;
  isSubFGDSGPActive: boolean = true;
  isSubFGDSGIActive: boolean = true;
  isSubFGDFIELDActive: boolean = true;
  isSubFGDLPGActive: boolean = true;
  isSubFGDInternalUsageActive: boolean = true;

  public languages: TCOLanguages[] = [];

  constructor(
    public translate: TranslateService,
    private router: Router, 
    private _i18nService: I18NService
  ) {
    this._i18nService.languages$
      .pipe(takeUntilDestroyed())
      .subscribe(languages => {
        this.languages = languages;
      });

    this.router.events
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateSelectedItem(event.urlAfterRedirects);

          this.currentRouteSub = event.urlAfterRedirects;
        }
      });
  }

  public selectLang(lang: string) {
    this.translate.use(lang);
  }

  selectNestedChild(child: any) {
    this.selectedItem = child;
  }

  updateSelectedItem(currentRoute: string) {
    this.isMainPage =
      currentRoute === '/' ||
      currentRoute === '/TCO' ||
      currentRoute === '/TCO/ProcessEng' ||
      currentRoute === '/FD';
    
    this.isNotAuthorize = currentRoute === '/not-authorized';
    // Logic to set selectedItem based on the current route
    this.selectedItem = null;
    for (let group of this.arr) {
      for (let item of group.items) {
        for (let child of item.childs) {
          if (currentRoute.startsWith(child.route)) {
            this.selectedItem = child;
            return;
          }
          if (child.schilds) {
            for (let schild of child.schilds) {
              if (currentRoute.startsWith(schild.route)) {
                this.selectedItem = child;
                return;
              }
              if (schild.tchilds) {
                for (let tchild of schild.tchilds) {
                  if (currentRoute.startsWith(tchild.route)) {
                    this.selectedItem = child;
                    return;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  setActiveItemByRoute(route: string) {
    this.isMainPage = route === '/' || route === '/TCO' || route === '/TCO/ProcessEng' || route === '/FD';
    
    this.isNotAuthorize = route === '/not-authorized';
  }

  isMapActive(): boolean {
    return this.router.isActive('/TCO', true);
  }

  isActive(groupName: string): boolean {
    const currentRoute = this.router.url;

    //FD routes
    this.isSubFDActive = currentRoute.includes('/TCO/ProcessEng/FD/');
    this.isSubFDKTLActive = currentRoute.includes('/TCO/ProcessEng/FD/KTL/');
    this.isSubFDSGPActive = currentRoute.includes('/TCO/ProcessEng/FD/SGP/');
    this.isSubFDSGIActive = currentRoute.includes('/TCO/ProcessEng/FD/SGI/');
    this.isSubFDFIELDActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/'
    );
    this.isSubFDLPGActive = currentRoute.includes('/TCO/ProcessEng/FD/LPG/');
    this.isSubFDSO2EMISSIONSActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/'
    );

    if (groupName === 'FD' && currentRoute.includes('/TCO/ProcessEng/FD/')) {
      return true;
    }

    // FD KTL route active

    if (
      groupName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Input/')
    ) {
      return true;
    }
    if (
      groupName === 'Daily Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/DailyReport')
    ) {
      return true;
    }
    if (
      groupName === 'Monthly Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/MonthlyReport')
    ) {
      return true;
    }
    if (
      groupName === 'Chemical Composition' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/cc')
    ) {
      return true;
    }
    if (
      groupName === 'Schema' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Schema')
    ) {
      return true;
    }

    // FD SGP route active

    if (
      groupName === 'Input ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Input/')
    ) {
      return true;
    }
    if (
      groupName === 'Daily Report ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/DailyReport')
    ) {
      return true;
    }
    if (
      groupName === 'Monthly Report ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/MonthlyReport')
    ) {
      return true;
    }
    if (
      groupName === 'Chemical Composition ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/cc')
    ) {
      return true;
    }
    if (
      groupName === 'Schema ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Schema')
    ) {
      return true;
    }

    // FD SGI route active

    if (
      groupName === 'Flare Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/flare-report')
    ) {
      return true;
    }
    if (
      groupName === 'Target Permit' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/target-permit')
    ) {
      return true;
    }
    if (
      groupName === 'Chemical Composition  ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/cc')
    ) {
      return true;
    }

    // FD FIELD route active

    if (
      groupName === 'Input       ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/input/')
    ) {
      return true;
    }

    if (
      groupName === 'Field Calc' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/field-calc')
    ) {
      return true;
    }

    if (
      groupName === 'Chemical Composition     ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/cc')
    ) {
      return true;
    }

    if (
      groupName === 'Final Report ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/final-report')
    ) {
      return true;
    }

    // FD LPG route active

    if (
      groupName === 'Input  ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/')
    ) {
      return true;
    }
    if (
      groupName === 'Fuel Gas Flaring' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FuelGasFlaring')
    ) {
      return true;
    }
    if (
      groupName === 'Chemical Composition   ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/CC')
    ) {
      return true;
    }
    groupName;
    if (
      groupName === 'Final Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FinalReport/')
    ) {
      return true;
    }

    // FD SO2 Emissions route active

    if (
      groupName === 'Input   ' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/ProjectedData'
      )
    ) {
      return true;
    }
    if (
      groupName === 'Monthly Report  ' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/MonthlyReport'
      )
    ) {
      return true;
    }

    // FGD route active

    //KTL
    if (
      groupName === 'Input       ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/Input')
    ) {
      return true;
    }
    if (
      groupName === 'Daily Report    ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/ProductionGasBalance')
    ) {
      return true;
    }
    if (
      groupName === 'Monthly Report      ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/monthly-report')
    ) {
      return true;
    }
    //SGP
    if (
      groupName === 'Daily Report  ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/DailyReport')
    ) {
      return true;
    }
    if (
      groupName === 'Monthly Report     ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/MonthlyReport')
    ) {
      return true;
    }
    if (
      groupName === 'Gas Usage Report' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/GasUsageReport')
    ) {
      return true;
    }
    if (
      groupName === 'Input    ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/Input/')
    ) {
      return true;
    }
    if (
      groupName === 'Calculations' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/calculations/')
    ) {
      return true;
    }
    if (
      groupName === 'Monthly Report   ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/MonthlyReport/')
    ) {
      return true;
    }

    //FGD routes
    this.isSubFGDActive = currentRoute.includes('/TCO/ProcessEng/FGD/');
    this.isSubFGDKTLActive = currentRoute.includes('/TCO/ProcessEng/FGD/KTL/');
    this.isSubFGDSGPActive = currentRoute.includes('/TCO/ProcessEng/FGD/SGP/');
    this.isSubFGDSGIActive = currentRoute.includes('/TCO/ProcessEng/FD/SGI/');
    this.isSubFGDFIELDActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/'
    );
    this.isSubFGDLPGActive = currentRoute.includes('/TCO/ProcessEng/FD/LPG/');
    this.isSubFGDInternalUsageActive = currentRoute.includes(
      '/TCO/ProcessEng/FGD/IUS/'
    );

    if (groupName === 'FGD' && currentRoute.includes('/TCO/ProcessEng/FGD/')) {
      return true;
    }

    return false;
  }

  isSubChildActive(childName: string): boolean {
    const currentRoute = this.router.url;

    // console.log(childName);
    // console.log(currentRoute);

    // FD KTL route active

    if (
      childName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Input/')
    ) {
      return true;
    }
    if (
      childName === 'Daily Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/DailyReport')
    ) {
      return true;
    }
    if (
      childName === 'Monthly Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/MonthlyReport')
    ) {
      return true;
    }
    if (
      childName === 'Chemical Composition' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/cc')
    ) {
      return true;
    }
    if (
      childName === 'Schema' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Schema')
    ) {
      return true;
    }

    // FD SGP route active

    if (
      childName === 'Input ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Input/')
    ) {
      return true;
    }
    if (
      childName === 'Daily Report ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/DailyReport')
    ) {
      return true;
    }
    if (
      childName === 'Monthly Report ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/MonthlyReport')
    ) {
      return true;
    }
    if (
      childName === 'Chemical Composition ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/cc')
    ) {
      return true;
    }
    if (
      childName === 'Schema ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Schema')
    ) {
      return true;
    }

    // FD SGI route active

    if (
      childName === 'Flare Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/flare-report')
    ) {
      return true;
    }
    if (
      childName === 'Target Permit' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/target-permit')
    ) {
      return true;
    }
    if (
      childName === 'Chemical Composition  ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/cc')
    ) {
      return true;
    }

    // FD FIELD route active

    if (
      childName === 'Input       ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/input/')
    ) {
      return true;
    }


    if (
      childName === 'Field Calc' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/field-calc')
    ) {
      return true;
    }

    if (
      childName === 'Chemical Composition     ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/cc')
    ) {
      return true;
    }

    if (
      childName === 'Final Report ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/final-report')
    ) {
      return true;
    }

    // FD LPG route active

    if (
      childName === 'Input  ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/')
    ) {
      return true;
    }
    if (
      childName === 'Fuel Gas Flaring' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FuelGasFlaring')
    ) {
      return true;
    }
    if (
      childName === 'Chemical Composition   ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/CC')
    ) {
      return true;
    }
    if (
      childName === 'Final Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FinalReport/')
    ) {
      return true;
    }

    // FD SO2 Emissions route active

    if (
      childName === 'Input   ' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/ProjectedData'
      )
    ) {
      return true;
    }
    if (
      childName === 'Monthly Report  ' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/MonthlyReport'
      )
    ) {
      return true;
    }

    // FGD route active

    //KTL
    // if (
    //   childName === 'Fuel Gas Dashboard' &&
    //   currentRoute.includes('/TCO/ProcessEng/FGD/KTL/ProductionGasBalance')
    // ) {
    //   return true;
    // }
    if (
      childName === 'Input       ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/Input')
    ) {
      return true;
    }
    if (
      childName === 'Daily Report    ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/ProductionGasBalance')
    ) {
      return true;
    }
    if (
      childName === 'Monthly Report      ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/monthly-report')
    ) {
      return true;
    }
    //SGP
    if (
      childName === 'Daily Report  ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/DailyReport')
    ) {
      return true;
    }
    if (
      childName === 'Monthly Report     ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/MonthlyReport')
    ) {
      return true;
    }
    if (
      childName === 'Gas Usage Report' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/GasUsageReport')
    ) {
      return true;
    }
    if (
      childName === 'Input    ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/Input/')
    ) {
      return true;
    }
    if (
      childName === 'Calculations' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/calculations/')
    ) {
      return true;
    }
    if (
      childName === 'Monthly Report   ' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/MonthlyReport/')
    ) {
      return true;
    }

    return false;
  }
}
