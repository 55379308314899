import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  concatMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as F205Actions from './f-205.actions';
import * as notificationActions from '../../../../../../../state/notification/notification.action';
import {
  KTLSGPClient,
} from '../../../../../../../web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class F205Effects {
  constructor(
    private actions: Actions,
    private ktlfdClient: KTLSGPClient,
    private store: Store<AppState>
  ) {}

  F205 = createEffect(() =>
    this.actions.pipe(
      ofType(F205Actions.getF205),
      exhaustMap((action) => {
        return this.ktlfdClient
          .getF205CalcDetails(new Date(action.startdt),new Date(action.enddt))
          .pipe(
            map((res) => F205Actions.getF205Success({ data: res })),
            catchError((err) => of(F205Actions.getF205Failure(err)))
          );
      })
    )
  );
}