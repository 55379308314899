import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.action';
import { UserGroups } from '../../web-api-client';

export interface AuthState {
  userGroups: UserGroups[] | null;
}

export const initialState: AuthState = {
  userGroups: null,
};

export const AuthReducer = createReducer(
  initialState,
  on(AuthActions.getUserGroupsSuccess, (state, { userGroups }) => ({ ...state, userGroups })),
  on(AuthActions.getUserGroupsFailure, state => ({ ...state, userGroups: [] }))
);
