import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

import { AppState } from 'src/app/core/models/appState.model';
import { getChartData } from 'src/app/state/chartjs/chartjs.action';
import {
  chartDataSelector,
  chartTitleSelector,
} from 'src/app/state/chartjs/chartjs.selector';

import { LPGFlowmeterValuesDTO } from 'src/app/web-api-client';

import { CommonService } from 'src/app/core/services/common/common.service';

Chart.register(...registerables, zoomPlugin);

@Component({
  selector: 'app-chartjs',
  templateUrl: './chartjs.component.html',
  styleUrls: ['./chartjs.component.scss'],
})
export class ChartjsComponent implements OnInit, OnDestroy {
  @Input() startTime: string = '';
  @Input() endTime: string = '';

  chart: any;
  xLabels?: number[];

  yLabels?: number[];
  title?: string = 'Flared Gas Flowmeter';
  data?: LPGFlowmeterValuesDTO[];

  initialTimeUnit = 'hour';
  zoomedTimeUnit = 'minute';
  teckStepInitial = 4;
  tickStepZoomed = 2;
  izZoomed = false;

  constructor(
    private store: Store<AppState>,
    private commonService: CommonService
  ) {
    // this.store
    //   .select(chartTitleSelector)
    //   .subscribe((title) => (this.title = title));
    this.store.select(chartDataSelector).subscribe((data) => {
      this.data = data;
      this.xLabels = data?.map((x) => x.timeStamp.getTime());
      this.xLabels?.push(this.xLabels[this.xLabels.length - 1] + 60000);
      if (this.data) this.createChart();
    });
  }

  ngOnInit(): void {
    const startTime = this.commonService.manipulateDate(this.startTime, {
      amount: -1,
      unit: 'days',
      time: { hours: 2, minutes: 0 },
    });
    const endTime = this.commonService.manipulateDate(this.endTime, {
      amount: 1,
      unit: 'days',
      time: { hours: 1, minutes: 59 },
    });
    this.store.dispatch(
      getChartData({
        startTime: startTime,
        endTime: endTime,
        graphType: 2,
      })
    );
  }

  nearestTimeRange(val: number): number {
    const stepSizes = [240, 120, 60, 30, 15, 10, 5, 1];
    let nearest = 0;
    let diff = 300;
    stepSizes.forEach((x, index) => {
      if (diff > Math.abs(x - val)) {
        nearest = index;
        diff = Math.abs(x - val);
      }
    });
    return stepSizes[nearest];
  }

  createChart() {
    var oldChart = Chart.getChart('MyChart');
    if (oldChart != undefined) oldChart.destroy();
    this.chart = new Chart('MyChart', {
      type: 'line',
      data: {
        labels: this.xLabels, //timestamp
        datasets: [
          //values
          {
            label: '80FI351043_2S.PV',
            data: this.data?.map((y) => y.value),
            // data: this.data?.map((x) => x.value),
            backgroundColor: 'green',
            borderColor: 'green',
            pointBackgroundColor: 'green',
          },
        ],
      },
      options: {
        // responsive: true,
        // aspectRatio: 2.5,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              onZoom: ({ chart }) => {
                if (chart.options.scales) {
                  const max = chart.options.scales['x']?.max as number;
                  const min = chart.options.scales['x']?.min as number;
                  const newStepSize = this.nearestTimeRange(
                    Math.floor((max - min) / 1000000)
                  );
                  if (chart.options.scales['x'])
                    chart.options.scales['x'].ticks = {
                      stepSize: newStepSize,
                      source: 'auto',
                      maxRotation: 90,
                      minRotation: 90,
                      autoSkip: true,
                    };
                }
              },
              mode: 'xy',
            },
          },
          title: {
            display: true,
            text: this.title,
          },
        },

        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              displayFormats: {
                minute: 'D-MMM,HH:mm',
              },
              tooltipFormat: 'D-MMM,HH:mm',
            },
            title: {
              display: true,
              text: 'Time',
            },
            ticks: {
              source: 'auto',
              stepSize: 240,
              maxRotation: 90,
              minRotation: 90,
              autoSkip: true,
            },
          },
          y: {
            title: {
              display: true,
              text: 'Value',
            },
          },
        },
      },
    });
  }
  ngOnDestroy(): void {}
}

//zoom level
//240
//180
//120
//60
//30
//15
//5
//1
