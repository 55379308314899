import { createReducer, on } from '@ngrx/store';
import * as flowmeterCalculationActions from './flowmeter-calculation.action';
import { LPGFlowmeterCalculateType, LPGPEInputDTO } from 'src/app/web-api-client';

export interface FlowmeterCalculationState {
  average: number | null;
  volume: number | null;
  mass: number | null;
  result: number | null | { message: string; type: string }
  loading: boolean;
  error: any;
  isLoading?: boolean; 
  isLoadingSuccess?: boolean;
  isLoadingFailure?: boolean;
}

export const initialState: FlowmeterCalculationState = {
  average: null,
  volume: null,
  mass: null,
  result: null,
  loading: false,
  error: null
};

export const flowmeterCalculationReducer = createReducer(
  initialState,
  on(flowmeterCalculationActions.getCalculation, state => ({
    ...state,
    loading: true,
    error: null
  })),
  on(flowmeterCalculationActions.getCalculationSuccess, (state, { result, calculationType }) => ({
    ...state,
    result,
    average: calculationType == LPGFlowmeterCalculateType.Average ? result : state.average,
    volume: calculationType == LPGFlowmeterCalculateType.Volume ? result : state.volume,
    mass: calculationType == LPGFlowmeterCalculateType.Mass ? result : state.mass,
    loading: false
  })),
  on(flowmeterCalculationActions.getCalculationFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  })),

  on(flowmeterCalculationActions.LPGFlowmeterCalculationInputAdd, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(flowmeterCalculationActions.LPGFlowmeterCalculationInputAddSuccess, (state, result) => ({
    ...state,
    data: result,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(flowmeterCalculationActions.LPGFlowmeterCalculationInputAddFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
);