import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  arr = [
    {
      id: 'FD',
      name: 'FD',
      route: '',
      items: [
        {
          id: 'FD-KTL',
          name: 'KTL',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-KTL-INPUT',
              name: 'Input',
              disabled: true,
              route: '/FD/KTL/Input/ProcessEngineer/Flaring',
              expand: true,
              schilds: [
                {
                  id: 'FD-KTL-INPUT-PROCESSENGINEER',
                  name: 'Process Engineer',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-FLARING',
                      name: 'Flaring',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Flaring',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-MW',
                      name: 'MW',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/MW',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-LEAKAGE',
                      name: 'Leakage',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Leakages',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-CONSTANTS',
                      name: 'Constants',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/Constants',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-PERMITS',
                      name: 'Permits',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'FD-KTL-INPUT-PROCESSENGINEER-INPUT-HISTORY',
                      name: 'Input History',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/InputHistory',
                    },
                  ],
                },
                {
                  id: 'FD-KTL-INPUT-OPERATOR',
                  name: 'Operator',
                  disabled: false,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-KTL-INPUT-OPERATOR-FLARING',
                      name: 'Flaring ',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/Operator',
                    },
                  ],
                },
                {
                  id: 'FD-KTL-INPUT-DIAGNOSTICIAN',
                  name: 'Diagnostician',
                  disabled: true,
                  expand: false,
                  route: '/FD/KTL/Input/Diagnostician',
                  tchilds: [
                    {
                      id: 'FD-KTL-INPUT-DIAGNOSTICIAN-LEAKAGE',
                      name: 'Leakage ',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/Diagnostician/Leakage',
                    },
                    {
                      id: 'FD-KTL-INPUT-DIAGNOSTICIAN-FLARESWITCHER',
                      name: 'Flare Switcher',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/Input/Diagnostician/FlareSwitcher',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-KTL-DAILY-REPORT',
              name: 'Daily Report',
              disabled: false,
              route: '/FD/KTL/DailyReport',
              expand: false,
              schilds: [
                {
                  id: 'FD-KTL-DAILY-REPORT-REPORTS',
                  name: 'Daily Reports',
                  disabled: true,
                  expand: true,
                  route: '/FD/KTL/DailyReport',
                  tchilds: [
                    {
                      id: 'FD-KTL-DAILY-REPORT-REPORTS-DAILY-REPORT',
                      name: 'Daily Report',
                      disabled: false,
                      expand: false,
                      route: '/FD/KTL/DailyReport',
                    },
                    {
                      id: 'FD-KTL-DAILY-REPORT-REPORTS-F-205-KTL2',
                      name: 'KTL-2 F-205',
                      disabled: false,
                      expand: false,
                      route: 'FD/KTL/F205/KTL2',
                    },
                    {
                      id: 'FD-KTL-DAILY-REPORT-REPORTS-F-205-KTL23',
                      name: 'KTL-2.3 F-205',
                      disabled: false,
                      expand: false,
                      route: 'FD/KTL/F205/KTL23',
                    }
                  ],
                },
              ],
            },
            {
              id: 'FD-KTL-MONTHLY-REPORT',
              name: 'Monthly Report',
              disabled: false,
              route: '/FD/KTL/MonthlyReport',
              expand: false,
              schilds: [
                {
                  id: 'FD-KTL-MONTHLY-REPORT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-KTL-CHEMICAL-COMPOSITION',
              name: 'Chemical Composition',
              disabled: false,
              route: '/FD/KTL/cc',
              expand: false,
              schilds: [
                {
                  id: 'FD-KTL-CHEMICAL-COMPOSITION',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-KTL-SCHEMA',
              name: 'Schema',
              disabled: false,
              route: '/FD/KTL/Schema',
              expand: false,
              schilds: [
                {
                  id: 'FD-KTL-CHEMICAL-COMPOSITION',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FD-SGP',
          name: 'SGP',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-SGP-INPUT',
              name: 'Input',
              disabled: true,
              route: '/FD/SGP/Input/ProcessEngineer/Flaring',
              expand: true,
              schilds: [
                {
                  id: 'FD-SGP-INPUT-PROCESSENGINEER',
                  name: 'Process Engineer',
                  disabled: true,
                  expand: true,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-FLARING',
                      name: 'Flaring',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Flaring',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-MW',
                      name: 'MW',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/MW',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-LEAKAGE',
                      name: 'Leakage',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Leakages',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-CONSTANTS',
                      name: 'Constants',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/Constants',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-PERMITS',
                      name: 'Permits',
                      disabled: true,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-EQUIPMENT',
                      name: 'Equipment',
                      disabled: true,
                      expand: false,
                      route: '',
                    },
                    {
                      id: 'FD-SGP-INPUT-PROCESSENGINEER-INPUT-HISTORY',
                      name: 'Input History',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/InputHistory',
                    },
                  ],
                },
                {
                  id: 'FD-SGP-INPUT-OPERATOR',
                  name: 'Operator',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-SGP-INPUT-OPERATOR-FLARING',
                      name: 'Flaring ',
                      disabled: false,
                      expand: false,
                      route: '/FD/SGP/Input/Operator/Flaring',
                    },
                  ],
                },
                {
                  id: 'FD-SGP-INPUT-DIAGNOSTICIAN',
                  name: 'Diagnostician',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-SGP-INPUT-DIAGNOSTICIAN-LEAKAGE',
                      name: 'Leakage ',
                      disabled: false,
                      expand: false,
                      route: '',
                    },
                    {
                      id: 'FD-SGP-INPUT-OPERATOR-FLARE-SWITCHER',
                      name: 'Flare Switcher ',
                      disabled: false,
                      expand: false,
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-SGP-DAILY-REPORT',
              name: 'Daily Report',
              disabled: false,
              route: '/FD/SGP/DailyReport',
              expand: false,
              schilds: [
                {
                  id: 'FD-SGP-DAILY-REPORT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-SGP-MONTHLY-REPORT',
              name: 'Monthly Report',
              disabled: true,
              route: '',
              expand: false,
              schilds: [
                {
                  id: 'FD-SGP-MONTHLY-REPORT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-SGP-CHEMICAL-COMPOSITION',
              name: 'Chemical Composition',
              disabled: true,
              route: '',
              expand: false,
              schilds: [
                {
                  id: 'FD-SGP-CHEMICAL-COMPOSITION',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-SGP-SCHEMA',
              name: 'Schema',
              disabled: true,
              route: '',
              expand: false,
              schilds: [
                {
                  id: 'FD-SGP-SCHEMA',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FD-SGI',
          name: 'SGI',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-SGI-FLARE-REPORT',
              name: 'Flare Report',
              disabled: false,
              expand: false,
              route: 'FD/SGI/flare-report',
              schilds: [
                {
                  id: 'FD-SGI-FLARE-REPORT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-SGI-TARGET-PERMIT',
              name: 'Target Permit',
              disabled: false,
              expand: false,
              route: 'FD/SGI/target-permit',
              schilds: [
                {
                  id: 'FD-SGI-TARGET-PERMIT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-SGI-CC',
              name: 'Chemical Composition',
              disabled: false,
              expand: false,
              route: 'FD/SGI/cc',
              schilds: [
                {
                  id: 'FD-SGI-CC',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FD-FIELD',
          name: 'FIELD',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-FIELD-FIELD-CALC', // FD-FIELD-FIELD-CALC-DRY-GAS
              name: 'Field Calc',
              disabled: false,
              expand: false,
              route: 'FD/field/field-calc',
              schilds: [
                {
                  id: 'FD-FIELD-FIELD-CALC-DRY-GAS',
                  name: 'Dry Gas',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/field-calc/dry-gas',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-FIELD-CALC-RESERVOIR-GAS',
                  name: 'Reservoir Gas',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/field-calc/reservoir-gas',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-FIELD-CALC-PURGE-N-PILOT',
                  name: 'Purge & Pilot',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/field-calc/purge-n-pilot',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-FIELD-CC',
              name: 'Chemical Composition',
              disabled: false,
              expand: false,
              route: 'FD/field/cc',
              schilds: [
                {
                  id: 'FD-FIELD-CC-FUEL-GAS',
                  name: 'Fuel Gas',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/cc/fuel-gas',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-CC-RESERVOIR-GAS',
                  name: 'Reservoir Gas',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/cc/reservoir-gas',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-FIELD-FINAL-REPORT',
              name: 'Final Report',
              disabled: false,
              expand: false,
              route: 'FD/field/final-report',
              schilds: [
                {
                  id: 'FD-FIELD-FINAL-REPORT-GAS-BALANCE',
                  name: 'Gas Balance',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/final-report/gas-balance',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-FINAL-REPORT-FLARING-REPORT',
                  name: 'Flaring Report',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/final-report/flaring-report',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-FIELD-INPUT',
              name: 'Input',
              disabled: false,
              expand: false,
              route: 'FD/field/input',
              schilds: [
                {
                  id: 'FD-FIELD-INPUT-OPERATOR',
                  name: 'Operator',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/input/operator',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-INPUT-PE',
                  name: 'PE',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/input/pe',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-INPUT-METRICS',
                  name: 'Metrics',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/input/metrics',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-FIELD-INPUT-PERMIT',
                  name: 'Permits',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/input/permits',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FD-LPG',
          name: 'LPG',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FD-LPG-INPUT',
              name: 'Input',
              disabled: true,
              expand: true,
              route: '/FD/LPG/Input/TargetPermit',
              schilds: [
                {
                  id: 'FD-LPG-INPUT-TARGETPERMIT',
                  name: 'Process Engineer',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/TargetPermit',
                  tchilds: [
                    {
                      id: 'FD-LPG-INPUT-PROCESS-ENGINEER-TARGETPERMIT',
                      name: 'Flaring',
                      disabled: false,
                      expand: false,
                      route: '/FD/LPG/Input/PE/ProcessEngineer',
                    },
                    {
                      id: 'FD-LPG-INPUT-PROCESS-ENGINEER-TARGETPERMIT',
                      name: 'Permits',
                      disabled: false,
                      expand: false,
                      route: 'FD/LPG/Input/TargetPermit',
                    },
                  ],
                },
                {
                  id: 'FD-LPG-INPUT-ANALYST',
                  name: 'Analyst',
                  disabled: false,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: 'FD-LPG-INPUT-ANALYST-FLARING',
                      name: 'RTC/Flaring',
                      disabled: false,
                      expand: false,
                      route: 'FD/LPG/Input/Analyst',
                    },
                  ],
                },
                {
                  id: 'FD-LPG-INPUT-OPERATOR',
                  name: 'Operator',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Operator',
                  tchilds: [
                    {
                      id: 'FD-LPG-INPUT-OPERATOR-FLARING',
                      name: 'Flaring ',
                      disabled: false,
                      expand: false,
                      route: '/FD/LPG/Input/Operator',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-LPG-FUEL-GAS-FLARING',
              name: 'Fuel Gas Flaring',
              disabled: false,
              expand: false,
              route: 'FD/LPG/FuelGasFlaring',
              schilds: [
                {
                  id: 'FD-LPG-FUEL-GAS-FLARING',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-LPG-CC',
              name: 'Chemical Composition',
              disabled: false,
              expand: false,
              route: 'FD/LPG/CC',
              schilds: [
                {
                  id: 'FD-LPG-CC',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FD-LPG-FINAL-REPORT',
              name: 'Final Report',
              disabled: true,
              expand: true,
              route: '/FD/LPG/FinalReport/LPGData',
              schilds: [
                {
                  id: 'FD-LPG-FINAL-REPORT-LPG-DATA',
                  name: 'LPG Data',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/LPGData',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FD-LPG-FINAL-REPORT-FLARING-REPORT',
                  name: 'Flaring Report',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/FlaringReport',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'SO2-EMISSIONS',
          name: 'SO2 EMISSIONS',
          route: '',
          disabled: false,
          childs: [
            {
              id: 'SO2EMISSIONS-KTLSGP-INPUT',
              name: 'Input',
              disabled: false,
              route: 'FD/KTLSGP/So2Emissions/ProjectedData',
              expand: false,
              schilds: [
                {
                  id: 'SO2EMISSIONS-KTLSGP-INPUT-PROCESS-ENGINEER',
                  name: 'Process Engineer',
                  disabled: true,
                  expand: false,
                  route: 'FD/KTLSGP/So2Emissions/ProjectedData',
                  tchilds: [
                    {
                      id: 'SO2EMISSIONS-KTLSGP-PROCESS-ENGINEER-PROJECTEDDATA',
                      name: 'Projected Data',
                      disabled: true,
                      expand: false,
                      route: 'FD/KTLSGP/So2Emissions/ProjectedData',
                    },
                    {
                      id: 'SO2EMISSIONS-KTLSGP-PROCESS-ENGINEER-KTL-PERMITS',
                      name: 'KTL Permits',
                      disabled: true,
                      expand: false,
                      route: '/FD/KTL/Input/ProcessEngineer/TargetPermit',
                    },
                    {
                      id: 'SO2EMISSIONS-KTLSGP-PROCESS-ENGINEER-SGP-PERMITS',
                      name: 'SGP Permits',
                      disabled: true,
                      expand: false,
                      route: '/FD/SGP/Input/ProcessEngineer/TargetPermit',
                    },
                  ],
                },
              ],
            },
            {
              id: 'SO2EMISSIONS-KTLSGP-PROJECTEDDATA',
              name: 'Monthly Report',
              disabled: false,
              route: 'FD/KTLSGP/So2Emissions/MonthlyReport',
              expand: false,
              schilds: [
                {
                  id: '',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'FGD',
      name: 'FGD',
      route: '',
      items: [
        {
          id: 'FGD-KTL',
          name: 'KTL',
          expand: true,
          disabled: true,
          route: '',
          childs: [
            {
              id: 'FGD-KTL-PRODUCTION-GAS-BALANCE',
              name: 'Fuel Gas Dashboard',
              disabled: false,
              expand: false,
              route: 'FGD/KTL/ProductionGasBalance',
              schilds: [
                {
                  id: 'FGD-KTL-PRODUCTION-GAS-BALANCE',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FGD-SGP',
          name: 'SGP',
          expand: true,
          disabled: true,
          route: '',
          childs: [
            {
              id: 'FGD-SGP-FUEL-GAS-DASHBOARD',
              name: 'Fuel Gas Dashboard',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/FuelGasDashboard',
              schilds: [
                {
                  id: 'FGD-SGP-FUEL-GAS-DASHBOARD',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-SGP-GAS-USAGE-REPORT',
              name: 'Gas Usage Report',
              disabled: false,
              expand: false,
              route: 'FGD/SGP/GasUsageReport',
              schilds: [
                {
                  id: 'FGD-SGP-GAS-USAGE-REPORT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FGD-FIELD',
          name: 'FIELD',
          route: '',
          expand: false,
          disabled: true,
          childs: [
            {
              id: 'FGD-FIELD-DRY-GAS', // FD-FIELD-FIELD-CALC-DRY-GAS
              name: 'Dry Gas',
              disabled: false,
              expand: false,
              route: 'FD/field/field-calc/dry-gas',
              schilds: [
                {
                  id: 'FGD-FIELD-DRY-GAS',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-FIELD-RESERVOIR-GAS', //FD-FIELD-FIELD-CALC-RESERVOIR-GAS
              name: 'Reservoir Gas',
              disabled: false,
              expand: false,
              route: 'FD/field/field-calc/reservoir-gas',
              schilds: [
                {
                  id: 'FGD-FIELD-RESERVOIR-GAS',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-FIELD-PURGE-AND-PILOT', //FD-FIELD-FIELD-CALC-PURGE-AND-PILOT
              name: 'Purge & Pilot',
              disabled: false,
              expand: false,
              route: 'FD/field/field-calc/purge-n-pilot',
              schilds: [
                {
                  id: 'FGD-FIELD-PURGE-AND-PILOT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: 'FD/field/field-calc/purge-n-pilot',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FGD-LPG',
          name: 'LPG',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-LPG-INPUT',
              name: 'Input',
              disabled: true,
              expand: true,
              route: '/FD/LPG/Input/TargetPermit',
              schilds: [
                {
                  id: 'FGD-LPG-INPUT-TARGETPERMIT',
                  name: 'Target/Permit',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/TargetPermit',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FGD-LPG-INPUT-OPERATOR',
                  name: 'Operator',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Operator',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FGD-LPG-INPUT-ANALYST',
                  name: 'Analyst',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/Analyst',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FGD-LPG-INPUT-PROCESSENGINEER',
                  name: 'PROCESS ENGINEER',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/Input/PE/ProcessEngineer',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-LPG-FUEL-GAS-FLARING',
              name: 'Fuel Gas Flaring',
              disabled: false,
              expand: false,
              route: 'FD/LPG/FuelGasFlaring',
              schilds: [
                {
                  id: 'FGD-LPG-FUEL-GAS-FLARING',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-LPG-CC',
              name: 'Chemical Composition',
              disabled: false,
              expand: false,
              route: 'FD/LPG/CC',
              schilds: [
                {
                  id: 'FGD-LPG-CC',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-LPG-FINAL-REPORT',
              name: 'FINAL REPORT',
              disabled: true,
              expand: true,
              route: '/FD/LPG/FinalReport/LPGData',
              schilds: [
                {
                  id: 'FGD-LPG-FINAL-REPORT-LPG-DATA',
                  name: 'LPG Data',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/LPGData',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FGD-LPG-FINAL-REPORT-FLARING-REPORT',
                  name: 'Flaring Report',
                  disabled: false,
                  expand: false,
                  route: 'FD/LPG/FinalReport/FlaringReport',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FGD-SGI',
          name: 'SGI',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-SGI-FLARE-REPORT',
              name: 'Flare Report',
              disabled: false,
              expand: false,
              route: 'FD/SGI/flare-report',
              schilds: [
                {
                  id: 'FGD-SGI-FLARE-REPORT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-SGI-TARGET-PERMIT',
              name: 'Target Permit',
              disabled: false,
              expand: false,
              route: 'FD/SGI/target-permit',
              schilds: [
                {
                  id: 'FGD-SGI-TARGET-PERMIT',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-SGI-CC',
              name: 'Chemical Composition',
              disabled: false,
              expand: false,
              route: 'FD/SGI/cc',
              schilds: [
                {
                  id: 'FGD-SGI-CC',
                  name: '',
                  disabled: true,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'FGD-IUS',
          name: 'Internal Usage',
          route: '',
          expand: true,
          disabled: true,
          childs: [
            {
              id: 'FGD-IUS-INPUT',
              name: 'Input',
              disabled: true,
              expand: true,
              route: 'FGD/IUS/Input/boilers-motohours-data',
              schilds: [
                {
                  id: 'FGD-IUS-INPUT-PROCESS-ENGINEER',
                  name: 'Process Engineer',
                  disabled: false,
                  expand: false,
                  route: '',
                  tchilds: [
                    {
                      id: 'FGD-IUS-INPUT-PROCESS-ENGINEER-BOILERHOUSEDATA',
                      name: 'Boilers Motohours Data',
                      disabled: false,
                      expand: false,
                      route: 'FGD/IUS/Input/boilers-motohours-data',
                    },
                    {
                      id: 'FGD-IUS-INPUT-PROCESS-ENGINEER-HEATERSDATA',
                      name: 'Heaters Motohours Data',
                      disabled: false,
                      expand: false,
                      route: 'FGD/IUS/Input/heaters-motohours-data',
                    },
                    {
                      id: 'FGD-IUS-INPUT-PROCESS-ENGINEER-METERSDATA',
                      name: 'Meters Data',
                      disabled: false,
                      expand: false,
                      route: 'FGD/IUS/Input/meters-data',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-IUS-CALCULATIONS',
              name: 'Calculations',
              disabled: true,
              expand: true,
              route: 'FGD/IUS/calculations/boilers-motohours-data',
              schilds: [
                {
                  id: 'FGD-IUS-FGU-BOILER',
                  name: 'Boilers Motohours Data',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/calculations/boilers-motohours-data',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
                {
                  id: 'FGD-IUS-FGU-HEATER',
                  name: 'Heaters Motohours Data',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/calculations/heaters-motohours-data',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
            {
              id: 'FGD-IUS-MONTHLY-REPORT',
              name: 'Monthly Report',
              disabled: true,
              expand: true,
              route: 'FGD/IUS/MonthlyReport/monthly-calc',
              schilds: [
                {
                  id: 'FGD-IUS-MONTHLY-REPORT-MONTHLY-CALC',
                  name: 'Monthly Calc',
                  disabled: false,
                  expand: false,
                  route: 'FGD/IUS/MonthlyReport/monthly-calc',
                  tchilds: [
                    {
                      id: '',
                      name: '',
                      route: '',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  activeSubItem: any = null;

  selectedItem: any = null;
  isMainPage: boolean = true;

  currentRouteSub: string = '';

  //FD route
  isSubFDActive: boolean = true;

  //KTL
  isSubFDKTLActive: boolean = true;
  isSubFDKTLInputActive: boolean = true;
  isSubFDKTLDailyReportActive: boolean = true;
  isSubFDKTL2F205Active: boolean = true;
  isSubFDKTL23F205Active: boolean = true;
  isSubFDKTLMonthlyReportActive: boolean = true;
  isSubFDKTLCCActive: boolean = true;
  isSubFDKTLSchemaActive: boolean = true;

  //SGP
  isSubFDSGPActive: boolean = true;
  isSubFDSGPInputActive: boolean = true;

  //SGI
  isSubFDSGIActive: boolean = true;

  //FIELD
  isSubFDFIELDActive: boolean = true;
  isSubFDFIELDFieldCalcActive: boolean = true;
  isSubFDFIELDCCActive: boolean = true;
  isSubFDFIELDFinalReportActive: boolean = true;
  isSubFDFIELDInputActive: boolean = true;

  //LPG
  isSubFDLPGActive: boolean = true;
  isSubFDLPGInputActive: boolean = true;

  //FD SO2
  isSubFDSO2EMISSIONSActive: boolean = true;
  isSubFDSO2EMISSIONSInputctive: boolean = true;

  //FGD route
  isSubFGDActive: boolean = true;

  //KTL
  isSubFGDKTLActive: boolean = true;

  //SGP
  isSubFGDSGPActive: boolean = true;

  //SGI
  isSubFGDSGIActive: boolean = true;

  //FIELD
  isSubFGDFIELDActive: boolean = true;

  //LPG
  isSubFGDLPGActive: boolean = true;

  //Internal Usage
  isSubFGDInternalUsageActive: boolean = true;
  isSubFGDInternalUsageInputActive: boolean = true;
  isSubFGDInternalUsageCalculationsActive: boolean = true;
  isSubFGDInternalUsageMonthlyReportActive: boolean = true;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateSelectedItem(event.urlAfterRedirects);

        this.currentRouteSub = event.urlAfterRedirects;
      }
    });
  }

  updateSelectedItem(currentRoute: string) {
    this.isMainPage =
      currentRoute === '/TCO' ||
      currentRoute === '/TCO/ProcessEng' ||
      currentRoute === '/FD';
    // Logic to set selectedItem based on the current route
    this.selectedItem = null;
    for (let group of this.arr) {
      for (let item of group.items) {
        for (let child of item.childs) {
          if (currentRoute.startsWith(child.route)) {
            this.selectedItem = child;
            return;
          }
          if (child.schilds) {
            for (let schild of child.schilds) {
              if (currentRoute.startsWith(schild.route)) {
                this.selectedItem = child;
                return;
              }
              if (schild.tchilds) {
                for (let tchild of schild.tchilds) {
                  if (currentRoute.startsWith(tchild.route)) {
                    this.selectedItem = child;
                    return;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  isSubChildActive(childName: string): boolean {
    const currentRoute = this.router.url;

    //==========================================Condition=Variables==========================================//

    //==================================FD==================================//

    //FD
    this.isSubFDActive = currentRoute.includes('/TCO/ProcessEng/FD/');

    //===============FD=KTL===============//

    //FD KTL
    this.isSubFDKTLActive = currentRoute.includes('/TCO/ProcessEng/FD/KTL/');

    //FD KTL Input
    this.isSubFDKTLInputActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTL/Input/'
    );

    //FD KTL Daily Report
    this.isSubFDKTLDailyReportActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTL/DailyReport'
    );

    //FD KTL F205
    this.isSubFDKTL2F205Active = currentRoute.endsWith(
      '/TCO/ProcessEng/FD/KTL/F205/KTL2'
    );

    //FD KTL F205 KTL23
    this.isSubFDKTL23F205Active = currentRoute.endsWith(
      '/TCO/ProcessEng/FD/KTL/F205/KTL23'
    );

    //FD KTL Monthly Report
    this.isSubFDKTLMonthlyReportActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTL/MonthlyReport'
    );

    //FD KTL CC
    this.isSubFDKTLCCActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTL/cc'
    );

    //FD KTL Schema
    this.isSubFDKTLSchemaActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTL/Schema'
    );

    //===============FD=SGP===============//

    //FD SGP
    this.isSubFDSGPActive = currentRoute.includes('/TCO/ProcessEng/FD/SGP/');

    //FD SGP Input
    this.isSubFDSGPInputActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/SGP/'
    );

    //===============FD=SGI===============//

    //FD SGI
    this.isSubFDSGIActive = currentRoute.includes('/TCO/ProcessEng/FD/SGI/');

    //===============FD=FIELD===============//

    //FD FIELD
    this.isSubFDFIELDActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/'
    );

    //FD FIELD Field Calc
    this.isSubFDFIELDFieldCalcActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/field-calc'
    );

    //FD FIELD CC
    this.isSubFDFIELDCCActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/cc'
    );

    //FD FIELD Final Report
    this.isSubFDFIELDFinalReportActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/final-report'
    );
    this.isSubFDFIELDInputActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/input'
    );

    // FD FIELD INPUT Operator route active
    if (
      childName === 'Operator' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/input/operator')
    ) {
      return true;
    }

    // FD FIELD INPUT PE route active
    if (
      childName === 'PE' &&
      currentRoute === '/TCO/ProcessEng/FD/field/input/pe'
    ) {
      return true;
    }

    // FD FIELD INPUT Metrics route active
    if (
      childName === 'Metrics' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/input/metrics')
    ) {
      return true;
    }

    // FD FIELD INPUT Permits route active
    if (
      childName === 'Permits' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/input/permits')
    ) {
      return true;
    }

    //===============FD=LPG===============//

    //FD LPG
    this.isSubFDLPGActive = currentRoute.includes('/TCO/ProcessEng/FD/LPG/');

    //FD LPG Input
    this.isSubFDLPGInputActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/LPG/Input/'
    );

    //===============FD=SO2=EMISSIONS===============//

    //FD SO2 EMISSIONS
    this.isSubFDSO2EMISSIONSActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/'
    );

    //FD SO2 EMISSIONS Projected Data
    this.isSubFDSO2EMISSIONSInputctive = currentRoute.includes(
      '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/ProjectedData'
    );

    //==================================FGD==================================//

    //FGD
    this.isSubFGDActive = currentRoute.includes('/TCO/ProcessEng/FGD/');

    //===============FGD=KTL===============//

    //FGD KTL
    this.isSubFGDKTLActive = currentRoute.includes('/TCO/ProcessEng/FGD/KTL/');

    //===============FGD=SGP===============//

    //FGD SGP
    this.isSubFGDSGPActive = currentRoute.includes('/TCO/ProcessEng/FGD/SGP/');

    //===============FGD=SGI===============//

    //FGD SGI
    this.isSubFGDSGIActive = currentRoute.includes('/TCO/ProcessEng/FD/SGI/');

    //===============FGD=FIELD===============//

    //FGD FIELD
    this.isSubFGDFIELDActive = currentRoute.includes(
      '/TCO/ProcessEng/FD/field/'
    );

    //===============FGD=LPG===============//

    //FGD LPG
    this.isSubFGDLPGActive = currentRoute.includes('/TCO/ProcessEng/FD/LPG/');

    //===============FGD=Internal=Usage===============//

    //FGD Internal Usage
    this.isSubFGDInternalUsageActive = currentRoute.includes(
      '/TCO/ProcessEng/FGD/IUS/'
    );

    //FGD Internal Usage Input
    this.isSubFGDInternalUsageInputActive = currentRoute.includes(
      '/TCO/ProcessEng/FGD/IUS/Input/'
    );

    //FGD Internal Usage Calculations
    this.isSubFGDInternalUsageCalculationsActive = currentRoute.includes(
      '/TCO/ProcessEng/FGD/IUS/calculations/'
    );

    //FGD Internal Usage Monthly Report
    this.isSubFGDInternalUsageMonthlyReportActive = currentRoute.includes(
      '/TCO/ProcessEng/FGD/IUS/MonthlyReport/'
    );

    //==========================================Active=Condition==========================================//

    // FD KTL route active

    // FD KTL INPUT route active
    if (
      childName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Input/')
    ) {
      return true;
    }

    // FD KTL INPUT PROCESS ENGINEER route active

    // FD KTL INPUT PROCESS ENGINEER Flaring route active
    if (
      childName === 'Flaring' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/Flaring'
      )
    ) {
      return true;
    }

    // FD KTL INPUT PROCESS ENGINEER MW route active
    if (
      childName === 'MW' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/MW')
    ) {
      return true;
    }

    // FD KTL INPUT PROCESS ENGINEER Leakage route active
    if (
      childName === 'Leakage' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/Leakages'
      )
    ) {
      return true;
    }

    // FD KTL INPUT PROCESS ENGINEER Constants route active
    if (
      childName === 'Constants' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/Constants'
      )
    ) {
      return true;
    }

    // FD KTL INPUT PROCESS ENGINEER Permits route active
    if (
      childName === 'Permits' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/TargetPermit'
      )
    ) {
      return true;
    }

    // FD KTL INPUT PROCESS ENGINEER Input History route active
    if (
      childName === 'Input History' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/InputHistory'
      )
    ) {
      return true;
    }

    // FD KTL INPUT OPERATOR route active

    // FD KTL INPUT OPERATOR Flaring route active
    if (
      childName === 'Flaring ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Input/Operator')
    ) {
      return true;
    }

    // FD KTL INPUT DIAGNOSTICIAN route active

    // FD KTL INPUT DIAGNOSTICIAN Leakage route active
    if (
      childName === 'Leakage ' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/Diagnostician/Leakage'
      )
    ) {
      return true;
    }

    // FD KTL INPUT DIAGNOSTICIAN Flare Switcher route active
    if (
      childName === 'Flare Switcher' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTL/Input/Diagnostician/FlareSwitcher'
      )
    ) {
      return true;
    }

    // FD KTL DAILY REPORT route active
    if (
      childName === 'Daily Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/DailyReport')
    ) {
      return true;
    }

    // FD KTL F-205 KTL23 route active
    if (
      childName === 'KTL-2.3 F-205' &&
      currentRoute.endsWith('/TCO/ProcessEng/FD/KTL/F205/KTL23')
    ) {
      return true;
    }

    // FD KTL F-205 KTL2 route active
    if (
      childName === 'KTL-2 F-205' &&
      currentRoute.endsWith('/TCO/ProcessEng/FD/KTL/F205/KTL2')
    ) {
      return true;
    }

    // FD KTL MONTHLY REPORT route active
    if (
      childName === 'Monthly Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/MonthlyReport')
    ) {
      return true;
    }

    // FD KTL CC route active
    if (
      childName === 'Chemical Composition' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/cc')
    ) {
      return true;
    }

    // FD KTL SCHEMA route active
    if (
      childName === 'Schema' &&
      currentRoute.includes('/TCO/ProcessEng/FD/KTL/Schema')
    ) {
      return true;
    }

    // FD SGP route active

    // FD SGP Input route active
    if (
      childName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Input/')
    ) {
      return true;
    }

    // FD SGP INPUT PROCESS ENGINEER route active

    // FD SGP INPUT PROCESS ENGINEER Flaring route active
    if (
      childName === 'Flaring' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/Flaring'
      )
    ) {
      return true;
    }

    // FD SGP INPUT PROCESS ENGINEER MW route active
    if (
      childName === 'MW' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/MW')
    ) {
      return true;
    }

    // FD SGP INPUT PROCESS ENGINEER Leakage route active
    if (
      childName === 'Leakage' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/Leakages'
      )
    ) {
      return true;
    }

    // FD SGP INPUT PROCESS ENGINEER Constants route active
    if (
      childName === 'Constants' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/Constants'
      )
    ) {
      return true;
    }

    // FD SGP INPUT PROCESS ENGINEER Permits route active
    if (
      childName === 'Permits' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/TargetPermit'
      )
    ) {
      return true;
    }

    // FD SGP INPUT PROCESS ENGINEER Equipment route active
    if (
      childName === 'Equipment' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/Equipment'
      )
    ) {
      return true;
    }

     // FD SGP INPUT PROCESS ENGINEER Input History route active
     if (
      childName === 'Input History' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/InputHistory'
      )
    ) {
      return true;
    }

    // FD SGP Operator route active

    if (
      childName === 'Flaring ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Input/Operator/Flaring')
    ) {
      return true;
    }

    // FD SGP Daily Report route active
    if (
      childName === 'Daily Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/DailyReport')
    ) {
      return true;
    }

    // FD SGP Monthly Report route active
    if (
      childName === 'Monthly Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/MonthlyReport')
    ) {
      return true;
    }

    // FD SGP CC route active
    if (
      childName === 'Chemical Composition' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/cc')
    ) {
      return true;
    }

    // FD SGP Schema route active
    if (
      childName === 'Schema' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGP/Schema')
    ) {
      return true;
    }

    // FD SGI route active

    // FD SGI Flare Report route active
    if (
      childName === 'Flare Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/flare-report')
    ) {
      return true;
    }

    // FD SGI Target Permit route active
    if (
      childName === 'Target Permit' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/target-permit')
    ) {
      return true;
    }

    // FD SGI Chemical Composition route active
    if (
      childName === 'Chemical Composition' &&
      currentRoute.includes('/TCO/ProcessEng/FD/SGI/cc')
    ) {
      return true;
    }

    // FD Final Report route active

    // FD Final Report Gas Balance route active
    if (
      childName === 'Gas Balance' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/final-report/gas-balance')
    ) {
      return true;
    }

    // FD FIELD Final Report Flaring Report route active
    if (
      childName === 'Flaring Report' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/field/final-report/flaring-report'
      )
    ) {
      return true;
    }

    // FD FIELD field calc dry gas route active
    if (
      childName === 'Dry Gas' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/field-calc/dry-gas')
    ) {
      return true;
    }

    // FD FIELD field calc reservoir gas route active
    if (
      childName === 'Reservoir Gas' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/field-calc/reservoir-gas')
    ) {
      return true;
    }

    // FD FIELD field calc purge n pilot route active
    if (
      childName === 'Purge & Pilot' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/field-calc/purge-n-pilot')
    ) {
      return true;
    }

    //FD FIELD CC fuel gas route active

    if (
      childName === 'Fuel Gas' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/cc/fuel-gas')
    ) {
      return true;
    }

    //FD FIELD CC reservoir gas route active

    if (
      childName === 'Reservoir Gas' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/cc/reservoir-gas')
    ) {
      return true;
    }

    // FD FIELD Input

    if (
      childName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FD/field/input')
    )
      return true;

    // FD LPG route active

    // FD LPG INPUT
    if (
      childName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/TargetPermit')
    ) {
      return true;
    }

    // FD LPG INPUT Flaring
    if (
      childName === 'Flaring' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/PE/ProcessEngineer')
    ) {
      return true;
    }

    // FD LPG INPUT TargetPermit
    if (
      childName === 'Permits' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/TargetPermit')
    ) {
      return true;
    }

    // FD LPG INPUT Analyst
    if (
      childName === 'RTC/Flaring' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/Analyst')
    ) {
      return true;
    }

    // FD LPG INPUT Operator
    if (
      childName === 'Flaring ' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/Input/Operator')
    ) {
      return true;
    }

    // FD LPG FINAL REPORT
    if (
      childName === 'Final Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FinalReport/')
    ) {
      return true;
    }

    // FD LPG FINAL REPORT LPG Data
    if (
      childName === 'LPG Data' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FinalReport/LPGData')
    ) {
      return true;
    }

    // FD LPG FINAL REPORT Flaring Report
    if (
      childName === 'Flaring Report' &&
      currentRoute.includes('/TCO/ProcessEng/FD/LPG/FinalReport/FlaringReport')
    ) {
      return true;
    }

    // FD SO2 Emissions route active

    // FD SO2 Emissions Projected Data route active
    if (
      childName === 'Projected Data' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/ProjectedData'
      )
    ) {
      return true;
    }

    // FGD route active

    // FGD KTL route active
    if (
      childName === 'Fuel Gas Dashboard' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/KTL/ProductionGasBalance')
    ) {
      return true;
    }

    // FGD SGP route active
    if (
      childName === 'Fuel Gas Dashboard' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/FuelGasDashboard ')
    ) {
      return true;
    }

    // FGD SGP Gas Usage Report route active
    if (
      childName === 'Gas Usage Report' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/SGP/GasUsageReport')
    ) {
      return true;
    }

    // FGD Internal Usage route active

    // FGD Internal Usage Input route active
    if (
      childName === 'Input' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/Input/')
    ) {
      return true;
    }

    // FGD Internal Usage Input Boilers Motohours Data route active
    if (
      childName === 'Boilers Motohours Data' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FGD/IUS/Input/boilers-motohours-data'
      )
    ) {
      return true;
    }

    // FGD Internal Usage Input Heaters Motohours Data route active
    if (
      childName === 'Heaters Motohours Data' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FGD/IUS/Input/heaters-motohours-data'
      )
    ) {
      return true;
    }

    // FGD Internal Usage Input Meters Data route active
    if (
      childName === 'Meters Data' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/Input/meters-data')
    ) {
      return true;
    }

    // FGD Internal Usage Calculations route active
    if (
      childName === 'Calculations' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/calculations/')
    ) {
      return true;
    }

    // FGD Internal Usage Calculations Boilers Motohours Data route active
    if (
      childName === 'Boilers Motohours Data' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FGD/IUS/calculations/boilers-motohours-data'
      )
    ) {
      return true;
    }

    // FGD Internal Usage Calculations Heaters Motohours Data route active
    if (
      childName === 'Heaters Motohours Data' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FGD/IUS/calculations/heaters-motohours-data'
      )
    ) {
      return true;
    }

    // FGD Internal Usage Monthly Report
    if (
      childName === 'Monthly Report' &&
      currentRoute.includes('/TCO/ProcessEng/FGD/IUS/MonthlyReport/')
    ) {
      return true;
    }

    // FGD Internal Usage Monthly Report Monthly Calc
    if (
      childName === 'Monthly Calc' &&
      currentRoute.includes(
        '/TCO/ProcessEng/FGD/IUS/MonthlyReport/monthly-calc'
      )
    ) {
      return true;
    }

    return false;
  }

  // isActive(groupName: string): boolean {

  //   const currentRoute = this.router.url;

  //   //===========================FD===========================//

  //   //FD routes
  //   this.isSubFDActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/'
  //   );

  //   //FD KTL routes
  //   this.isSubFDKTLActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/'
  //   );

  //   //FD KTL INPUT routes
  //   this.isSubFDKTLInputActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/Input/'
  //   );

  //   //FD KTL Daily report routes
  //   this.isSubFDKTLDailyReportActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/DailyReport'
  //   );

  //   //FD KTL Daily report F205 routes
  //   this.isSubFDKTL2F205Active = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/F205'
  //   );

  //   //FD KTL F205 routes
  //   this.isSubFDKTLMonthlyReportActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/MonthlyReport'
  //   );

  //   //FD KTL CC routes
  //   this.isSubFDKTLCCActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/cc'
  //   );

  //   //FD KTL Schema routes
  //   this.isSubFDKTLSchemaActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTL/Schema'
  //   );

  //   //FD SGP routes
  //   this.isSubFDSGPActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/SGP/'
  //   );

  //   //FD SGI routes
  //   this.isSubFDSGIActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/SGI/'
  //   );

  //   //FD FIELD routes
  //   this.isSubFDFIELDActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/field/'
  //   );

  //   //FD FIELD Field calc routes
  //   this.isSubFDFIELDFieldCalcActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/field/field-calc'
  //   );

  //   //FD FIELD final report routes
  //   this.isSubFDFIELDFinalReportActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/field/final-report'
  //   );

  //   //FD LPG routes
  //   this.isSubFDLPGActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/LPG/'
  //   );

  //   //FD SO2 Emissions routes
  //   this.isSubFDSO2EMISSIONSActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/KTLSGP/So2Emissions/'
  //   );

  //   //===========================FGD===========================//

  //   //FGD routes
  //   this.isSubFGDActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FGD/'
  //   );

  //   //FGD KTL routes
  //   this.isSubFGDKTLActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FGD/KTL/'
  //   );

  //   //FGD SGP routes
  //   this.isSubFGDSGPActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FGD/SGP/'
  //   );

  //   //FGD SGI routes
  //   this.isSubFGDSGIActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/SGI/'
  //   );
  // this.isSubFDSGPActive = currentRoute.includes('/TCO/ProcessEng/FD/SGP/');
  // this.isSubFDSGIActive = currentRoute.includes('/TCO/ProcessEng/FD/SGI/');

  // this.isSubFDFIELDActive = currentRoute.includes(
  //   '/TCO/ProcessEng/FD/field/'
  // );
  // this.isSubFDFIELDFieldCalcActive = currentRoute.includes(
  //   '/TCO/ProcessEng/FD/field/field-calc'
  // );
  // this.isSubFDFIELDFinalReportActive = currentRoute.includes(
  //   '/TCO/ProcessEng/FD/field/final-report'
  // );
  // this.isSubFDFIELDInputActive = currentRoute.includes(
  //   '/TCO/ProcessEng/FD/field/input'
  // );

  //   //FGD FIELD routes
  //   this.isSubFGDFIELDActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/field/'
  //   );

  //   //FGD LPG routes
  //   this.isSubFGDLPGActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FD/LPG/'
  //   );

  //   //FGD IUS routes
  //   this.isSubFGDInternalUsageActive = currentRoute.includes(
  //     '/TCO/ProcessEng/FGD/IUS/'
  //   );

  //   return false;

  // }
}
