<div class="row">
    <div class="col">
       
    </div>
    <div class="col">
        <div class="center-content">
            <h1 class="text-center">Page is restricted or there is permissions to view this page</h1>
            <h2 class="text-center">Please contact support if you require access to this page.</h2>
        </div>
    </div>
    <div class="col">

    </div>
</div>