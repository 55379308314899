<div class="icon-wrapper">
  <mat-icon class="icon">error</mat-icon>
</div>

<h1
  mat-dialog-title
  style="text-align: center; color: #0066b2; text-transform: uppercase"
>
  ARE YOU SURE?
</h1>

<div mat-dialog-content class="content">
  <p>
    This action can’t be undone. All values <br />
    associated with this row will be lost.
  </p>
</div>

<div mat-dialog-actions class="button-container">
  <button
    mat-button
    style="background-color: #0066b2; color: white"
    (click)="onDeleteClick()"
    onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
    onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
  >
    {{ action === DeleteDialogAction.Delete ? "DELETE" : "RESET" }}
  </button>
  <button mat-button (click)="onCancelClick()">CANCEL</button>
</div>
