import { createAction, props } from '@ngrx/store';
import { ChemicalCompoundsDTO, ChemicalCompoundsEquipmentDTO, InputRecordDTO, KTLSGPStreamDTO } from 'src/app/web-api-client';

export const getChemicalCompound = createAction(
  '[SGP] Get Chemical Compound',
);
export const getChemicalCompoundSuccess = createAction(
  '[SGP] Get Chemical Compound Success',
  props<{ data: ChemicalCompoundsDTO[]; }>()
);
export const getChemicalCompoundFailure = createAction(
  '[SGP] Get Chemical Compound Failure',
  props<{ message: string }>()
);

export const getChemicalCompoundsEquipment = createAction(
  '[SGP] Get Chemical Compounds Equipment'
);
export const getChemicalCompoundsEquipmentSuccess = createAction(
  '[SGP] Get Chemical Compounds Equipment Success',
  props<{ dataEquipment: ChemicalCompoundsEquipmentDTO[]; }>()
);
export const getChemicalCompoundsEquipmentFailure = createAction(
  '[SGP] Get Chemical Compounds Equipment Failure',
  props<{ message: string }>()
);

export const getStreamsName = createAction(
  '[SGP] Get Streams Name',
);
export const getStreamsNameSuccess = createAction(
  '[SGP] Get Streams Name Success',
  props<{ streams: KTLSGPStreamDTO[]; }>()
);
export const getStreamsNameFailure = createAction(
  '[SGP] Get Streams Name Failure',
  props<{ message: string }>()
);

export const AddManualRecord = createAction(
  '[SGP] Add Manual Record',
  props<InputRecordDTO>()
);
export const AddManualRecordSuccess = createAction(
  '[SGP] Add Manual Record Success',
  props<any>()
);
export const AddManualRecordFailure = createAction(
  '[SGP] Add Manual Record Failure',
  props<{ message: string }>()
);