import { Component, Input, OnInit } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PEService } from 'src/app/core/services/lpg/pe.service';

@Component({
  selector: 'app-shared-dropdown-pe',
  templateUrl: './shared-dropdown-pe.component.html',
  styleUrls: ['./shared-dropdown-pe.component.scss'],
})
export class SharedDropdownPeComponent implements OnInit {
  @Input() peDropdownFormGroup!: FormGroup;

  parks: string[] = []; //cascading dropdown
  gases: string[] = []; //cascading dropdown
  bullets: string[] = []; //cascading dropdown
  isOtherSelected: boolean = false; //other input
  otherInputValue: string = ''; //other input
  purposes: string[] = []; //purpose
  entrytypes: string[] = [];
  MWSelectionPoints: string[] = [];
  categories: string[] = [];

  constructor(private dataService: PEService, private fb: FormBuilder) {
    // this.peDropdownFormGroup = this.fb.group({
    //   lpgPark: ['', Validators.required],
    //   gasType: ['', Validators.required],
    //   purpose: ['', Validators.required],
    //   entryType: ['', Validators.required],
    //   bullet: ['', Validators.required],
    //   mwSelectionPoint: ['', Validators.required],
    //   category: ['', Validators.required],
    //   volume: ['', Validators.required],
    // });
  }

  ngOnInit(): void {
    this.parks = this.dataService.getParks();
    this.purposes = this.dataService.getPurpose();
    this.entrytypes = this.dataService.getEntryType();
    this.categories = this.dataService.getCategory();
    this.gases = [];
    this.bullets = [];
  }

  onParkChange() {
    this.gases = this.dataService.getGasesForPark(
      this.peDropdownFormGroup.value.lpgPark
    );
    this.peDropdownFormGroup.get('gasType')?.enable();
    this.peDropdownFormGroup.patchValue({
      gasType: '',
      bullet: '',
    });
    this.bullets = [];
    this.isOtherSelected = false;
  }

  onGasChange() {
    this.bullets = this.dataService.getOptionsForGas(
      this.peDropdownFormGroup.value.lpgPark,
      this.peDropdownFormGroup.value.gasType
    );
    this.peDropdownFormGroup.get('bullet')?.enable();
    this.peDropdownFormGroup.get('mwSelectionPoint')?.enable();
    this.MWSelectionPoints = this.dataService.getMWSelectionPoints(
      this.peDropdownFormGroup.value.gasType
    );
    this.peDropdownFormGroup.patchValue({
      bullet: '',
    });
    this.isOtherSelected = false;
  }

  onOptionChange() {
    this.isOtherSelected = this.peDropdownFormGroup.value.bullet === 'Other';
    if (!this.isOtherSelected) {
      this.otherInputValue = '';
    }
  }
}
