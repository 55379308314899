import { Injectable } from '@angular/core';
import { FieldClient, LPGClient, SGPFGDClient } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  isLoading: boolean = false;
  blob: any;

  constructor(private lpgClient: LPGClient, private fieldClient: FieldClient, private fgdsgpClient: SGPFGDClient) {}

  exportFlaringReport(dt: Date) {
    this.setIsLoading(true);
    this.lpgClient.exportFlaringReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportAnalystReports(dt: Date) {
    this.setIsLoading(true);
    this.lpgClient.exportAnalystReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportLPGDataReports(dt: Date) {
    this.setIsLoading(true);
    this.lpgClient.exportLPGDataReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportFuelGasFlaringReports(dt: Date) {
    this.setIsLoading(true);
    this.lpgClient.exportFuelGasFlaringReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportChemicalCompositionReports(dt: Date) {
    this.setIsLoading(true);
    this.lpgClient.exportChemicalCompositionReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportFieldDryGasReports(dt: Date) {
    this.setIsLoading(true);
    this.fieldClient.exportDryGas(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportFGDSGPMonthlyFuelGasReports(dt: Date) {
    this.setIsLoading(true);
    this.fgdsgpClient.exportSGPFGDMonthlyReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }

  exportFGDSGPDailyFuelGasReports(dt: Date) {
    this.setIsLoading(true);
    this.fgdsgpClient.exportSGPFGDDailyReports(dt).subscribe((res) => {
      if (res) {
        this.blob = new Blob([res.data]);
        var downloadUrl = window.URL.createObjectURL(res.data);
        var link = document.createElement('a');
        link.href = downloadUrl;
        link.download = res.fileName!;
        link.click();
      }
      this.setIsLoading(false);
    });
  }


  setIsLoading(loading: boolean) {
    this.isLoading = loading;
  }

  getIsLoading() {
    return this.isLoading;
  }
}
