import { createAction, props } from '@ngrx/store';
import { KTLSGPSO2RecordDTO } from 'src/app/web-api-client';
import { KTLSGPSO2CalculatedReportDTO } from 'src/app/web-api-client';

export const ListSO2Emissions = createAction(
  '[SO2Emissions] List SO2 Emissions',
  props<{ startTime: Date }>()
);
export const ListSO2EmissionsSuccess = createAction(
  '[SO2Emissions] List SO2 Emissions Success',
  props<{ data: KTLSGPSO2CalculatedReportDTO[] }>()
);
export const ListSO2EmissionsFailure = createAction(
  '[SO2Emissions] List SO2 Emissions Failure',
  props<{ message: string }>()
);
export const SO2EmissionsEdit = createAction(
  '[SO2Emissions] SO2 Emissions Edit',
  props<KTLSGPSO2RecordDTO>()
);
export const SO2EmissionsEditSuccess = createAction(
  '[SO2Emissions] SO2 Emissions Edit Success',
  props<any>()
);
export const SO2EmissionsEditFailure = createAction(
  '[SO2Emissions] SO2 Emissions Edit Failure',
  props<{ message: string }>()
);
