import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGroups } from '../../../../../../web-api-client';
import { RoleGuard } from '../../../../../../core/guards/role.guard';
import { RedirectTypes } from '../../../../../../core/models/redirect-types.enum';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'Analyst',
        loadChildren: () => import('./analyst/analyst.module').then(m => m.AnalystModule),
        data: {
          expectedRole: [UserGroups.LPG_Engineer, UserGroups.LPG_ProdPlanAnalyst],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'Operator',
        loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule),
        data: {
          expectedRole: [UserGroups.LPG_Engineer, UserGroups.LPG_Operator],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'PE',
        loadChildren: () => import('./pe/pe.module').then(m => m.PeModule),
        data: {
          expectedRole: [UserGroups.LPG_Engineer],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'TargetPermit',
        loadChildren: () => import('./target-permit/target-permit.module').then(m => m.TargetPermitModule),
        data: {
          expectedRole: [UserGroups.LPG_Engineer],
          redirectCase: RedirectTypes.LPG
        },
        canActivate: [RoleGuard],
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FDInputRoutingModule {}
