import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuelGasFlaringEditComponent } from 'src/app/features/tco/processeng/fd/lpg/fuel-gas-flaring/fuel-gas-flaring-edit/fuel-gas-flaring-edit.component';

@Injectable({
  providedIn: 'root',
})
export class FuelGasFlaringService {

constructor(private dialog: MatDialog) {}

  openEditDialog(data: any) {
    const dialogRef = this.dialog.open(FuelGasFlaringEditComponent, {
      width: '800px', // Adjust the width as needed
      data: data, // Pass the data to the dialog
    });

    return dialogRef.afterClosed();
  }
}