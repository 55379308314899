import { createAction, props } from '@ngrx/store';
import { F205CalcDTO, F205DTO, F205TotalCalcDTO, F205TotalDTO } from 'src/app/web-api-client';

export const getF205 = createAction(
  '[KTL] Get F205',
  props<{ startdt: Date; enddt: Date }>()
);
export const getF205Success = createAction(
  '[KTL] Get F205 Success',
  props<{ data: F205TotalDTO }>()
);
export const getF205Failure = createAction(
  '[KTL] Get F205 Failure',
  props<{ message: string }>()
);

// export const getF205D = createAction(
//   '[KTL] Get F205',
//   props<{ startdt: Date; enddt: Date }>()
// );
// export const getF205DSuccess = createAction(
//   '[KTL] Get F205 Success',
//   props<{ data: F205DTO }>()
// );
// export const getF205DFailure = createAction(
//   '[KTL] Get F205 Failure',
//   props<{ message: string }>()
// );

// export const getF205S = createAction(
//   '[KTL] Get F205 S',
//   props<{ startdt: Date; enddt: Date }>()
// );
// export const getF205SSuccess = createAction(
//   '[KTL] Get F205 S Success',
//   props<{ data: F205CalcDTO }>()
// );
// export const getF205SFailure = createAction(
//   '[KTL] Get F205 S Failure',
//   props<{ message: string }>()
// );

// export const getF205T = createAction(
//   '[KTL] Get F205 T',
//   props<{ startdt: Date; enddt: Date }>()
// );
// export const getF205TSuccess = createAction(
//   '[KTL] Get F205 T Success',
//   props<{ data: F205TotalCalcDTO }>()
// );
// export const getF205TFailure = createAction(
//   '[KTL] Get F205 T Failure',
//   props<{ message: string }>()
// );

