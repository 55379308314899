import { createAction, props } from '@ngrx/store';
import { SO2FlowmeterGraphDTO } from 'src/app/web-api-client';

export const getSO2EmissionsFlowmeterGraph = createAction(
  '[SO2 Charts] SO2 Emissions Flowmeter Graph',
  props<{ date: Date; plant: number; areaType: number }>()
);
export const getSO2EmissionsFlowmeterGraphSuccess = createAction(
  '[SO2 Charts] SO2 Emissions Flowmeter Graph Success',
  props<{ data: SO2FlowmeterGraphDTO[] }>()
);
export const getSO2EmissionsFlowmeterGraphFailure = createAction(
  '[SO2 Charts] SO2 Emissions Flowmeter Graph Failure',
  props<{ message: string }>()
);
