import { createAction, props } from '@ngrx/store';
import { SGPFGDCalculatedDTO, SGPFGDInputRecordDTO, SGPFGDOverrideRecordDTO } from 'src/app/web-api-client';

export const getDailyFuelGas = createAction(
  '[SGP] Get Daily Fuel Gas',
  props<{ startTime: Date }>()
);
export const getDailyFuelGasSuccess = createAction(
  '[SGP] Get Daily Fuel Gas Success',
  props<{ data: SGPFGDCalculatedDTO[] }>()
);
export const getDailyFuelGasFailure = createAction(
  '[SGP] Get Daily Fuel Gas Failure',
  props<{ message: string }>()
);

export const getIsDailyRecordExist = createAction(
  '[SGP] Get Is Daily Record Exist',
  props<{ startTime: Date }>()
);
export const getIsDailyRecordExistSuccess = createAction(
  '[SGP] Get Is Daily Record Exist Success',
  props<{ isDailyRecordExistdata: boolean }>()
);
export const getIsDailyRecordExistFailure = createAction(
  '[SGP] Get Is Daily Record Exist Failure',
  props<{ message: string }>()
);

export const getMonthlyFuelGas = createAction(
  '[SGP] Get Monthly Fuel Gas',
  props<{ startTime: Date }>()
);
export const getMonthlyFuelGasSuccess = createAction(
  '[SGP] Get Monthly Fuel Gas Success',
  props<{ data: SGPFGDCalculatedDTO[] }>()
);
export const getMonthlyFuelGasFailure = createAction(
  '[SGP] Get Monthly Fuel Gas Failure',
  props<{ message: string }>()
);

export const FGDSGPManualRecord = createAction(
  '[SGP] Manual Record',
  props<SGPFGDInputRecordDTO>()
);
export const FGDSGPManualRecordSuccess = createAction(
  '[SGP] Manual Record Success',
  props<any>()
);
export const FGDSGPManualRecordFailure = createAction(
  '[SGP] Manual Record Failure',
  props<{ message: string }>()
);

export const AddOverrideManualRecord = createAction(
  '[SGP] Add Override Manual Record',
  props<{props: SGPFGDOverrideRecordDTO}>()
);
export const AddOverrideManualRecordSuccess = createAction(
  '[SGP] Add Override Manual Record Success',
  props<any>()
);
export const AddOverrideManualRecordFailure = createAction(
  '[SGP] Add Override Manual Record Failure',
  props<{ message: string }>()
);

export const AddOverrideManualRecords = createAction(
  '[SGP] Add Override Manual Records',
  props<{date: Date; props: SGPFGDOverrideRecordDTO[]}>()
);
export const AddOverrideManualRecordsSuccess = createAction(
  '[SGP] Add Override Manual Records Success',
  props<any>()
);
export const AddOverrideManualRecordsFailure = createAction(
  '[SGP] Add Override Manual Records Failure',
  props<{ message: string }>()
);

export const DeleteOverrideRecord = createAction(
  '[SGP] Override Record Delete',
  props<{ streamId: number; startTime: Date }>()
);
export const DeleteOverrideRecordSuccess = createAction(
  '[SGP] Override Record Delete Success',
  props<any>()
);
export const DeleteOverrideRecordFailure = createAction(
  '[SGP] Override Record Delete Failure',
  props<{ message: string }>()
);