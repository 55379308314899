import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  switchMap,
  mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

import * as notificationActions from './notification.action';
import * as analystActions from '../lpg/analyst/analyst.actions';
import * as operatorActions from '../lpg/operator/operator.actions';
import * as peActions from '../lpg/pe/pe.action';
import * as chartActions from '../chartjs/chartjs.action';
import * as ccActions from '../lpg/cc/cc.actions';
import * as fuelGasFlaringActions from '../lpg/fuel-gas-flaring/fuel-gas-flaring.actions';
import * as flaringReportActions from '../lpg/flaring-report/flaring-report.actions';
import * as lpgDataActions from '../lpg/lpg-data/lpg-data.actions';
import * as projectedDataActions from '../ktlsgp/projected-data/projected-data.actions';
import * as so2EmissionsActions from '../ktlsgp/so2-emissions/so2-emissions.actions';
import * as so2ChartsActions from '../ktlsgp/so2-charts/so2-charts.action';
import * as so2RecalculationActions from '../ktlsgp/so2-emissions-recalculation/so2-emissions-recalculation.actions';
import * as TargetPermitActions from '../lpg/target-permit/target-permit.actions';
import * as approveActions from '../lpg/approve/approve.actions';
import * as FlowmeterCalcActions from '../lpg/flowmeter-calculation/flowmeter-calculation.action';
import * as SgiActions from '../../features/tco/processeng/fd/sgi/store/sgi.action';
import * as FuelGasActions from '../../features/tco/processeng/fgd/sgp/fuel-gas-dashboard/state/fuel-gas.actions';
import * as KTLFGDFuelGasActions from '../../features/tco/processeng/fgd/ktl/production-gas-balance/state/ktl-fgd-fuel-gas.actions';
import * as InternalUsageActions from '../../features/tco/processeng/fgd/ius/store/internal-usage.action';
import * as KTLFDFlaringActions from '../../features/tco/processeng/fd/ktl/input/process-engineer/flaring/state/flaring.actions';
import * as KTLFDDailyReportActions from '../../features/tco/processeng/fd/ktl/daily-report/state/daily-report.actions';
import * as GURActions from '../../features/tco/processeng/fgd/sgp/gas-usage-report/state/gas-usage-report.actions';
import * as F205Actions from '../../features/tco/processeng/fd/ktl/f-205/state/f-205.actions';

@Injectable({
  providedIn: 'root',
})
export class NotificationEffects {
  constructor(private actions: Actions) {}

  showNotification = createEffect(() =>
    this.actions.pipe(
      ofType(notificationActions.showNotification),
      switchMap((action) => {
        if (action.isSuccess)
          return [
            notificationActions.showNotificationSuccess({
              message: action.message ?? 'Success: Your action was successful!',
            }),
          ];
        else
          return [
            notificationActions.showNotificationFailure({
              message: action.message ?? 'Error: Something went wrong. Please try again.',
            }),
          ];
      })
    )
  );

  showCustomNotification = createEffect(() =>
    this.actions.pipe(
      ofType(notificationActions.showCustomNotification),
      switchMap((action) => {
        console.log(action);
        if (action.isSuccess)
          return [
            notificationActions.showNotificationSuccess({
              message: action.message,
            }),
          ];
        else
          return [
            notificationActions.showNotificationFailure({
              message: action.message,
            }),
          ];
      })
    )
  );

  loadingEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        analystActions.AddAnalyst,
        analystActions.ListAnalyst,
        analystActions.EditAnalyst,
        analystActions.DeleteAnalyst,
        operatorActions.AddOperator,
        operatorActions.DeleteOperator,
        operatorActions.EditOperator,
        operatorActions.ListOperator,
        peActions.PEAdd,
        peActions.PEEdit,
        peActions.PEDelete,
        peActions.PEList,
        chartActions.getChartData,
        ccActions.GetCCData,
        fuelGasFlaringActions.FuelGasFlaringData,
        fuelGasFlaringActions.EditFuelGasFlaringData,
        flaringReportActions.FlaringReportData,
        lpgDataActions.LPGData,
        projectedDataActions.AddProjectedData,
        projectedDataActions.ListProjectedData,
        projectedDataActions.DeleteProjectedData,
        so2EmissionsActions.ListSO2Emissions,
        so2EmissionsActions.SO2EmissionsEdit,
        so2ChartsActions.getSO2EmissionsFlowmeterGraph,
        so2RecalculationActions.ListSO2EmissionsRecalculation,
        so2RecalculationActions.AddSO2EmissionsRecalculation,
        so2RecalculationActions.DeleteSO2EmissionsRecalculation,
        TargetPermitActions.AddTargetPermit,
        TargetPermitActions.ListTargetPermit,
        approveActions.LPGApproveByChef,
        FlowmeterCalcActions.LPGFlowmeterCalculationInputAdd,
        SgiActions.getDailyFlareReport,
        SgiActions.saveManualRecord,
        SgiActions.approveSgiFlareReport,
        SgiActions.getBlowDownRecords,
        SgiActions.saveBlowDownRecord,
        SgiActions.deleteBlowDownRecord,
        SgiActions.getTargetPermitRecords,
        SgiActions.saveTargetPermitRecord,
        SgiActions.getChemicalCompositionRecords,
        SgiActions.exportMonthlyReport,
        SgiActions.editFlareRecordVolume,
        SgiActions.deleteFlareRecordVolume,
        FuelGasActions.FGDSGPManualRecord,
        FuelGasActions.AddOverrideManualRecord,
        InternalUsageActions.getBoilerRecords,
        InternalUsageActions.getHeaterRecords,
        InternalUsageActions.getMetersRecords,
        InternalUsageActions.getMonthlyReports,
        InternalUsageActions.approveMonthlyReport,
        KTLFDFlaringActions.AddManualRecord,
        KTLFDDailyReportActions.AddGenerateReports,
        GURActions.getGUR,
        GURActions.FGDSGPGURManualRecord,
        F205Actions.getF205
      ), // Customize this to your actions
      mergeMap(() => {
        // Dispatch the start loading action
        return of(notificationActions.startLoading());
      })
    )
  );

  stopLoadingEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        analystActions.AddAnalystSuccess,
        analystActions.AddAnalystFailure,
        analystActions.DeleteAnalystSuccess,
        analystActions.DeleteAnalystFailure,
        analystActions.EditAnalystSuccess,
        analystActions.EditAnalystFailure,
        analystActions.ListAnalystSuccess,
        analystActions.ListAnalystFailure,
        operatorActions.AddOperatorSuccess,
        operatorActions.AddOperatorFailure,
        operatorActions.DeleteOperatorSuccess,
        operatorActions.DeleteOperatorFailure,
        operatorActions.EditOperatorSuccess,
        operatorActions.EditOperatorFailure,
        operatorActions.ListOperatorSuccess,
        operatorActions.ListOperatorFailure,
        peActions.PEAddSuccess,
        peActions.PEAddFailure,
        peActions.PEEditSuccess,
        peActions.PEEditFailure,
        peActions.PEDeleteSuccess,
        peActions.PEDeleteFailure,
        peActions.PEListSuccess,
        peActions.PEListFailure,
        chartActions.getChartDataSuccess,
        chartActions.getChartDataFailure,
        ccActions.GetCCDataSuccess,
        ccActions.GetCCDataFailure,
        fuelGasFlaringActions.FuelGasFlaringDataSuccess,
        fuelGasFlaringActions.FuelGasFlaringDataFailure,
        fuelGasFlaringActions.EditFuelGasFlaringDataSuccess,
        fuelGasFlaringActions.EditFuelGasFlaringDataFailure,
        flaringReportActions.FlaringReportDataSuccess,
        flaringReportActions.FlaringReportDataFailure,
        lpgDataActions.LPGDataSuccess,
        lpgDataActions.LPGDataFailure,
        projectedDataActions.AddProjectedDataSuccess,
        projectedDataActions.AddProjectedDataFailure,
        projectedDataActions.ListProjectedDataSuccess,
        projectedDataActions.ListProjectedDataFailure,
        projectedDataActions.DeleteProjectedDataSuccess,
        projectedDataActions.DeleteProjectedDataFailure,
        so2EmissionsActions.ListSO2EmissionsSuccess,
        so2EmissionsActions.ListSO2EmissionsFailure,
        so2EmissionsActions.SO2EmissionsEditSuccess,
        so2EmissionsActions.SO2EmissionsEditFailure,
        so2ChartsActions.getSO2EmissionsFlowmeterGraphSuccess,
        so2ChartsActions.getSO2EmissionsFlowmeterGraphFailure,
        so2RecalculationActions.AddSO2EmissionsRecalculationSuccess,
        so2RecalculationActions.AddSO2EmissionsRecalculationFailure,
        so2RecalculationActions.ListSO2EmissionsRecalculationSuccess,
        so2RecalculationActions.ListSO2EmissionsRecalculationFailure,
        so2RecalculationActions.DeleteSO2EmissionsRecalculationSuccess,
        so2RecalculationActions.AddSO2EmissionsRecalculationFailure,
        TargetPermitActions.AddTargetPermitSuccess,
        TargetPermitActions.AddTargetPermitFailure,
        TargetPermitActions.ListTargetPermitSuccess,
        TargetPermitActions.ListTargetPermitFailure,
        approveActions.LPGApproveByChefSuccess,
        approveActions.LPGApproveByChefFailure,
        FlowmeterCalcActions.LPGFlowmeterCalculationInputAddSuccess,
        FlowmeterCalcActions.LPGFlowmeterCalculationInputAddFailure,
        SgiActions.getDailyFlareReportSuccess,
        SgiActions.getDailyFlareReportFailure,
        SgiActions.saveManualRecordSuccess,
        SgiActions.saveManualRecordFailure,
        SgiActions.approveSgiFlareReportSuccess,
        SgiActions.approveSgiFlareReportFailure,
        SgiActions.getBlowDownRecordsSuccess,
        SgiActions.getBlowDownRecordsFailure,
        SgiActions.saveBlowDownRecordSuccess,
        SgiActions.saveBlowDownRecordFailure,
        SgiActions.deleteBlowDownRecordSuccess,
        SgiActions.deleteBlowDownRecordFailure,
        SgiActions.getTargetPermitRecordsSuccess,
        SgiActions.getTargetPermitRecordsFailure,
        SgiActions.saveTargetPermitRecordSuccess,
        SgiActions.saveTargetPermitRecordFailure,
        SgiActions.getChemicalCompositionRecordsSuccess,
        SgiActions.getChemicalCompositionRecordsFailure,
        SgiActions.exportMonthlyReportSuccess,
        SgiActions.exportMonthlyReportFailure,
        SgiActions.editFlareRecordVolumeSuccess,
        SgiActions.editFlareRecordVolumeFailure,
        SgiActions.deleteFlareRecordVolumeSuccess,
        SgiActions.deleteFlareRecordVolumeFailure,
        FuelGasActions.FGDSGPManualRecordSuccess,
        FuelGasActions.FGDSGPManualRecordFailure,
        FuelGasActions.AddOverrideManualRecordSuccess,
        FuelGasActions.AddOverrideManualRecordFailure,
        InternalUsageActions.getBoilerRecordsSuccess,
        InternalUsageActions.getBoilerRecordsFailure,
        InternalUsageActions.getHeaterRecordsSuccess,
        InternalUsageActions.getHeaterRecordsFailure,
        InternalUsageActions.getMetersRecordsSuccess,
        InternalUsageActions.getMetersRecordsFailure,
        InternalUsageActions.getMonthlyReportsSuccess,
        InternalUsageActions.getMonthlyReportsFailure,
        InternalUsageActions.approveMonthlyReportSuccess,
        InternalUsageActions.approveMonthlyReportFailure,
        KTLFDFlaringActions.AddManualRecordSuccess,
        KTLFDFlaringActions.AddManualRecordFailure,
        KTLFDDailyReportActions.AddGenerateReportsSuccess,
        KTLFDDailyReportActions.AddGenerateReportsFailure,
        GURActions.getGURSuccess,
        GURActions.getGURFailure,
        GURActions.FGDSGPGURManualRecordSuccess,
        GURActions.FGDSGPGURManualRecordFailure,
        F205Actions.getF205Success,
        F205Actions.getF205Failure
      ), // Customize this to your actions
      mergeMap(() => {
        // Dispatch the stop loading action
        return of(notificationActions.stopLoading());
      })
    )
  );
}
