import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../../state/auth/auth.action';
import { AppState } from 'src/app/core/models/appState.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private store: Store<AppState>) {}

  login() {
    this.store.dispatch(AuthActions.getUserGroups());
  }
}
