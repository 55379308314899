import { Component, Input, OnInit } from '@angular/core';
import { TitleService } from 'src/app/core/services/dynamictools/title.service';

@Component({
  selector: 'app-dynamic-title',
  templateUrl: './dynamic-title.component.html',
})
export class DynamicTitleComponent implements OnInit {
  @Input() pageTitle: string = '';

  constructor(private titleService: TitleService) {}

  ngOnInit() {
    this.titleService.setTitle(this.pageTitle);
  }
}
