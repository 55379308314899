import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

import { API_BASE_URL } from './web-api-client';
import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { StateModule } from './state/state.module';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';
import { FinalReportModule } from './features/tco/processeng/fd/lpg/final-report/final-report.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/components/shared-navbar/navbar.component';
import { AuthService } from './core/services/auth/auth.service';
import { AppInitializer } from './app.initializer';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TCOLanguages } from './core/models/i18n.model';

import { ToastrModule } from 'ngx-toastr';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    // General modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // App modules
    AppRoutingModule,
    SharedModule,

    // State module
    StateModule,

    // Material module
    MaterialModule,

    // Feature modules
    FinalReportModule,

    // Internalization module
    TranslateModule.forRoot({
      defaultLanguage: TCOLanguages.RU,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useValue: environment.API_BASE_URL_Append,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
