import { Action, createReducer, on } from '@ngrx/store';
import * as FuelGasActions from './ktl-fgd-fuel-gas.actions';
import { CalculatedStreamDTO } from 'src/app/web-api-client';

export interface KTLFGDFuelGasState{
  data: CalculatedStreamDTO[] | null ;
  isDailyRecordExistdata: boolean | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: KTLFGDFuelGasState = {
  data: null,
  isDailyRecordExistdata: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const KTLFGDFuelGasReducer = createReducer(
  initialState,
  on(FuelGasActions.getDailyFuelGas, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(FuelGasActions.getDailyFuelGasSuccess, (state, result) => ({
    ...state,
    data: result.data,
    // isDailyRecordExistdata: null,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(FuelGasActions.getDailyFuelGasFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  // on(FuelGasActions.getMonthlyFuelGas, (state, result) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(FuelGasActions.getMonthlyFuelGasSuccess, (state, result) => ({
  //   ...state,
  //   data: result.data,
    // isDailyRecordExistdata: null,
  //   result,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  //   isLoadingFailure: false,
  // })),
  // on(FuelGasActions.getMonthlyFuelGasFailure, (state, result) => ({
  //   ...state,
  //   result,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // })),
  // on(FuelGasActions.AddDailyManualRecord, (state, payload) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(FuelGasActions.AddDailyManualRecordSuccess, (state, payload) => ({
  //   ...state,
  //   result: payload,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  // })),
  // on(FuelGasActions.AddDailyManualRecordFailure, (state, payload) => ({
  //   ...state,
  //   result: payload.message,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // })),
  // on(FuelGasActions.AddMonthlyManualRecord, (state, payload) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(FuelGasActions.AddMonthlyManualRecordSuccess, (state, payload) => ({
  //   ...state,
  //   result: payload,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  // })),
  // on(FuelGasActions.AddMonthlyManualRecordFailure, (state, payload) => ({
  //   ...state,
  //   result: payload.message,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // })),
  // on(FuelGasActions.AddOverrideManualRecord, (state, payload) => ({
  //   ...state,
  //   isLoading: true,
  // })),
  // on(FuelGasActions.AddOverrideManualRecordSuccess, (state, payload) => ({
  //   ...state,
  //   result: payload,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  // })),
  // on(FuelGasActions.AddOverrideManualRecordFailure, (state, payload) => ({
  //   ...state,
  //   result: payload.message,
  //   isLoading: false,
  //   isLoadingFailure: true,
  // })),
  on(FuelGasActions.getIsDailyRecordExist, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(FuelGasActions.getIsDailyRecordExistSuccess, (state, result) => ({
    ...state,
    // data: null,
    isDailyRecordExistdata: result.isDailyRecordExistdata,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(FuelGasActions.getIsDailyRecordExistFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(FuelGasActions.DeleteOverrideRecord, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  // on(FuelGasActions.DeleteOverrideRecordSuccess, (state, result) => ({
  //   data: result.data,
  //   isDailyRecordExistdata: null,
  //   result,
  //   isLoading: false,
  //   isLoadingSuccess: true,
  //   isLoadingFailure: false
  // })),
  on(FuelGasActions.DeleteOverrideRecordFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);