import { Action, createReducer, on } from '@ngrx/store';
import * as PEAction from './pe.action';
import { LPGPEInputDTO } from 'src/app/web-api-client';

export interface PEState {
  data: LPGPEInputDTO[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: PEState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const PEReducer = createReducer(
  initialState,
  on(PEAction.PEAdd, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(PEAction.PEAddSuccess, (state, result) => ({
    data: result,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(PEAction.PEAddFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(PEAction.PEList, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(PEAction.PEListSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(PEAction.PEListFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(PEAction.PEEdit, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(PEAction.PEEditSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(PEAction.PEEditFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(PEAction.PEDelete, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(PEAction.PEDeleteSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(PEAction.PEDeleteFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
