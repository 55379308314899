<!-- <div class="row">
  <div class="col">
    <div class="List">
      <mat-card>
        <a [routerLink]="['/TCOList']">List View</a>
      </mat-card>
    </div>
  </div>
</div> -->

<div class="FlareFuelField" id="flareFuelField"  (click)="navigateTo('/TCO/ProcessEng/FD/field/field-calc/dry-gas')"> 
  <mat-card>
    <img src="./assets/Flare&FuelGas.png">
    <mat-divider></mat-divider>
    <mat-card-footer>
      Flare&Fuel Gas ​Dashboard for Field​
    </mat-card-footer>
  </mat-card>
</div>

<div class="KTL" id="ktl">
  <mat-card>
    <img src="./assets/KTL.png">
    <mat-divider></mat-divider>
    <mat-card-footer>
      KTL
    </mat-card-footer>
  </mat-card>
</div>

<div class="FlareKTL" (click)="navigateTo('/TCO/ProcessEng/FD/KTL/Input/ProcessEngineer/Flaring')">
  <mat-card>
    <img src="./assets/Flare​.png" style="width: 30%;">
    <mat-divider></mat-divider>
    <mat-card-footer>
      Flare​ Dashboard​ for KTL​​
    </mat-card-footer>
  </mat-card>
</div>

<div class="FuelKTL" (click)="navigateTo('/TCO/ProcessEng/FGD/KTL/ProductionGasBalance')"> 
  <mat-card>
    <img src="./assets/FuelGas​.png" style="width: 30%; margin-bottom: 5px;">
    <mat-divider></mat-divider>
    <mat-card-footer>
      Fuel Gas​ Dashboard​ For KTL​
    </mat-card-footer>
  </mat-card>
</div>

<div class="FlareFuelLPG" (click)="navigateTo('/TCO/ProcessEng/FD/LPG/Input/TargetPermit')">
  <mat-card>
    <img src="./assets/FlareFuelLPG.png"  style="margin-bottom: 10px;">
    <mat-divider></mat-divider>
    <mat-card-footer>
      Flare&Fuel Gas Dashboard for LPG​
    </mat-card-footer>
  </mat-card>
</div>

<div class="FuelGasInternalUsage" (click)="navigateTo('/TCO/ProcessEng/FGD/IUS/Input/boilers-motohours-data')">
  <mat-card>
    <img src="./assets/FuelGasIU.png" >
    <mat-divider></mat-divider>
    <mat-card-footer>
      Fuel Gas Dashboard for​ Internal Usage​
    </mat-card-footer>
  </mat-card>
</div>

<div class="SGP">
  <mat-card>
    <img src="./assets/KTL.png" >
    <mat-divider></mat-divider>
    <mat-card-footer>
      SGP
    </mat-card-footer>
  </mat-card>
</div>

<div class="FlareSGP" (click)="navigateTo('/TCO/ProcessEng/FD/SGP/Input/ProcessEngineer/Flaring')">
  <mat-card>
    <img src="./assets/Flare​.png" style="width: 30%;">
    <mat-divider></mat-divider>
    <mat-card-footer>
      Flare​ Dashboard​ for SGP
    </mat-card-footer>
  </mat-card>
</div>

<div class="FuelSGP" (click)="navigateTo('TCO/ProcessEng/FGD/SGP')"> 
  <mat-card>
    <img src="./assets/FuelGas​.png" style="width: 30%; margin-bottom: 5px;">
    <mat-divider></mat-divider>
    <mat-card-footer>
      Fuel Gas​ Dashboard​ For SGP
    </mat-card-footer>
  </mat-card>
</div>

<div class="SGI" (click)="navigateTo('/TCO/ProcessEng/FD/SGI/flare-report')">
  <mat-card>
    <img src="./assets/SGI.png" >
    <mat-divider></mat-divider>
    <mat-card-footer>
      SGI Dashboard​
    </mat-card-footer>
  </mat-card>
</div>

<div class="SO2" (click)="navigateTo('/TCO/ProcessEng/FD/KTLSGP/So2Emissions/MonthlyReport')">
  <mat-card>
    <img src="./assets/SO2Emissions.png" >
    <mat-divider></mat-divider>
    <mat-card-footer>
      SO2 Emissions​ Dashboard​
    </mat-card-footer>
  </mat-card>
</div>