import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProjectedDataActions from './projected-data.actions';
import * as notificationActions from '../../notification/notification.action';
import { SO2Client } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class ProjectedDataEffects {
  constructor(private actions: Actions, private so2Client: SO2Client) {}

  addProjectedData = createEffect(() =>
    this.actions.pipe(
      ofType(ProjectedDataActions.AddProjectedData),
      exhaustMap((action) => {
        return this.so2Client.inputProjectedData(action).pipe(
          concatMap((res) => [
            ProjectedDataActions.AddProjectedDataSuccess(res),
            // ProjectedDataActions.ListProjectedData({
            //   startTime: action.startTime,
            //   pageNumber: action.pageNumber,
            //   pageSize: action.pageSize
            // }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(ProjectedDataActions.AddProjectedDataFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  listProjectedData = createEffect(() =>
    this.actions.pipe(
      ofType(ProjectedDataActions.ListProjectedData),
      exhaustMap((action) =>
        this.so2Client
          .getProjectedData(new Date(action.startTime), action.pageNumber, action.pageSize)
          .pipe(
            map((res) =>// [
              ProjectedDataActions.ListProjectedDataSuccess({ data: res }),
              // notificationActions.showNotification({isSuccess: true}),
              // notificationActions.stopLoading()
            //]
              
            ),
            catchError((err) => of(ProjectedDataActions.ListProjectedDataFailure(err)))
          )
      )
    )
  );
  
  showNotification = createEffect(() =>
    this.actions.pipe(
      ofType(
        ProjectedDataActions.ListProjectedDataFailure,
        ProjectedDataActions.AddProjectedDataSuccess
      ),
      map((action) => //[
        // notificationActions.stopLoading(),
      // notificationActions.clearNotification()
        notificationActions.showNotification({ isSuccess: action.type === ProjectedDataActions.ListProjectedDataSuccess.type }),

        // notificationActions.stopLoading()
        // notificationActions.clearNotification()
     // ]
        
      )
    )
  );

  deleteProjectedData = createEffect(() =>
  this.actions.pipe(
    ofType(ProjectedDataActions.DeleteProjectedData),
    exhaustMap((action) => {
      return this.so2Client.deleteProjectedData(action.id).pipe(
        concatMap((res) => [
          ProjectedDataActions.DeleteProjectedDataSuccess(res),
          ProjectedDataActions.ListProjectedData({ 
            startTime: action.startTime,
            pageNumber: action.pageNumber,
            pageSize: action.pageSize
           }),
          notificationActions.showNotification({ isSuccess: true }),
        ]),
        catchError((err) => [
          of(ProjectedDataActions.DeleteProjectedDataFailure(err)),
          notificationActions.showNotification({ isSuccess: false }),
        ])
      );
    })
  )
);

}