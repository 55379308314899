<!-- <app-tab [pageTitle]="pageTitle" [buttons2]="finalReportButtons2"></app-tab> -->
<!-- [buttons]="finalReportButtons"-->

<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <label>Month</label>
            <div class="input-group mb-2 mr-sm-2">
              <div>
                <input
                  type="month"
                  id="monthPicker"
                  name="logged"
                  class="form-control"
                  [(ngModel)]="month"
                  (ngModelChange)="monthChange()"
                />
              </div>
              <div>
                <button
                  class="ml-4"
                  mat-raised-button
                  style="background-color: #0066b2; color: white"
                  onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
                  onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
                  (click)="exportToExcel()"
                >
                  Export
                </button>
              </div>
              <div class="ml-4" *ngIf="getLoading()">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <h1>MONTHLY GAS FLARING REPORT</h1>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <table
            mat-table
            class="mat-elevation-z8"
            [dataSource]="monthlyGasFlarinReport"
          >
            <!-- elementName Column -->
            <ng-container matColumnDef="elementName">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item">{{ item.elementName }}</td>
            </ng-container>

            <!-- totalVolume Column -->
            <ng-container matColumnDef="totalVolume">
              <th mat-header-cell *matHeaderCellDef>Volume, kNm3</th>
              <td mat-cell *matCellDef="let item">
                {{ formatNumberMV(item.totalVolume) }}
              </td>
            </ng-container>

            <!-- totalVolumekSm3 Column -->
            <ng-container matColumnDef="totalVolumekSm3">
              <th mat-header-cell *matHeaderCellDef>Volume, kSm3</th>
              <td mat-cell *matCellDef="let item">
                {{ formatNumberMV(item.totalVolumekSm3) }}
              </td>
            </ng-container>

            <!-- totalMass Column -->
            <ng-container matColumnDef="totalMass">
              <th mat-header-cell *matHeaderCellDef>Mass, tons</th>
              <td mat-cell *matCellDef="let item">
                {{ formatNumberMV(item.totalMass) }}
              </td>
            </ng-container>

            <div class="mat-row" *matNoDataRow>
              <div class="mat-cell">No records found</div>
            </div>

            <!-- Repeat the pattern for other columns... -->
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns"
              class="header-row"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="example-element-row"
            ></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <h1>FUEL GAS FLARING</h1>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <table
            mat-table
            class="mat-elevation-z8"
            [dataSource]="fuelGasFlaring"
          >
            <!-- elementName Column -->
            <ng-container matColumnDef="elementName">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item">{{ item.elementName }}</td>
            </ng-container>

            <!-- totalVolume Column -->
            <ng-container matColumnDef="totalVolume">
              <th mat-header-cell *matHeaderCellDef>Volume, kNm3</th>
              <td mat-cell *matCellDef="let item">
                {{ formatNumberMV(item.totalVolume) }}
              </td>
            </ng-container>

            <!-- totalMass Column -->
            <ng-container matColumnDef="totalMass">
              <th mat-header-cell *matHeaderCellDef>Mass, tons</th>
              <td mat-cell *matCellDef="let item">
                {{ formatNumberMV(item.totalMass) }}
              </td>
            </ng-container>

            <!-- comment Column -->
            <ng-container matColumnDef="comment">
              <th mat-header-cell *matHeaderCellDef>Comment</th>
              <td mat-cell *matCellDef="let item">{{ item.comment }}</td>
            </ng-container>

            <!-- updatedBy Column -->
            <ng-container matColumnDef="updatedBy">
              <th mat-header-cell *matHeaderCellDef>Updated By</th>
              <td mat-cell *matCellDef="let item">{{ item.updatedBy }}</td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>Edit</th>
              <td mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="onEditClick(item)">
                  <mat-icon class="Edit">edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <div class="mat-row" *matNoDataRow>
              <div class="mat-cell">No records found</div>
            </div>

            <!-- Repeat the pattern for other columns... -->
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns2"
              class="header-row"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns2"
              class="example-element-row"
            ></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
