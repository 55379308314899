import { createAction, props } from '@ngrx/store';
import { LPGFuelGasFlaringDefaultDTO } from 'src/app/web-api-client';

export const getFuelGasFlaringDefault = createAction(
  '[Fuel Gas Flaring] Get Fuel Gas Flaring Defaults',
  props<{ date: Date }>()
);
export const getFuelGasFlaringDefaultSuccess = createAction(
  '[Fuel Gas Flaring] Get Fuel Gas Flaring Default Success',
  props<{ payload: LPGFuelGasFlaringDefaultDTO }>()
);
export const getFuelGasFlaringDefaultFailure = createAction(
  '[Fuel Gas Flaring] Get Fuel Gas Flaring Failure',
  props<{ error: string }>()
);
