import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectedDataActions from './projected-data.actions';
import { IPagedListOfKTLSGPSO2ProjectedDataInputDTO, KTLSGPSO2ProjectedDataInputDTO, PagedListOfKTLSGPSO2ProjectedDataInputDTO } from 'src/app/web-api-client';

export interface ProjectedDataState{
  data: PagedListOfKTLSGPSO2ProjectedDataInputDTO | null; // KTLSGPSO2ProjectedDataInputDTO[]   IPagedListOfKTLSGPSO2ProjectedDataInputDTO
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: ProjectedDataState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const ProjectedDataReducer = createReducer(
  initialState,
  on(ProjectedDataActions.AddProjectedData, (state, payload) => ({
    ...state,
    isLoading: true,
  })),
  on(ProjectedDataActions.AddProjectedDataSuccess, (state, { res }) => ({ //payload
    ...state,
    result: res, // payload,
    isLoading: false,
    isLoadingSuccess: true,
  })),
  on(ProjectedDataActions.AddProjectedDataFailure, (state, payload) => ({
    ...state,
    result: payload.message,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ProjectedDataActions.ListProjectedData, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(ProjectedDataActions.ListProjectedDataSuccess, (state, result) => ({
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(ProjectedDataActions.ListProjectedDataFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ProjectedDataActions.DeleteProjectedData, (state, result) => ({
    ...state,
    isLoading: true,
  })),
  on(ProjectedDataActions.DeleteProjectedDataSuccess, (state, result) => ({
    data: result.data,
    // ...state,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(ProjectedDataActions.DeleteProjectedDataFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);