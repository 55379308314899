import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  concatMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as SO2EmissionsActions from './so2-emissions.actions';
import * as notificationActions from '../../notification/notification.action';
import { SO2Client } from '../../../web-api-client';
import { KTLSGPSO2CalculatedReportDTO } from 'src/app/web-api-client';
import { AppState } from 'src/app/core/models/appState.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SO2EmissionsEffects {
  constructor(
    private actions: Actions,
    private so2Client: SO2Client,
    private store: Store<AppState>
  ) {}

  listSO2Emissions = createEffect(() =>
    this.actions.pipe(
      ofType(SO2EmissionsActions.ListSO2Emissions),
      exhaustMap((action) => {
        return this.so2Client
          .getMonthlyRecords(new Date(action.startTime))
          .pipe(
            map((res) =>
              SO2EmissionsActions.ListSO2EmissionsSuccess({ data: res })
            ),
            catchError((err) =>
              of(SO2EmissionsActions.ListSO2EmissionsFailure(err))
            )
          );
      })
    )
  );

  editSO2Emissions = createEffect(() =>
    this.actions.pipe(
      ofType(SO2EmissionsActions.SO2EmissionsEdit),
      exhaustMap((action) => {
        return this.so2Client.addManualRecord(action).pipe(
          concatMap((res) => [
            SO2EmissionsActions.SO2EmissionsEditSuccess(res),
            SO2EmissionsActions.ListSO2Emissions({
              startTime: action.startTime,
            }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(SO2EmissionsActions.SO2EmissionsEditFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}
