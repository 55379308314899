import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  mergeMap,
  concatMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as OperatorAction from './operator.actions';
import * as notificationActions from '../../notification/notification.action';
import { LPGClient, LPGOperatorInputDTO } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class OperatorEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}

  listOperator = createEffect(() =>
    this.actions.pipe(
      ofType(OperatorAction.ListOperator),
      exhaustMap((action) => {
        return this.lpgClient
          .monthlyOperatorRecords(new Date(action.startTime))
          .pipe(
            map((res) => OperatorAction.ListOperatorSuccess({ data: res })),
            catchError((err) => of(OperatorAction.ListOperatorFailure(err)))
          );
      })
    )
  );

  addOperator = createEffect(() =>
    this.actions.pipe(
      ofType(OperatorAction.AddOperator),
      exhaustMap((action) => {
        return this.lpgClient.addLPGOperatorInput(action).pipe(
          concatMap((res) => [
            OperatorAction.AddOperatorSuccess(res),
            OperatorAction.ListOperator({
              startTime: action.startTime,
            }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(OperatorAction.AddOperatorFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  editOperator = createEffect(() =>
    this.actions.pipe(
      ofType(OperatorAction.EditOperator),
      exhaustMap((action) => {
        return this.lpgClient.editLPGOperatorInput(action).pipe(
          concatMap((res) => [
            OperatorAction.EditOperatorSuccess(res),
            OperatorAction.ListOperator({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(OperatorAction.EditOperatorFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );

  deleteOperator = createEffect(() =>
    this.actions.pipe(
      ofType(OperatorAction.DeleteOperator),
      exhaustMap((action) => {
        return this.lpgClient.deleteLPGOperatorInput(action.id).pipe(
          concatMap((res) => [
            OperatorAction.DeleteOperatorSuccess(res),
            OperatorAction.ListOperator({ startTime: action.startTime }),
            notificationActions.showNotification({ isSuccess: true }),
          ]),
          catchError((err) => [
            of(OperatorAction.DeleteOperatorFailure(err)),
            notificationActions.showNotification({ isSuccess: false }),
          ])
        );
      })
    )
  );
}
