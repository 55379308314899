import { createAction, props } from '@ngrx/store';
import { LPGTargetPermitInputDTO } from 'src/app/web-api-client';

export const ListTargetPermit = createAction(
  '[TargetPermit] List TargetPermit',
  props<{ year: number }>()
);
export const ListTargetPermitSuccess = createAction(
  '[TargetPermit] List TargetPermit Success',
  props<{ data: LPGTargetPermitInputDTO[] }>()
);
export const ListTargetPermitFailure = createAction(
  '[TargetPermit] List TargetPermit Failure',
  props<{ message: string }>()
);

export const AddTargetPermit = createAction(
  '[TargetPermit] Add TargetPermit',
  props<LPGTargetPermitInputDTO>()
);
export const AddTargetPermitSuccess = createAction(
  '[TargetPermit] Add TargetPermit Success',
  props<any>()
);
export const AddTargetPermitFailure = createAction(
  '[TargetPermit] Add TargetPermit Failure',
  props<{ message: string }>()
);