import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CCAction from './cc.actions';
import { LPGClient, ChemicalCompoundRecord } from '../../../web-api-client';

@Injectable({
  providedIn: 'root',
})
export class CCEffects {
  constructor(private actions: Actions, private lpgClient: LPGClient) {}

  getCCData = createEffect(() =>
    this.actions.pipe(
      ofType(CCAction.GetCCData),
      exhaustMap((action) => {
        return this.lpgClient
          .getChemicalCompositions(new Date(action.startTime))
          .pipe(
            map((res) => CCAction.GetCCDataSuccess({ data: res })),
            catchError((err) => of(CCAction.GetCCDataFailure(err)))
          );
      })
    )
  );
}
