import { createAction, props } from '@ngrx/store';
import { LPGFlowmeterGraphDTO } from 'src/app/web-api-client';

export const getChartData = createAction(
  '[ChartJs] Get Data',
  props<{ startTime: Date; endTime: Date; graphType: number }>()
);

export const getChartDataSuccess = createAction(
  '[ChartJs] Get Data Success',
  props<LPGFlowmeterGraphDTO>()
);

export const getChartDataFailure = createAction(
  '[ChartJs] Get Data Failure',
  props<{ message: string }>()
);

export const getFlowMeterChartData = createAction(
  '[ChartJs] Get Data',
  props<{ startTime: Date; endTime: Date; graphType: number }>()
);

export const getFlowMeterChartDataSuccess = createAction(
  '[ChartJs] Get Data Success',
  props<LPGFlowmeterGraphDTO>()
);

export const getFlowMeterChartDataFailure = createAction(
  '[ChartJs] Get Data Failure',
  props<{ message: string }>()
);
