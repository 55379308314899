import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { notificationSelector } from 'src/app/state/notification/notification.selector';

@Component({
  selector: 'app-shared-loading-spinner',
  templateUrl: './shared-loading-spinner.component.html',
  styleUrls: ['./shared-loading-spinner.component.scss'],
})
export class SharedLoadingSpinnerComponent implements OnInit {
  isLoading: boolean = false;
  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.select(notificationSelector).subscribe((res) => {
      this.isLoading = res.isLoading;
      this.cdRef.detectChanges();
    });
  }
}
