import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/models/appState.model';
import { ProductionGasBalanceService } from '../services/production-gas-balance.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ModalService } from 'src/app/core/services/dynamictools/modal.service';
import { DeleteOverrideRecord } from '../state/ktl-fgd-fuel-gas.actions';

@Component({
  selector: 'app-fuel-gas-edit',
  templateUrl: './fuel-gas-edit.component.html',
  styleUrls: ['./fuel-gas-edit.component.scss'],
})
export class FuelGasEditComponent implements OnInit {
  FuelGasEditForm: FormGroup;
  item: string = '';

  elementName: string = '';
  totalVolume: string = '';

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private sharedService: ProductionGasBalanceService,
    private commonService: CommonService,
    private modalService: ModalService,
    private dialogRef: MatDialogRef<FuelGasEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.FuelGasEditForm = this.fb.group({
      elementName: [this.data.elementName, { disabled: true }],
      totalVolume: [this.data.totalVolume, Validators.required],
    });
  }

  ngOnInit(): void {
    // this.FuelGasEditForm.value.elementName;
    // this.FuelGasEditForm.value.totalVolume;

    this.FuelGasEditForm = this.fb.group({
      elementName: [{ value: this.data.elementName, disabled: true }],
      totalVolume: [this.data.totalVolume, Validators.required],
    });
  }

  handleSave() {
    // const body: KTLSGPSO2RecordDTO = new KTLSGPSO2RecordDTO();
    // const vals = this.So2EmissionsSgpEditForm.value;
    // if (this.So2EmissionsSgpEditForm.valid) {
    //   (body.startTime = vals.timeStamp),
    //     (body.totalVolumeSGP = vals.totalVolumeSGP),
    //     (body.inputCommentSGP = vals.inputCommentSGP);
    //   body.plant = 1;
    //   this.store.dispatch(SO2EmissionsEdit(body));
    // }
    console.log('form', this.FuelGasEditForm.value, this.data);

    this.dialogRef.close(this.FuelGasEditForm.value);
  }

  handleDelete() {
    //id: number
    this.modalService.openDeleteConfirmationDialog().then((result: boolean) => {
      if (result) {
        // User confirmed deletion, implement your delete logic here.
        this.store.dispatch(
          DeleteOverrideRecord({
            streamId: this.data.streamId,
            startTime: new Date(this.data.timeStamp),
          })
        );

        this.dialogRef.close();
      }
    });
  }

  formatDate(date: Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'dd MMMM yyyy') || ''; // Returns an empty string if the transformation fails
  }
}
