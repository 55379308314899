import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedDeleteConfirmationComponent } from 'src/app/shared/components/shared-delete-confirmation/shared-delete-confirmation.component';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  openDeleteConfirmationDialog(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const dialogRef = this.dialog.open(SharedDeleteConfirmationComponent, {
        width: '1000px',
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        resolve(result);
      });
    });
  }
}
