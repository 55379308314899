import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FinalReportRoutingModule } from './final-report-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { FlaringReportComponent } from './flaring-report/flaring-report.component'; 
import { LPGDataComponent } from './lpg-data/lpg-data.component';

@NgModule({
  declarations: [FlaringReportComponent,LPGDataComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FinalReportRoutingModule,
    SharedModule
  ]
})
export class FinalReportModule { }
