<!-- <app-tab
  [pageTitle]="pageTitle"
  [buttons]="finalReportButtons"
  [buttons2]="finalReportButtons2"
></app-tab> -->

<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <label>Month</label>
            <div class="input-group mb-2 mr-sm-2">
              <div>
                <input
                  type="month"
                  id="monthPicker"
                  name="logged"
                  class="form-control"
                  [(ngModel)]="month"
                  (ngModelChange)="monthChange()"
                />
              </div>
              <div>
                <button
                  class="ml-4"
                  mat-raised-button
                  style="background-color: #0066b2; color: white"
                  onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
                  onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
                  (click)="exportToExcel()"
                >
                  Export
                </button>
              </div>
              <div class="ml-4" *ngIf="getLoading()">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div class="ml-auto">
                <button
                  class="ml-4"
                  mat-raised-button
                  style="background-color: #0066b2; color: white"
                  onmouseover="this.style.backgroundColor='#005599'; this.style.color='white';"
                  onmouseout="this.style.backgroundColor='#0066b2'; this.style.color='white';"
                  (click)="Approve()"
                >
                  Approve
                </button>
              </div>
              <div
                class="ml-4"
                *ngIf="approveStatus !== null"
                style="color: green"
              >
                {{ approveStatus }}
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="data1 && data2 && data3">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>TOTAL FLARED GAS FROM LPG PARK FOR MONTHLY FLARING REPORT</h1>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="data1?.table1">
            <table class="mat-elevation-z8">
              <thead>
                <col />
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Dry Gas(V7)
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table1?.dry; let i = index">
                  <td
                    class="col-3"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table1?.dry)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table1?.dry)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table1?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table1?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table1?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table1?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>OLD (KTL) LPG PARK</h1>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top: 17px"></div>
          <div *ngIf="data1?.table2">
            <table class="mat-elevation-z9">
              <thead>
                <col />
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <tr>
                  <th colspan="1"></th>

                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table2?.propane; let i = index">
                  <td
                    class="col-3"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table2?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table2?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table2?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table2?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>NEW (SGP) LPG PARK</h1>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top: 17px"></div>
          <div *ngIf="data1?.table3">
            <table class="mat-elevation-z10">
              <thead>
                <col />
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table3?.propane; let i = index">
                  <td
                    class="col-3"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>

                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table3?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table3?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table3?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table3?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>E-909 AND FLARING FROM BULLETS DURING PSVS REPAIR WORK</h1>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="data1?.table4">
            <table class="mat-elevation-z11">
              <thead>
                <col />
                <colgroup span="4"></colgroup>
                <colgroup span="4"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table4?.propane; let i = index">
                  <td
                    class="col-2"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table4?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table4?.propane)![i].totalMass) }}
                  </td>

                  <td class="col-2">
                    {{ formatNumberMV((data1.table4?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table4?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>SGP LPG EQUALIZING LINE FLARING</h1>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top: 17px"></div>
          <div *ngIf="data1?.table5">
            <table class="mat-elevation-z12">
              <thead>
                <col />
                <colgroup span="4"></colgroup>
                <colgroup span="4"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table5?.propane; let i = index">
                  <td
                    class="col-2"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table5?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table5?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table5?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table5?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>FUEL GAS FLARING</h1>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top: 17px"></div>
          <div *ngIf="data1?.table9">
            <table class="mat-elevation-z13">
              <thead>
                <col />
                <colgroup span="4"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Fuel Gas
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table9; let i = index">
                  <td
                    class="col-2"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{ formatNumberMV(data1.table9![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV(data1.table9![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>DATA FOR PDV DAILY MONITORING</h1>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="data1?.table6">
            <table class="mat-elevation-z14">
              <thead>
                <col />
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">kNm3/day</th>
                  <th class="col-2">Ton/day</th>
                  <th class="col-2">kNm3/day</th>
                  <th class="col-2">Ton/day</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table6?.propane; let i = index">
                  <td
                    class="col-3"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table6?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table6?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table6?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table6?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>DATA FOR ACCOUNTING ANALYST: OLD PARK</h1>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="data1?.table7">
            <table class="mat-elevation-z15">
              <thead>
                <col />
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table7?.propane; let i = index">
                  <td
                    class="col-3"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table7?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table7?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table7?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table7?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>DATA FOR ACCOUNTING ANALYST: NEW PARK</h1>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="data1?.table8">
            <table class="mat-elevation-z16">
              <thead>
                <col />
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Propane
                  </th>
                  <th colspan="2" scope="colgroup" class="text-center">
                    Butane
                  </th>
                </tr>
                <tr>
                  <th class="col-2">Month</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                  <th class="col-2">Nm3</th>
                  <th class="col-2">Ton</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data1?.table8?.propane; let i = index">
                  <td
                    class="col-3"
                    [innerText]="formatDate(row.timeStamp)"
                  ></td>
                  <td class="col-2">
                    {{
                      formatNumberMV((data1.table8?.propane)![i].totalVolume)
                    }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table8?.propane)![i].totalMass) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table8?.butane)![i].totalVolume) }}
                  </td>
                  <td class="col-2">
                    {{ formatNumberMV((data1.table8?.butane)![i].totalMass) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>FOR ANALYSTS</h1>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <h3>PROPANE AND BUTANE FLARING BY OLD AND NEW PARK</h3>
              <div *ngIf="data2 && data2.table1 && data2.table1.propane">
                <table class="mat-elevation-z17">
                  <thead>
                    <col />
                    <colgroup span="2"></colgroup>
                    <colgroup span="2"></colgroup>
                    <tr>
                      <th colspan="1"></th>
                      <th colspan="2" scope="colgroup" class="text-center">
                        Propane
                      </th>
                      <th colspan="2" scope="colgroup" class="text-center">
                        Butane
                      </th>
                    </tr>
                    <tr>
                      <th class="col-2">Name</th>
                      <th class="col-1">kNm3</th>
                      <th class="col-1">Ton</th>
                      <th class="col-1">kNm3</th>
                      <th class="col-1">Ton</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of countItem(2); let i = index">
                      <td class="col-2">
                        {{ data2.table1.propane![i].elementName }}
                      </td>
                      <td class="col-1">
                        {{
                          formatNumberMV(data2.table1.propane![i].totalVolume)
                        }}
                      </td>
                      <td class="col-1">
                        {{ formatNumberMV(data2.table1.propane![i].totalMass) }}
                      </td>
                      <td class="col-1">
                        {{
                          formatNumberMV(data2.table1.butane![i].totalVolume)
                        }}
                      </td>
                      <td class="col-1">
                        {{ formatNumberMV(data2.table1.butane![i].totalMass) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col">
              <h3>DRY GAS FLARING FROM GEEP AND KTL</h3>
              <div *ngIf="data2 && data2.table2">
                <table class="mat-elevation-z18">
                  <thead>
                    <tr>
                      <th class="col-2">From</th>
                      <th class="col-1">kNm3</th>
                      <th class="col-1">kSm3</th>
                      <th class="col-1">Ton</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of data2.table2; let i = index">
                      <td class="col-2">{{ row?.elementName }}</td>
                      <td class="col-1">{{ row?.totalVolume?.toFixed(2) }}</td>
                      <td class="col-1">
                        {{ row?.totalVolumekSm3?.toFixed(2) }}
                      </td>
                      <td class="col-1">{{ row?.totalMass?.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col">
              <h3>TOTAL FLARED AT LPG PARK FLARES</h3>
              <div *ngIf="data2 && data2.table3">
                <table class="mat-elevation-z19">
                  <thead>
                    <tr>
                      <th class="col-2">Gas Type</th>
                      <th class="col-1">kNm3</th>
                      <th class="col-1">Ton</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of data2.table3">
                      <td class="col-2">{{ row?.elementName }}</td>
                      <td class="col-1">{{ row?.totalVolume?.toFixed(2) }}</td>
                      <td class="col-1">{{ row?.totalMass?.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-header>
          <h1>BULLETS ENTRY</h1>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <h3>OLD LPG PARK - RTC LOADED</h3>
              <div *ngIf="data3">
                <table class="mat-elevation-z20">
                  <thead>
                    <tr>
                      <th class="col-2">Month</th>
                      <th class="col-1">Propane</th>
                      <th class="col-1">Butane</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of data3?.table1">
                      <td
                        class="col-2"
                        [innerText]="formatDate(row.timeStamp)"
                      ></td>
                      <td class="col-1">{{ formatNumberMV(row.propane) }}</td>
                      <td class="col-1">{{ formatNumberMV(row.butane) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col">
              <h3>NEW LPG PARK - RTC LOADED</h3>
              <div *ngIf="data3">
                <table class="mat-elevation-z21">
                  <thead>
                    <tr>
                      <th class="col-2">Month</th>
                      <th class="col-1">Propane</th>
                      <th class="col-1">Butane</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of data3?.table2">
                      <td
                        class="col-2"
                        [innerText]="formatDate(row.timeStamp)"
                      ></td>
                      <td class="col-1">{{ formatNumberMV(row.propane) }}</td>
                      <td class="col-1">{{ formatNumberMV(row.butane) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
