import { Action, createReducer, on } from '@ngrx/store';
import * as CCAction from './cc.actions';
import { ChemicalCompoundRecord } from 'src/app/web-api-client';

export interface CCState {
  data: ChemicalCompoundRecord[] | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

const initialState: CCState = {
  data: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
};

export const CCReducer = createReducer(
  initialState,
  on(CCAction.GetCCData, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CCAction.GetCCDataSuccess, (state, result) => ({
    ...state,
    data: result.data,
    result,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
  })),
  on(CCAction.GetCCDataFailure, (state, result) => ({
    ...state,
    result,
    isLoading: false,
    isLoadingFailure: true,
  }))
);
