import { Component } from '@angular/core';

@Component({
  selector: 'app-shared-a',
  templateUrl: './shared-a.component.html',
  styleUrls: ['./shared-a.component.scss']
})
export class SharedAComponent {

}
